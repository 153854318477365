/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { IBaseProduct } from '../../../services/api/service/products/types'
import { OptionalExceptFor } from '../../../types/common'
import { ProductImageClickCallback } from '../../../types/productCallback'
import Picture, { PictureProps } from '../../Catalog/Picture/Picture'
import { $PropertyType } from 'utility-types'
export type MainPictureProps = Pick<PictureProps, 'className' | 'children'> & {
    product: OptionalExceptFor<IBaseProduct, 'name' | '@id' | 'id'>
    onImageClick?: ProductImageClickCallback
    loadEagerly?: $PropertyType<PictureProps, 'loadEagerly'>
}

function MainPicture({
    product,
    className,
    loadEagerly = true,
    onImageClick,
    children,
}: MainPictureProps): JSX.Element {
    const handleImageClick = useCallback(() => {
        onImageClick?.(product, 0)
    }, [product, onImageClick])

    const pictures = useMemo(() => {
        if (product.main_pictures && product.main_pictures.length) {
            return product.main_pictures
        } else if (product.main_picture) {
            return [product.main_picture]
        }
        return []
    }, [product])

    if (pictures.length === 0) {
        return <></>
    }

    return (
        <Picture
            onClick={handleImageClick}
            className={classNames('product-image', 'product-image-list', className)}
            loadEagerly={loadEagerly}
            src={pictures[0].list}
            alt={product.name}
            placeholder={pictures[0].placeholder}
        >
            {children}
        </Picture>
    )
}

MainPicture.defaultProps = {
    loadEagerly: false,
} as Partial<MainPictureProps>

export default MainPicture

import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'
import { ContainerState as ProductState, IPlanogramDetailState, IPlanogramGroupAttributeState } from './types'

const selectPlanogramDomain = (state: IApplicationRootState): ProductState => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.planogram || initialState
}

const selectPlanogramDetail = (state: IApplicationRootState): IPlanogramDetailState => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.planogram.detail || initialState.detail
}

const selectPlanogramGroups = (state: IApplicationRootState): IPlanogramGroupAttributeState => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.planogram.planogramGroups || initialState.planogramGroups
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by Product
 */

const makeSelectPlanogram = () =>
    createSelector(selectPlanogramDomain, (substate) => {
        return substate
    })

const makeSelectPlanogramDetailId = () =>
    createSelector(selectPlanogramDomain, (substate) => {
        return substate.planogramId
    })

const makeSelectPlanogramDetailGroupId = () =>
    createSelector(selectPlanogramDomain, (substate) => {
        return substate.planogramGroupId
    })

const makeSelectPlanogramDetailFetching = () =>
    createSelector(selectPlanogramDomain, (substate) => {
        return substate.detail.fetching
    })

const makeSelectPlanogramDetailError = () =>
    createSelector(selectPlanogramDomain, (substate) => {
        return substate.detail.error
    })

const makeSelectPlanogramDetail = () =>
    createSelector(selectPlanogramDetail, (substate) => {
        return substate.detail
    })

const makeSelectPlanogramGroups = () =>
    createSelector(selectPlanogramGroups, (substate) => {
        return substate
    })

const makeSelectPlanogramGroupLinkedPlanograms = () =>
    createSelector(selectPlanogramGroups, (substate) => {
        return substate.data?.planograms
    })

export default makeSelectPlanogram
export {
    selectPlanogramDomain,
    makeSelectPlanogramDetailId,
    makeSelectPlanogramDetail,
    makeSelectPlanogramGroups,
    makeSelectPlanogramDetailFetching,
    makeSelectPlanogramGroupLinkedPlanograms,
    makeSelectPlanogramDetailError,
    makeSelectPlanogramDetailGroupId,
}

import { AxiosResponse, CancelToken } from 'axios'
import {
    IApiBooleanType,
    IApiResource,
    IBasePaginationParameters,
    ICollectionApiResponse,
    IPaginationApiResponse,
} from '../../types'
import { CollectionMap } from '../../../../types/common'
import { IBaseProduct, ProductStockAlertCollection } from '../products/types'
import { IOrderMode } from '../orders/types'
import { IShippingLocation } from '../classification/types'
import { AnyObjectWithStringKeys } from 'tsdef'
import { ICustomer } from '../customers/types'
import { ICatalogListFilterTree, ICatalogListUserFilterValue, ICatalogListUserSelectionCollection } from '../core/types'
import { $PropertyType } from 'utility-types'
import { IBasePlanogram, IPlanogramModule } from '../planograms/types'

export type ICartItemProduct = IBaseProduct & {
    readonly unit_volume: number
}

// export type IReOrderParameters = CollectionMap<string | number | boolean | Date | undefined | Array<string>>
export type IManageCartParameters = {
    erase_cart: boolean
    cart_type?: IOrderMode
    customers?: Array<string>
}

export type IReOrderParameters = IManageCartParameters & {
    order: string
}

export type ICartDuplicateParameters = IManageCartParameters & {
    cart: string
}

export interface IBulkCartQuantityFilterAttribute {
    attribute: string
    values: ICatalogListUserFilterValue
}

export type BulkCartQuantityFilterAttributeCollection = Array<IBulkCartQuantityFilterAttribute>

export type BulkCartQuantityParameters = {
    bulk: ICatalogListFilterTree & {
        quantity: number
        products?: ICatalogListUserSelectionCollection
        filters?: BulkCartQuantityFilterAttributeCollection | undefined
        min_price?: number
        max_price?: number
        listed_only?: IApiBooleanType | undefined
        favorite?: IApiBooleanType | undefined
        best_sellers?: IApiBooleanType | undefined
        arrival_stocks?: IApiBooleanType | undefined
        my_listing?: IApiBooleanType | undefined
        discount?: IApiBooleanType | undefined
        new?: IApiBooleanType | undefined
        search?: string
        planogram?: $PropertyType<IBasePlanogram, '@id'>
        planogram_module?: $PropertyType<IPlanogramModule, '@id'>
    }
}

export interface ICartItem extends IApiResource {
    readonly product: ICartItemProduct
    readonly unit_price: number | null
    readonly unit_strike_price: number | null
    readonly cart: string
    readonly total: number | null
    readonly total_eco_tax?: number | null
    readonly package_count: number
    readonly quantity: number
    readonly errors: Array<string>
    readonly store_quantities?: CollectionMap<number>
    readonly can_increment_quantity: boolean
    readonly quantity_changed: boolean
    readonly is_deleted: boolean
}

export type ICartItemCollection = CollectionMap<ICartItem>

export type IResourceCart = 'Cart'
export const RESOURCE_CART_TYPE: IResourceCart = 'Cart'
export interface ICartLight extends IApiResource<IResourceCart> {
    readonly customer: string
    readonly shipping_location: string
    readonly minimum_order_amount: number
    readonly store_carts?: ICartLightCollection
    readonly locked: boolean
    // TODO: mercure nous appelera plus tard. En attendant, on incrémente à la main. On supprime le ReadOnly
    total: number
    total_eco_tax?: number | null
    total_quantity: number
    package_count: number
    items_count: number
    total_volume: number
}

export interface ICart extends ICartLight {
    readonly items?: ICartItemCollection
    readonly warnings?: Array<string>
    readonly min_delivery_date: string
    readonly max_delivery_date: string
}

export type IMultiStoreStoreCart = Omit<ICartLight, 'shipping_location'> & {
    readonly shipping_location: IShippingLocation
    readonly locked: boolean
}

export type ISalesmanCart = Omit<ICartLight, 'shipping_location'> & {
    readonly shipping_location: IShippingLocation
    readonly customer: ICustomer
    readonly type: IOrderMode
    readonly last_update?: string
}

export type IMultiStoreCartsParameters = IBasePaginationParameters & AnyObjectWithStringKeys

export type ISalesmanCartListParameters = IBasePaginationParameters & AnyObjectWithStringKeys

export enum ICartMode {
    Default = 'default', // mode classique
    Grouped = 'grouped', // paniers regroupés. On utilise StoreQuantity
    Both = 'both', // permet de choisir le type de panier et l'envoyer en header à l'api (setCartMode)
}

export enum ICartValidationMode {
    Default = 'default', // mode classique
    Lock = 'lock', // le panier est vérouillé. Aucune modification possible
}

export type IReOrderResponse = ICollectionApiResponse<ICartCollection> & {
    warnings: Array<string>
}

export type IBulkCartQuantityResponse = ICollectionApiResponse<ICartItemCollection> & {
    unavailable_stock_products: ProductStockAlertCollection
}

export type IAPiCartLockResponse = AxiosResponse<ICart>
export type ICartLightCollection = Array<ICartLight>
export type ICartCollection = Array<ICart>
export type ISalesmanCartCollection = Array<ISalesmanCart>
export type IMultiStoreStoreCartCollection = Array<IMultiStoreStoreCart>
export type IApiMultiStoreCartCollection = ICollectionApiResponse<IMultiStoreStoreCartCollection>
export type IApiMultiStoreCartResponse = AxiosResponse<IApiMultiStoreCartCollection>
export type IApiCartLightPagination = IPaginationApiResponse<ICartLightCollection>
export type IApiCartResponse = AxiosResponse<ICart>
export type IApiCartListPagination = IPaginationApiResponse<ICartCollection>
export type IApiCartListResponse = AxiosResponse<IApiCartListPagination>
export type IApiSalesmanCartListPagination = IPaginationApiResponse<ISalesmanCartCollection>
export type IApiSalesmanCartListResponse = AxiosResponse<IApiSalesmanCartListPagination>
export type IApiCartLightListResponse = AxiosResponse<IApiCartLightPagination>
export type IApiCartItemResponse = AxiosResponse<ICartItem>
export type IApiCartItemDeleteResponse = AxiosResponse<ICartItem | undefined>
export type IApiReOrderResponse = AxiosResponse<IReOrderResponse>
export type IApiBulkCartQuantityResponse = AxiosResponse<IBulkCartQuantityResponse>

export interface ICartService {
    list(): Promise<IApiCartListResponse>
    get(cartId: string): Promise<IApiCartResponse>
    lock(cartId: string): Promise<IAPiCartLockResponse>
    unlock(cartId: string): Promise<IAPiCartLockResponse>
    salesmanCartList(
        parameters: ISalesmanCartListParameters,
        cancelToken?: CancelToken
    ): Promise<IApiSalesmanCartListResponse>
    storesCartList(
        parameters: IMultiStoreCartsParameters,
        cancelToken?: CancelToken
    ): Promise<IApiMultiStoreCartResponse>
    storeCartList(parentCartId: string): Promise<IApiCartLightListResponse>
    clean(cartId: string): Promise<IApiCartResponse>
    getItem(productId: string): Promise<IApiCartItemResponse>
    addItem(productId: string, quantity: number, storeId?: string, cartId?: string): Promise<IApiCartItemResponse>
    addBulk(params: BulkCartQuantityParameters): Promise<IApiBulkCartQuantityResponse>
    bulkMove(params: BulkCartQuantityParameters): Promise<IApiBulkCartQuantityResponse>
    deleteItem(productId: string, storeId?: string): Promise<IApiCartItemDeleteResponse>
    reOrder(data: IReOrderParameters, headers?: Record<string, string>): Promise<IApiReOrderResponse>
    duplicate(data: ICartDuplicateParameters): Promise<IApiReOrderResponse>
}

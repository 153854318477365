/*
 *
 * Product reducer
 *
 */

import ActionTypes from './constants'
import { ContainerActions, ContainerState, IPlanogramDetailState } from './types'

export const initialAttributesState = {
    fetching: false,
    data: null,
}

export const initialProductDetailState: IPlanogramDetailState = {
    fetching: false,
    detail: null,
}

export const initialState: ContainerState = {
    planogramId: null,
    detail: initialProductDetailState,
    planogramGroups: initialAttributesState,
}

function planogramReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.DETAIL_RESET_ACTION:
            return {
                ...state,
                detail: initialProductDetailState,
            }
        case ActionTypes.DETAIL_SUCCESS_ACTION:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    detail: action.payload.response.data,
                },
            }
        case ActionTypes.GROUP_PROCESS_ACTION:
            return {
                ...state,
                planogramGroups: {
                    ...state.planogramGroups,
                    data: null,
                },
            }
        case ActionTypes.GROUP_RESET_ACTION:
            return {
                ...state,
                planogramGroups: initialAttributesState,
            }
        case ActionTypes.GROUP_SUCCESS_ACTION:
            return {
                ...state,
                planogramGroups: {
                    ...state.planogramGroups,
                    data: action.payload.response ? action.payload.response!.data : null,
                },
            }
        case ActionTypes.RESET_ACTION:
            return initialState
        default:
            return state
    }
}

export default planogramReducer

import Button from '../../../../components/Buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FlatIcon from '../../../../components/Icon/FlatIcon'
import { FormattedMessage } from 'react-intl'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IApplicationRootState } from '../../../../store'
import { IAttributeDefinitionCollection } from '../../../../services/api/service/classification/types'
import { PlanogramListAction } from '../../../../utils/planogramHelper'
import { cartBulkCartQuantityResetAction } from '../../../../store/carts/actions'
import Modal from '../../../MassAddToCart/Modal/Loadable'
import { BulkCartQuantityMode } from '../../../../store/carts/types'
import { createStructuredSelector } from 'reselect'
import { makeSelectCartBulkCartQuantityFetching } from '../../../../store/carts/selectors'
import classNames from 'classnames'
import { ActionProps } from './type'
import { IPlanogramModule } from '../../../../services/api/service/planograms/types'

const stateSelector = createStructuredSelector<any, any>({
    fetching: makeSelectCartBulkCartQuantityFetching(),
})

type Props = ActionProps & {
    planogramModule?: IPlanogramModule
    label?: string
}

function AddToCart({
    className,
    planogram,
    planogramModule,
    disabled,
    onClick,
    onActionStart,
    onActionDone,
    size,
    block,
    label,
    variant = 'primary',
}: Props): JSX.Element {
    const [modalShow, setModalShow] = useState<boolean>(false)

    const dispatch = useDispatch()
    const { fetching: bulkAddToCartFetching } = useSelector<
        IApplicationRootState,
        {
            fetching: boolean
            definition: IAttributeDefinitionCollection
        }
    >(stateSelector)

    const handleActionClick = useCallback(
        (evt: React.MouseEvent) => {
            setModalShow(true)
            onClick?.(evt, planogram, PlanogramListAction.MassAddToCart)
            onActionStart?.(PlanogramListAction.MassAddToCart)
        },
        [onClick, planogram, onActionStart]
    )

    const handleOnModalExited = useCallback(() => {
        dispatch(cartBulkCartQuantityResetAction())
        setModalShow(false)
        onActionDone?.(PlanogramListAction.MassAddToCart)
    }, [dispatch, onActionDone])

    return (
        <div className={classNames('planogram-action', className)}>
            <Button
                block={block}
                className={classNames('btn-action btn-mass-add-to-cart')}
                variant={variant}
                disabled={disabled || bulkAddToCartFetching}
                onClick={handleActionClick}
                size={size}
                data-action={PlanogramListAction.MassAddToCart}
            >
                {bulkAddToCartFetching ? (
                    <FontAwesomeIcon icon="circle-notch" spin={true} className={'app-icon'} />
                ) : (
                    <FlatIcon icon={'shopping-cart'} className={'app-icon-shopping-cart'} />
                )}
                {label ? label : <FormattedMessage id={'products.add_all_to_cart'} />}
            </Button>
            <Modal
                onExit={handleOnModalExited}
                show={modalShow}
                planogramId={planogram['@id']}
                planogramModuleId={planogramModule?.['@id']}
                mode={planogramModule ? BulkCartQuantityMode.PlanogramModule : BulkCartQuantityMode.Planogram}
            />
        </div>
    )
}

export default AddToCart

/**
 *
 * List
 *
 */
import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { IAttributeDefinitionCollection } from '../../../services/api/service/classification/types'
import { IProductListParameters } from '../../../services/api/service/products/types'
import { ICatalogListFilter, ICatalogListFilterCollection } from '../../../services/api/service/core/types'
import { IApplicationRootState } from '../../../store'
import { makeSelectClassificationAttributeDefinition } from '../../../store/classification/selectors'
import FilterList from '../../Catalog/Partial/Filter/List'
import {
    makeSelectProductsListFetching,
    makeSelectProductsListFilters,
    makeSelectProductsListParams,
} from '../../../store/products/selectors'
import { customerFilterHasSelectedValues } from '../../../utils/catalog'

interface IProps {
    className?: string
    filterPortalId?: string
    onFilterToggleOpen?: (filter: ICatalogListFilter, open: boolean) => void
    onReset?: () => void
}

const stateSelector = createStructuredSelector<any, any>({
    loading: makeSelectProductsListFetching(),
    filters: makeSelectProductsListFilters(),
    params: makeSelectProductsListParams(),
    definition: makeSelectClassificationAttributeDefinition(),
})

function List({ className, filterPortalId, onFilterToggleOpen }: IProps): JSX.Element {
    const { loading, filters, params, definition } = useSelector<
        IApplicationRootState,
        {
            loading: boolean
            filters?: ICatalogListFilterCollection
            params: IProductListParameters
            definition: IAttributeDefinitionCollection
        }
    >(stateSelector)

    const hasSelectedValues = useMemo(() => {
        return customerFilterHasSelectedValues(filters, params)
    }, [filters, params])

    return (
        <FilterList<IProductListParameters>
            className={className}
            filters={filters}
            params={params}
            loading={loading}
            onFilterToggleOpen={onFilterToggleOpen}
            hasSelectedValues={hasSelectedValues}
            filterPortalId={filterPortalId}
            definition={definition}
        />
    )
}

List.defaultProps = {
    desktop: true,
} as Partial<IProps>

export default memo(List)

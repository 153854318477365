/*
 *
 * Products actions
 *
 */

import { action } from 'typesafe-actions'

import { IPlanogramsListProcessPayload, IPlanogramsListSuccessPayload } from './types'

import ActionTypes from './constants'
import { IAppErrorTypes } from '../app/types'
import { $PropertyType } from 'utility-types'
import { IAPiPlanogramListResponse, IPlanogramListFiltersParameters } from '../../services/api/service/planograms/types'

// GLOBAL
export const planogramsResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)

export const planogramsListProcessAction = (params: IPlanogramListFiltersParameters) =>
    action<ActionTypes.LIST_PROCESS_ACTION, IPlanogramsListProcessPayload>(ActionTypes.LIST_PROCESS_ACTION, {
        params,
    })

export const planogramsListRefreshAction = () =>
    action<ActionTypes.LIST_REFRESH_ACTION>(ActionTypes.LIST_REFRESH_ACTION)

export const planogramsListSuccessAction = (
    response: $PropertyType<IAPiPlanogramListResponse, 'data'>,
    params: IPlanogramListFiltersParameters
) =>
    action<ActionTypes.LIST_SUCCESS_ACTION, IPlanogramsListSuccessPayload>(ActionTypes.LIST_SUCCESS_ACTION, {
        response,
        params,
    })
export const planogramListFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.LIST_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.LIST_FAILURE_ACTION, { error })
export const planogramsListResetAction = () => action<ActionTypes.LIST_RESET_ACTION>(ActionTypes.LIST_RESET_ACTION)

/**
 *
 * Mobile
 *
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import Button from '../../../../components/Buttons/Button'
import FlatIcon from '../../../../components/Icon/FlatIcon'
import { IProps as IFilterListProps } from './List'
import { ICatalogListParameters } from '../../../../services/api/service/core/types'

type Props<T extends ICatalogListParameters = ICatalogListParameters> = Pick<
    IFilterListProps<T>,
    'hasSelectedValues' | 'loading'
> & {
    count: number | undefined
    portalId: string
    children: ReactNode
    showDetail: boolean
    onShowDetail?: () => void
    onHideDetail: () => void
}

function Mobile<T extends ICatalogListParameters = ICatalogListParameters>({
    loading,
    count,
    hasSelectedValues,
    portalId,
    children,
    showDetail = false,
    onHideDetail,
}: Props<T>): JSX.Element {
    const history = useHistory()
    const { pathname } = useLocation()
    const [showMobileFilters, setShowMobileFilters] = useState<boolean>(false)
    const [currentPathname, setCurrentPathname] = useState<string>(pathname)

    const handleMobileFilterClick = useCallback(() => {
        setShowMobileFilters(true)
    }, [setShowMobileFilters])

    const handleClose = useCallback(() => {
        setShowMobileFilters(false)
    }, [setShowMobileFilters])

    const handleReset = useCallback(() => {
        setShowMobileFilters(false)
        history.push(pathname)
    }, [setShowMobileFilters, pathname, history])

    useEffect(() => {
        if (pathname !== currentPathname) {
            onHideDetail()
            setShowMobileFilters(false)
            setCurrentPathname(pathname)
        }
    }, [pathname, currentPathname, setCurrentPathname, setShowMobileFilters, onHideDetail])

    useEffect(() => {
        if (showMobileFilters) {
            document.documentElement.classList.add('mobile-filter-opened')
        } else {
            document.documentElement.classList.remove('mobile-filter-opened')
        }

        return () => {
            document.documentElement.classList.remove('mobile-filter-opened')
        }
    }, [showMobileFilters])

    return (
        <div className={classNames('mobile-filter-list', { 'is-empty': !hasSelectedValues })}>
            <Button
                type={'button'}
                variant={'light'}
                className={'btn-filter'}
                onClick={handleMobileFilterClick}
                loading={loading}
                disabled={typeof count === 'undefined'}
            >
                {!loading && <FontAwesomeIcon icon={'list'} className={'app-icon'} />}
                <FormattedMessage id={'default.filter'} />
            </Button>
            <div className={classNames('mobile-filter-page', { show: showMobileFilters })}>
                <div className={'root-page'}>
                    <div className={'header'}>
                        <Button onClick={handleClose} type={'button'} variant={'link'}>
                            <FlatIcon icon={'chevron-left'} />
                            <FormattedMessage id={'default.filter'} />
                        </Button>
                        <Button onClick={handleReset} type={'button'} variant={'link'} disabled={!hasSelectedValues}>
                            <FormattedMessage id={'products.remove_filters'} />
                        </Button>
                    </div>
                    <div className={'content'}>{children}</div>
                    <div className={'footer'}>
                        <Button block loading={loading} disabled={loading} onClick={handleClose}>
                            <FormattedMessage id={'product.view_catalog_count'} values={{ itemCount: count }} />
                        </Button>
                    </div>
                </div>
                <div className={classNames('detail-page', { show: showDetail })}>
                    <div className={classNames('catalog-filter-list', 'mobile', 'content')} id={portalId} />
                </div>
            </div>
        </div>
    )
}

export default Mobile

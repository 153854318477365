import Button from '../../../../components/Buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { Overlay, Tooltip } from 'react-bootstrap'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { PlanogramDocumentType } from '../../../../utils/planogramHelper'
import client from '../../../../services/api/client'
import { ActionProps } from './type'

export enum PlanogramListAction {
    MassAddToCart = 'mass_add_to_cart',
    DownloadPdf = 'download_pdf',
    DownloadExel = 'download_excel',
}

type Props = ActionProps & {
    documentType: PlanogramDocumentType
}

function DownloadDocument({
    className,
    planogram,
    disabled,
    onClick,
    documentType,
    onActionStart,
    onActionDone,
    size,
    block,
    variant = 'secondary',
}: Props): JSX.Element {
    const btnRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
    const { formatMessage } = useIntl()
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [fetchingAction, setFetchingAction] = useState(false)

    const actionType = useMemo(() => {
        return documentType === PlanogramDocumentType.Excel
            ? PlanogramListAction.DownloadExel
            : PlanogramListAction.DownloadPdf
    }, [documentType])

    const handleActionClick = useCallback(
        (evt: React.MouseEvent) => {
            const apiUrl = PlanogramListAction.DownloadExel === actionType ? planogram.excel : planogram.pdf
            if (!apiUrl) {
                return
            }

            onActionStart?.(actionType)
            setFetchingAction(true)

            client
                .getInstance()
                .get(apiUrl)
                .then((response) => {
                    window.open(response.data.url, '_blank')
                })
                .finally(() => {
                    setFetchingAction(false)
                    onActionDone?.(actionType)
                })

            onClick?.(evt, planogram, actionType)
        },
        [actionType, onClick, planogram, onActionStart, onActionDone]
    )

    const hasFile = useMemo(() => {
        return (
            (documentType === PlanogramDocumentType.Excel && !isEmpty(planogram.excel)) ||
            (documentType === PlanogramDocumentType.Pdf && !isEmpty(planogram.pdf))
        )
    }, [documentType, planogram.excel, planogram.pdf])

    const lbl = useMemo(() => {
        return formatMessage({
            id: documentType === PlanogramDocumentType.Pdf ? 'default.download_whole_file' : 'default.download_excel',
        })
    }, [documentType, formatMessage])

    const tooltipMessage = useMemo(() => {
        if (
            (documentType === PlanogramDocumentType.Pdf && isEmpty(planogram.pdf)) ||
            (documentType === PlanogramDocumentType.Excel && isEmpty(planogram.excel))
        ) {
            return formatMessage({ id: 'planograms.not_available_in_file_format' })
        }

        return undefined
    }, [documentType, planogram.pdf, planogram.excel, formatMessage])

    const handleMouseEnter = useCallback(() => {
        if (
            (documentType === PlanogramDocumentType.Pdf && isEmpty(planogram.pdf)) ||
            (documentType === PlanogramDocumentType.Excel && isEmpty(planogram.excel))
        ) {
            setShowErrorMessage(true)
            return
        }

        setShowErrorMessage(false)
    }, [documentType, planogram.excel, planogram.pdf])

    const handleMouseLeave = useCallback(() => {
        setShowErrorMessage(false)
    }, [])

    return (
        <div
            className={classNames('planogram-action', className, { disabled: disabled || !hasFile })}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Overlay target={btnRef.current} show={showErrorMessage}>
                {(props) => (
                    <Tooltip id={`tooltip-${planogram['@id']}-pdf`} {...props}>
                        {tooltipMessage || ''}
                    </Tooltip>
                )}
            </Overlay>
            <Button
                block={block}
                ref={btnRef}
                size={size}
                className={'btn-action btn-download'}
                variant={variant}
                disabled={disabled || !hasFile || fetchingAction}
                onClick={handleActionClick}
            >
                {fetchingAction && <FontAwesomeIcon icon="circle-notch" spin={true} className={'app-icon'} />}
                {lbl}
            </Button>
        </div>
    )
}

export default DownloadDocument

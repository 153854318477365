import { AxiosInstance, CancelToken } from 'axios'
import {
    IApiPlanogramGroupResponse,
    IAPiPlanogramListResponse,
    IApiPlanogramResponse,
    IPlanogramListFiltersParameters,
    IPlanogramsService,
} from './types'

const create = (instance: AxiosInstance): IPlanogramsService => {
    /*****************************
     * METHODS
     *****************************/
    const list = (
        parameters: IPlanogramListFiltersParameters,
        cancelToken?: CancelToken
    ): Promise<IAPiPlanogramListResponse> => {
        let params = { ...parameters }
        if (params.filters) {
            delete params['filters']
        }
        if (parameters['filters']) {
            params = { ...params, ...parameters.filters }
        }
        return instance.get('/planograms', { params: params, cancelToken })
    }

    const get = (planogramId: string, cancelToken?: CancelToken): Promise<IApiPlanogramResponse> => {
        return instance.get(`/planograms/${planogramId}`, { cancelToken })
    }

    const planogramGroup = (
        planogramGroupId: string,
        cancelToken?: CancelToken
    ): Promise<IApiPlanogramGroupResponse> => {
        return instance.get(`${planogramGroupId}`, { cancelToken })
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        list,
        get,
        planogramGroup,
    }
}

export default {
    create,
}

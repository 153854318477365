/**
 *
 * NotFoundAlert
 *
 */
import React, { memo, useMemo } from 'react'
import { Alert } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { ICatalogListParameters } from '../../services/api/service/core/types'

export enum NotFoundReasonModes {
    UnknownFamilyTree,
    NoResultsSearch,
    NoResultsFilters,
    NoResults,
}
export enum NotFoundReasonAction {
    ResetListedOnly,
    RemoveFilters,
    GoBack,
}

interface IProps {
    reason: NotFoundReasonModes
    queries?: ICatalogListParameters | undefined
    onClick: (action: NotFoundReasonAction) => void
}

function NotFoundAlert({ reason, queries }: IProps): JSX.Element {
    const { formatMessage } = useIntl()
    const title = useMemo(() => {
        if (reason === NotFoundReasonModes.NoResultsSearch) {
            return formatMessage({ id: 'page.search' })
        } else if (reason === NotFoundReasonModes.NoResultsFilters) {
            return formatMessage({ id: 'page.search' })
        }

        return formatMessage({ id: 'products.not_found.title' })
    }, [reason, formatMessage])

    const message = useMemo(() => {
        // if (reason === NotFoundReasonModes.NoResultsSearch) {
        //     const keywords: string = typeof queries !== 'undefined' && typeof queries.filters !== 'undefined'
        //     return formatMessage({ id: 'products.no_searched_results' }, { keyword: keywords })
        // } else if (reason === NotFoundReasonModes.NoResultsFilters) {
        if (reason === NotFoundReasonModes.NoResultsFilters) {
            return formatMessage({ id: 'products.no_filtered_results' })
        } else if (reason === NotFoundReasonModes.UnknownFamilyTree) {
            return formatMessage(
                { id: 'products.not_found' },
                {
                    name: window.location.pathname.split('/').slice(-1)!.pop()!.toUpperCase().replace('-', ' '),
                }
            )
        }

        return formatMessage({ id: 'products.not_found.title' })
    }, [reason, formatMessage])

    return (
        <Alert variant={'danger'}>
            <Alert.Heading>{title}</Alert.Heading>
            <p>{message}</p>
        </Alert>
    )
}

NotFoundAlert.defaultProps = {} as Partial<IProps>

export default memo(NotFoundAlert)

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { intlReducer } from 'react-intl-redux'
import { flashMessage } from 'redux-flash-messages'
import { ContainerState as ProductState } from './product/types'
import { ContainerState as PlanogramState } from './planogram/types'
import { ContainerState as CustomersState } from './customers/types'
import { ContainerState as OrdersState } from './orders/types'
import { ContainerState as InvoicesState } from './invoices/types'
import { ContainerState as OrderState } from './order/types'
import { ContainerState as PaymentModesState } from './payment-modes/types'

import IHttpAction from './http/actions'
import { IApplicationRootState } from './index'
import searchReducer from './search/reducer'
import authReducer from './auth/reducer'
import productsReducer from './products/reducer'
import planogramsReducer from './planograms/reducer'
import planogramReducer from './planogram/reducer'
import appReducer from './app/reducer'
import sideMenuReducer from './sidemenu/reducer'
import customersReducer from './customers/reducer'
import splashScreenReducer from './splashScreen/reducer'
import configReducer from './config/reducer'
import productReducer from './product/reducer'
import classificationReducer from './classification/reducer'
import cartsReducer from './carts/reducer'
import ordersReducer from './orders/reducer'
import orderReducer from './order/reducer'
import invoicesReducer from './invoices/reducer'
import cmsReducer from './cms/reducer'
import exportProductsReducer from './exports/reducer'
import shopImportReducer from './imports/reducer'
import salesmanReducer from './salesmens/reducer'
import statisticsReducer from './statistics/reducer'
import pictureExportReducer from './pictureExport/reducer'
import localizationReducer from './localization/reducer'
import paymentModesReducer from './payment-modes/reducer'

import { createHttpReducer } from './http/reducer'
import { History } from 'history'
import { ICartsState } from './carts/types'

const createRootReducer = (history: History, injectedReducers = {}) =>
    combineReducers<IApplicationRootState>({
        router: connectRouter(history),
        intl: intlReducer,
        auth: authReducer,
        app: appReducer,
        sideMenu: sideMenuReducer,
        splashScreen: splashScreenReducer,
        config: configReducer,
        classification: classificationReducer,
        products: createHttpReducer(
            'Products/List',
            createHttpReducer('Products/Search', productsReducer, 'search'),
            'list'
        ),
        product: createHttpReducer<ProductState, IHttpAction>(
            'Product/Detail',
            createHttpReducer(
                'Product/GroupAttributes',
                createHttpReducer(
                    'Product/CrossSelling',
                    createHttpReducer('Product/Collection', productReducer, 'productCollection'),
                    'groupAttributes'
                ),
                'groupAttributes'
            ),
            'detail'
        ),
        planograms: createHttpReducer('Planograms/List', planogramsReducer, 'list'),
        planogram: createHttpReducer<PlanogramState, IHttpAction>(
            'Planogram/Detail',
            createHttpReducer('Planogram/GroupAttributes', planogramReducer, 'planogramGroups'),
            'detail'
        ),
        customers: createHttpReducer<CustomersState, IHttpAction>(
            'Customers/Me',
            createHttpReducer(
                'Customers/ViewedProducts',
                createHttpReducer('Customers/ModifyLogin', customersReducer, 'modifyLogin'),
                'products'
            ),
            'customer'
        ),
        carts: createHttpReducer<ICartsState, IHttpAction>('Carts/Salesman/List', cartsReducer, 'salesman'),
        orders: createHttpReducer<OrdersState, IHttpAction>(
            'Orders/List',
            createHttpReducer('Orders/Last', ordersReducer, 'last'),
            'list'
        ),
        paymentModes: createHttpReducer<PaymentModesState, IHttpAction>(
            'PaymentModes/List',
            paymentModesReducer,
            'list'
        ),
        invoices: createHttpReducer<InvoicesState, IHttpAction>('Invoices/List', invoicesReducer, 'list'),
        salesman: salesmanReducer,
        order: createHttpReducer<OrderState, IHttpAction>('Order/Detail', orderReducer, 'detail'),
        cms: cmsReducer,
        search: searchReducer,
        exports: exportProductsReducer,
        imports: shopImportReducer,
        statistics: statisticsReducer,
        pictureExport: pictureExportReducer,
        localization: localizationReducer,
        flashMessage,
        ...injectedReducers,
    })

export default createRootReducer

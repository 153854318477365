import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'
import { findNextPlanogramItemBy, findPreviousPlanogramItemBy } from './utils'
import { IPlanogramList } from '../../services/api/service/planograms/types'

/**
 * Direct selector to the products state domain
 */

const selectPlanogramsDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.planograms || initialState
}

const selectPlanogramsListDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.planograms.list || initialState.list
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by Products
 */

const makeSelectPlanogramsListFetching = () =>
    createSelector(selectPlanogramsListDomain, (substate) => {
        return substate.fetching
    })

const makeSelectPlanogramsListParams = () =>
    createSelector(selectPlanogramsListDomain, (substate) => {
        return substate.params
    })

const makeSelectPlanogramsListParamsPrev = () =>
    createSelector(selectPlanogramsListDomain, (substate) => {
        return substate.paramsPrev
    })

const makeSelectPlanogramsListError = () =>
    createSelector(selectPlanogramsListDomain, (substate) => {
        return substate.error
    })

const makeSelectPlanogramsListItems = () =>
    createSelector(selectPlanogramsListDomain, (substate) => {
        return substate.items
    })

const makeSelectPlanogramsListFilters = () =>
    createSelector(selectPlanogramsListDomain, (substate) => {
        return substate.filters
    })

const makeSelectPlanogramsListTotalItems = () =>
    createSelector(selectPlanogramsListDomain, (substate) => {
        return substate.totalItems
    })

const makeSelectPlanogramsListOrderValues = () =>
    createSelector(selectPlanogramsListDomain, (substate) => {
        return substate.order.values
    })

const makeSelectPlanogramListNextItem = () =>
    createSelector(
        [makeSelectPlanogramsListItems(), (_: any, currentPlanogramId: string) => currentPlanogramId],
        (items, currentProductId) => {
            if (!items || !currentProductId) {
                return null
            }
            return findNextPlanogramItemBy<IPlanogramList, '@id'>(items, '@id', currentProductId)
        }
    )

const makeSelectPlanogramListPrevItem = () =>
    createSelector(
        [makeSelectPlanogramsListItems(), (_: any, currentProductId: string) => currentProductId],
        (items, currentProductId) => {
            if (!items || !currentProductId) {
                return null
            }
            return findPreviousPlanogramItemBy<IPlanogramList, '@id'>(items, '@id', currentProductId)
        }
    )

export {
    selectPlanogramsDomain,
    makeSelectPlanogramsListParams,
    makeSelectPlanogramsListParamsPrev,
    makeSelectPlanogramsListItems,
    makeSelectPlanogramsListFetching,
    makeSelectPlanogramsListOrderValues,
    makeSelectPlanogramsListError,
    makeSelectPlanogramListNextItem,
    makeSelectPlanogramListPrevItem,
    makeSelectPlanogramsListTotalItems,
    makeSelectPlanogramsListFilters,
}

/*
 *
 * Planogram actions
 *
 */

import { action } from 'typesafe-actions'

import { IPlanogramGroupProcessPayload, IPlanogramProcessPayload } from './types'

import ActionTypes from './constants'

import { IAppErrorTypes } from '../app/types'
import { IApiPlanogramGroupResponse, IApiPlanogramResponse } from '../../services/api/service/planograms/types'

// GLOBAL
export const planogramResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)

// DETAIL
export const planogramDetailProcessAction = (planogramId: string, planogramGroupId?: string) =>
    action<ActionTypes.DETAIL_PROCESS_ACTION, IPlanogramProcessPayload>(ActionTypes.DETAIL_PROCESS_ACTION, {
        planogramId: planogramId,
        planogramGroupId: planogramGroupId,
    })
export const planogramDetailSuccessAction = (response: IApiPlanogramResponse) =>
    action<ActionTypes.DETAIL_SUCCESS_ACTION, { response: IApiPlanogramResponse }>(ActionTypes.DETAIL_SUCCESS_ACTION, {
        response,
    })
export const planogramDetailFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.DETAIL_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.DETAIL_FAILURE_ACTION, { error })
export const productPlanogramResetAction = () =>
    action<ActionTypes.DETAIL_RESET_ACTION>(ActionTypes.DETAIL_RESET_ACTION)

// GROUP
export const planogramGroupProcessAction = (productGroupId?: string | null) =>
    action<ActionTypes.GROUP_PROCESS_ACTION, IPlanogramGroupProcessPayload>(ActionTypes.GROUP_PROCESS_ACTION, {
        planogramGroupId: productGroupId,
    })
export const planogramGroupSuccessAction = (response: IApiPlanogramGroupResponse | null) =>
    action<ActionTypes.GROUP_SUCCESS_ACTION, { response: IApiPlanogramGroupResponse | null }>(
        ActionTypes.GROUP_SUCCESS_ACTION,
        { response }
    )
export const planogramGroupFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.GROUP_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.GROUP_FAILURE_ACTION, { error })
export const planogramGroupResetAction = () => action<ActionTypes.GROUP_RESET_ACTION>(ActionTypes.GROUP_RESET_ACTION)

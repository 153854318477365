import {
    CatalogListFilterProps,
    IBaseCatalogImage,
    ICatalogAttributeValue,
    ICatalogDetailImage,
    ICatalogImageCollection,
    ICatalogListImage,
    ICatalogListParameters,
} from '../core/types'
import { IApiResource, IPaginationApiResponse } from '../../types'
import { Nilable } from 'tsdef'
import { AxiosResponse, CancelToken } from 'axios'
import { IProductList } from '../products/types'
import { CollectionMap } from '../../../../types/common'

export interface IPlanogramAttributeValue extends ICatalogAttributeValue {}

export interface IPlanogramAttributeCollection {
    readonly brand: IPlanogramAttributeValue
    readonly line?: IPlanogramAttributeValue
}

// Mode de liste possible
export enum PlanogramsListMode {
    Default = 'planogram',
}

/*******************************
 * PLANOGRAM_GROUP ATTRIBUTES
 * ****************************/
export interface IPlanogramGroupAttributeValue {
    readonly id: number
    readonly label: string
    readonly planograms: Array<string>
    readonly color?: string | null
    readonly image?: string | null
}

export type IPlanogramGroupAttributeValueIdentifiers = CollectionMap<number>

export interface IPlanogramGroupAttribute {
    readonly attribute: string
    readonly name: string
    readonly values: Array<IPlanogramGroupAttributeValue>
}

export type IPlanogramGroupAttributeCollection = Array<IPlanogramGroupAttribute>

export interface IPlanogramCustomAttribute {
    readonly label: string
    readonly value: string
}

export type IPlanogramCustomAttributeCollection = Array<IPlanogramCustomAttribute>

/*******************************
 * Plano Images
 * ****************************/
export interface IBaseProductImage {
    readonly placeholder: string
}

export interface IPlanogramListImage extends ICatalogListImage {}
export interface IPlanogramDetailImage extends ICatalogDetailImage {}
export type IPlanogramImageCollection<T extends IBaseCatalogImage> = ICatalogImageCollection<T>

/*******************************
 * Planogram
 * ****************************/
export interface IPlanogramModuleProduct extends IApiResource<'ModuleProduct'> {
    readonly product: IProductList
    readonly quantity: number
}

export type PlanogramModuleProductCollection = Array<IPlanogramModuleProduct>

export interface IPlanogramModule extends IApiResource<'PlanogramModule'> {
    readonly color?: string
    readonly products: PlanogramModuleProductCollection
    readonly name: string
}
export type PlanogramModuleCollection = Array<IPlanogramModule>

export interface IBasePlanogram extends IApiResource<'Planogram'> {
    readonly name: string
    readonly module_width: number
    readonly module_height: number
    readonly modules_count: number
    readonly dimensions: string
    readonly full_name: string
    readonly pdf?: string
    readonly excel: string
}

export interface IPlanogramList extends IBasePlanogram {
    readonly main_pictures?: IPlanogramImageCollection<IPlanogramListImage>
}

export interface IPlanogramDetail extends IBasePlanogram {
    readonly modules: PlanogramModuleCollection
    readonly pictures: IPlanogramImageCollection<IPlanogramDetailImage>
    readonly group?: Nilable<string>
}

export type IApiPlanogramListFilterProps = CatalogListFilterProps

export interface IApiPlanogramGroupsCollection extends IApiResource {
    readonly planograms?: Array<Omit<IPlanogramList, 'pdf' | 'excel' | 'main_pictures'>>
}

export type IPlanogramListFiltersParameters = ICatalogListParameters
export type IApiPlanogramListPagination = IPaginationApiResponse<Array<IPlanogramList>> & IApiPlanogramListFilterProps
export type IAPiPlanogramListResponse = AxiosResponse<IApiPlanogramListPagination>
export type IApiPlanogramResponse = AxiosResponse<IPlanogramDetail>
export type IApiPlanogramGroupResponse = AxiosResponse<IApiPlanogramGroupsCollection>
export interface IPlanogramsService {
    list(parameters: IPlanogramListFiltersParameters, cancelToken?: CancelToken): Promise<IAPiPlanogramListResponse>
    get(planogramId: string, cancelToken?: CancelToken): Promise<IApiPlanogramResponse>
    planogramGroup(planogramGroupId: string, cancelToken?: CancelToken): Promise<IApiPlanogramGroupResponse>
}

import {
    IApiProductListPagination,
    IBaseProduct,
    IProductGridDataItems,
    IProductGridDataResponse,
    IProductGroupAttribute,
    IProductGroupAttributeCollection,
    IProductGroupAttributeValue,
    IProductGroupAttributeValueIdentifiers,
    IProductList,
    IProductListParameters,
    IProductsListGridDefinition,
    IProductsListGridDefinitionCollection,
    ProductListOrderType,
    ProductListQueryName,
    ProductListStaticFilterCodes,
    ProductsListDisplayMode,
} from '../../services/api/service/products/types'
import prop from '../../utils/prop'
import Qs from 'qs'
import { $enum } from 'ts-enum-util'
import { CollectionMap } from '../../types/common'
import intersection from 'lodash/intersection'
import { ApiListOrderDirection } from '../../services/api/types'
import cloneDeep from 'lodash/cloneDeep'
import { newDecoder } from '../../utils/qs'
import { ICustomer } from '../../services/api/service/customers/types'
import { BadgeUrlCollection, ProductBadgeType } from '../../types/productBadge'
import isUndefined from 'lodash/isUndefined'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import flatMapDeep from 'lodash/flatMap'
import forEach from 'lodash/forEach'
import {
    CatalogListFilterMode,
    IApiCatalogListFilterCollection,
    ICatalogListFilterCollection,
} from '../../services/api/service/core/types'
import {
    catalogFiltersParametersEquals,
    catalogListHasPublicFilters,
    formatCatalogListFilters,
    ICatalogListQueryParameters,
    retrieveCatalogListQueryParams,
} from '../../utils/catalog'
import { $PropertyType } from 'utility-types'
import { PRODUCT_PATHNAME_REGEXP } from '../../utils/productHelper'

export enum ProductListSidebarMode {
    Collapsed = 'collapsed',
    Expanded = 'expanded',
}

export type IProductListSidebarPersistParameters = Record<ProductsListDisplayMode, ProductListSidebarMode>

export interface IProductListQueryParameters extends ICatalogListQueryParameters {
    displayMode?: ProductsListDisplayMode
    productGridId?: string
}

export function retrieveProductListQueryParams(locationSearch: string): IProductListQueryParameters {
    // récupération de ceux partagés
    const info = retrieveCatalogListQueryParams(locationSearch)

    // @ts-ignore
    const parsed = Qs.parse(locationSearch.substring(1), {
        ignoreQueryPrefix: true,
        arrayFormat: 'bracket',
        decoder: newDecoder(),
    })

    const displayModeValue =
        typeof parsed[ProductListQueryName.DisplayMode] !== 'undefined'
            ? (parsed[ProductListQueryName.DisplayMode] as string)
            : undefined

    const displayMode =
        displayModeValue && $enum(ProductsListDisplayMode).isValue(displayModeValue) ? displayModeValue : undefined

    const productGridId =
        typeof parsed[ProductListQueryName.ProductGrid] !== 'undefined'
            ? (parsed[ProductListQueryName.ProductGrid] as string)
            : undefined

    // suppression des entrées précédentes
    let filters: $PropertyType<IProductListQueryParameters, 'filters'> = undefined

    if (info.filters) {
        filters = cloneDeep(info.filters)
        $enum(ProductListQueryName).forEach((k) => delete filters?.[k])
    }

    return {
        ...info,
        filters,
        displayMode,
        productGridId,
    }
}

export function formatProductListFilters(
    filters: IApiCatalogListFilterCollection,
    showPriceFilters?: boolean
): ICatalogListFilterCollection | undefined {
    const all: IApiCatalogListFilterCollection = []

    // ajout de nos propres filtres

    // prix
    if (showPriceFilters) {
        all.push({
            '@id': ProductListStaticFilterCodes.Price,
            '@type': 'Filter',
            code: ProductListStaticFilterCodes.Price,
            label: 'products.filter.price.label',
            options: [],
            mode: CatalogListFilterMode.Range,
            public: true,
            searchable: false,
            translatable: true,
            multiple: false,
            autoValidate: false,
            static: true,
            position: 500,
        })
    }

    // référentiel
    all.push({
        '@id': ProductListStaticFilterCodes.ListedOnly,
        '@type': 'Filter',
        code: ProductListStaticFilterCodes.ListedOnly,
        label: '',
        options: [],
        mode: CatalogListFilterMode.Boolean,
        public: false,
        searchable: false,
        multiple: true,
        translatable: true,
        autoValidate: true,
        static: true,
        position: 10,
    })

    // favoris
    all.push({
        '@id': ProductListStaticFilterCodes.Favorite,
        '@type': 'Filter',
        code: ProductListStaticFilterCodes.Favorite,
        label: '',
        options: [],
        mode: CatalogListFilterMode.Boolean,
        public: false,
        searchable: false,
        translatable: true,
        autoValidate: true,
        static: true,
        position: 10,
    })

    // best sellers
    all.push({
        '@id': ProductListStaticFilterCodes.BestSellers,
        '@type': 'Filter',
        code: ProductListStaticFilterCodes.BestSellers,
        label: '',
        options: [],
        mode: CatalogListFilterMode.Boolean,
        public: false,
        searchable: false,
        translatable: true,
        autoValidate: true,
        static: true,
        position: 10,
    })

    // arrivages
    all.push({
        '@id': ProductListStaticFilterCodes.ArrivalStocks,
        '@type': 'Filter',
        code: ProductListStaticFilterCodes.ArrivalStocks,
        label: '',
        options: [],
        mode: CatalogListFilterMode.Boolean,
        public: false,
        searchable: false,
        translatable: true,
        autoValidate: true,
        static: true,
        position: 10,
    })

    // new
    all.push({
        '@id': ProductListStaticFilterCodes.New,
        '@type': 'Filter',
        code: ProductListStaticFilterCodes.New,
        label: '',
        options: [],
        mode: CatalogListFilterMode.Boolean,
        public: false,
        searchable: false,
        translatable: true,
        autoValidate: true,
        static: true,
        position: 10,
    })

    // tri
    if (showPriceFilters) {
        all.push({
            '@id': ProductListStaticFilterCodes.Order,
            '@type': 'Filter',
            code: ProductListStaticFilterCodes.Order,
            label: 'products.order.label',
            options: [
                // {
                //     id: `${ProductListOrderType.Position}_${ApiListOrderDirection.Asc}`,
                //     label: '',
                // },
                {
                    id: `${ProductListOrderType.Price}_${ApiListOrderDirection.Asc}`,
                    label: '',
                },
                {
                    id: `${ProductListOrderType.Price}_${ApiListOrderDirection.Desc}`,
                    label: '',
                },
            ],
            mode: CatalogListFilterMode.Order,
            public: true,
            searchable: false,
            translatable: true,
            multiple: false,
            autoValidate: true,
            static: true,
            position: 550,
        })
    }

    // recherche
    all.push({
        '@id': ProductListStaticFilterCodes.Search,
        '@type': 'Filter',
        code: ProductListStaticFilterCodes.Search,
        label: '',
        options: [],
        mode: CatalogListFilterMode.Input,
        public: false,
        searchable: false,
        multiple: true,
        translatable: true,
        autoValidate: false,
        static: true,
        position: 10,
    })

    // tri
    all.sort((a, b) => {
        return a.position > b.position ? 1 : a.position === b.position ? 0 : -1
    })

    const final: ICatalogListFilterCollection = formatCatalogListFilters(filters) || {}
    all.forEach((single) => (final[single.code] = single))

    return final
}

export function productListHasPublicFilters(
    filters?: ICatalogListFilterCollection,
    includeStaticFilters = false
): boolean {
    return catalogListHasPublicFilters(filters, includeStaticFilters)
}

export function productFiltersParametersEquals<T extends IProductListParameters = IProductListParameters>(
    params: T,
    prevParams?: T
): boolean {
    return catalogFiltersParametersEquals<T>(params, prevParams, [ProductListStaticFilterCodes.Order])
}

export function findProductItemIndexBy<T extends IBaseProduct, K extends keyof T>(
    items: Array<T>,
    propertyName: K,
    value: any
): number | null {
    let index: number | null = null
    item_loop: for (let i = 0; i < items.length; i++) {
        const subproduct = items[i]
        if (prop(subproduct, propertyName) === value) {
            index = i
            break item_loop
        }
    }
    return index
}

export function findPreviousProductItemBy<T extends IBaseProduct, K extends keyof T>(
    items: Array<T>,
    propertyName: K,
    value: any
): T | null {
    const currentIndex: number | null = findProductItemIndexBy<T, K>(items, propertyName, value)
    if (currentIndex === null) {
        return null
    }
    const prevIndex: number = currentIndex - 1
    if (prevIndex > -1 && items[prevIndex]) {
        return items[prevIndex]
    }
    return null
}

export function findNextProductItemBy<T extends IBaseProduct, K extends keyof T>(
    items: Array<T>,
    propertyName: K,
    value: any
): T | null {
    const currentIndex: number | null = findProductItemIndexBy<T, K>(items, propertyName, value)
    if (currentIndex === null) {
        return null
    }
    const nextIndex: number = currentIndex + 1
    if (nextIndex <= items.length - 1 && items[nextIndex]) {
        return items[nextIndex]
    }
    return null
}

export function isProductUrl(pathname: string): boolean {
    return pathname.match(PRODUCT_PATHNAME_REGEXP) !== null
}

export function removeProductPathToPathName(pathName: string): string {
    // eslint-disable-next-line no-useless-escape
    return pathName.replace(PRODUCT_PATHNAME_REGEXP, '')
}

export function findProductIdsFromProductGroupAttributes(
    groupAttributes: IProductGroupAttributeCollection,
    selectedGroupAttributeIds: IProductGroupAttributeValueIdentifiers,
    productGroupAttributeValueSelected?: IProductGroupAttributeValue
): Array<string> | undefined {
    // on récupère le bon identifiant produit
    const selectedAttributeKeys = Object.keys(selectedGroupAttributeIds)
    const selectedAttributeProductIds: CollectionMap<Array<string>> = {}
    for (const selectedAttributeKey in selectedAttributeKeys) {
        // on récupère l'id qui nous intéresse
        const key: string = selectedAttributeKeys[selectedAttributeKey]
        const value = selectedGroupAttributeIds[key]
        for (const groupAttributeIndex in groupAttributes) {
            const groupAttribute: IProductGroupAttribute = groupAttributes[groupAttributeIndex]
            if (groupAttribute.attribute !== key) {
                continue
            }
            for (const groupAttributeValueIndex in groupAttribute.values) {
                const groupAttributeValue = groupAttribute.values[groupAttributeValueIndex]
                if (groupAttributeValue.id !== value) {
                    continue
                }
                selectedAttributeProductIds[key] = groupAttributeValue.products
            }
        }
    }

    const keys = Object.keys(selectedAttributeProductIds)
    let productIds: Array<string> = []
    if (keys.length === 1) {
        productIds = selectedAttributeProductIds[keys[0]]
    } else {
        productIds = intersection(...Object.values(selectedAttributeProductIds))
    }

    if (productIds.length === 0 && productGroupAttributeValueSelected) {
        productIds = productGroupAttributeValueSelected.products
    }

    return productIds.length > 0 ? productIds : undefined
}

export function getCustomerBadgesUrl(customer?: ICustomer): BadgeUrlCollection {
    if (!customer || !customer.buying_group_badge) {
        return {}
    }
    return {
        [ProductBadgeType.MyListing]: customer.buying_group_badge,
    }
}

export function isProductListResponse(object: any): object is IApiProductListPagination {
    return (
        isObject(object) &&
        !isUndefined(object['@id']) &&
        isString(object['@id']) &&
        object['@id'] === '/products' &&
        !isUndefined(object['@type']) &&
        isString(object['@type']) &&
        object['@type'] === 'hydra:Collection'
    )
}

export function isProductGridDataResponse(object: any): object is IProductGridDataResponse {
    return (
        isObject(object) &&
        !isUndefined(object['@type']) &&
        isString(object['@type']) &&
        object['@type'] === 'ProductGridData'
    )
}

export function isProductGridDataItems(
    object: any,
    displayMode: ProductsListDisplayMode
): object is IProductGridDataItems {
    return (
        !isUndefined(object) && isObject(object) && !isArray(object) && displayMode === ProductsListDisplayMode.GridData
    )
}

export function isProductListCollection(
    object: any,
    displayMode: ProductsListDisplayMode
): object is Array<IProductList> {
    return !isUndefined(object) && isArray(object) && displayMode === ProductsListDisplayMode.Default
}

export function productGridFlattenDefinitions(definition: IProductsListGridDefinition) {
    if (!definition.children || !definition.children.length) {
        return [definition]
    }
    return [definition, ...flatMapDeep(definition.children, productGridFlattenDefinitions)]
}

export function productGridPathToDef(array: IProductsListGridDefinitionCollection, target: string): string | null {
    let result: string | null = null
    array.some(({ key, children = [] }) => {
        if (key === target) return (result = key)
        const temp = productGridPathToDef(children, target)
        if (temp) return (result = key + '/' + temp)
    })
    return result
}

export function productGridExtractAllProductIds(
    columns: IProductsListGridDefinitionCollection,
    rows: IProductsListGridDefinitionCollection,
    items: IProductGridDataItems,
    definition?: IProductsListGridDefinition
): Array<string> {
    let productIds: Array<string> = []

    if (!definition) {
        // récupération de tous les identifiants de produits
        Object.values(items).forEach((list) => {
            forEach(list, (product) => {
                productIds.push(product['@id'])
            })
        })
        return productIds
    }

    // extraction columnKeys
    const columnKeys = flatMapDeep(columns, productGridFlattenDefinitions).map((column) => column.key)
    // est-ce que la définition est une colonne ?
    const isColumnDef = columnKeys.indexOf(definition.key) > -1
    // est-ce que la définition a des enfants ?
    const subs = definition.children.length > 0 ? definition.children : [definition]
    // les definition
    const defs = isColumnDef ? columns : rows
    // quelles définitions on doit parcourir ?
    const otherDefs = isColumnDef ? rows : columns
    // stockage des paths permettant d'accéder à sa liste de produits
    const defPaths: Array<string> = []

    forEach(subs, (sub) => {
        const subPath = productGridPathToDef(defs, sub.key)
        forEach(otherDefs, (otherDef) => {
            const subOtherDefs = otherDef.children.length > 0 ? otherDef.children : [otherDef]
            forEach(subOtherDefs, (subOtherDef) => {
                const otherDefPath = productGridPathToDef([otherDef], subOtherDef.key)
                const finalPath = isColumnDef ? `${otherDefPath}/${subPath}` : `${subPath}/${otherDefPath}`
                // dans le cas d'une définition de colonne, il faut inverser car on commence l'id toujours par la row
                defPaths.push(finalPath)
            })
        })
    })

    // récupération des identifiants des produits
    forEach(defPaths, (defPath) => {
        // console.log({ defPath, items: items[defPath] || 'NOPE' })
        if (items[defPath]) {
            productIds = [...productIds, ...items[defPath].map((item) => item['@id'])]
        }
    })

    return productIds
}

export function productHasDiscount(product: IProductList): boolean {
    return product.discount === true
}

import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Config from '../../config'
import { ICatalogListQueryParameters } from '../../utils/catalog'
import { retrievePlanogramListQueryParams } from '../../store/planograms/utils'

export function usePlanogramListParsedQuery() {
    const { search } = useLocation()
    const [parsedQueries, setParsedQueries] = useState<ICatalogListQueryParameters | undefined>(undefined)

    useEffect(() => {
        const queries = retrievePlanogramListQueryParams(search)
        setParsedQueries({
            filters: queries.filters,
            page: queries.page || 1,
            itemsPerPage: queries.itemsPerPage || Config.PRODUCT_LIST.ITEMS_PER_PAGE,
        })
    }, [search])

    return {
        queries: parsedQueries,
    }
}

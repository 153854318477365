// LIBRARIES
import classNames from 'classnames'
import React, { useMemo } from 'react'

import { IPlanogramDetail, IPlanogramModule } from '../../../services/api/service/planograms/types'
import ModuleProduct from './ModuleProduct'
import AddToCart from '../../Planograms/Partial/Actions/AddToCart'
import { FormattedMessage, useIntl } from 'react-intl'
import { Alert, Table } from 'react-bootstrap'
import { createStructuredSelector } from 'reselect'
import { makeSelectClassificationAttributeDefinition } from '../../../store/classification/selectors'
import { useSelector } from 'react-redux'
import { IApplicationRootState } from '../../../store'
import { IAttributeDefinitionCollection } from '../../../services/api/service/classification/types'

interface IProps {
    className?: string
    disabled?: boolean
    planogram: IPlanogramDetail
    module: IPlanogramModule
}

const stateSelector = createStructuredSelector<any, any>({
    definition: makeSelectClassificationAttributeDefinition(),
})

function Module({ planogram, module, className, disabled }: IProps): JSX.Element {
    const { formatMessage } = useIntl()
    const hasProducts = useMemo(() => module.products.length > 0, [module.products])
    const { definition } = useSelector<IApplicationRootState, { definition: IAttributeDefinitionCollection }>(
        stateSelector
    )

    const collectionDef = useMemo(() => definition.find((def) => def.code === 'collection'), [definition])

    return (
        <div className={classNames('planogram-module', className)}>
            {hasProducts ? (
                <>
                    <AddToCart
                        planogram={planogram}
                        planogramModule={module}
                        className="add-to-cart-top mb-3"
                        variant="outline-primary"
                        label={formatMessage({ id: 'planogram_module.add_to_cart' })}
                    />
                    <Table striped bordered={false} hover responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th className="col-attribute">{collectionDef?.name || '-'}</th>
                                <th className="col-reference">
                                    <FormattedMessage id="product.reference_abbr" />
                                </th>
                                <th className="col-qty" colSpan={2}>
                                    <FormattedMessage id="cart.qty" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {module.products.map((product, i) => (
                                <ModuleProduct
                                    tabIndex={i + 1}
                                    key={product['@id']}
                                    moduleProduct={product}
                                    disabled={disabled}
                                />
                            ))}
                        </tbody>
                    </Table>

                    {module.products.length > 10 && (
                        <AddToCart
                            planogram={planogram}
                            planogramModule={module}
                            className="add-to-cart-bottom"
                            variant="outline-primary"
                            label={formatMessage({ id: 'planogram_module.add_to_cart' })}
                        />
                    )}
                </>
            ) : (
                <>
                    <Alert variant="info" className="mb-0">
                        <Alert.Heading>{module.name}</Alert.Heading>
                        <p className="mb-0">
                            <FormattedMessage id="planogram_module.no_products" />
                        </p>
                    </Alert>
                </>
            )}
        </div>
    )
}

Module.defaultProps = {
    modal: false,
} as Partial<IProps>

export default Module

import prop from '../../utils/prop'
import { CollectionMap } from '../../types/common'
import intersection from 'lodash/intersection'
import isUndefined from 'lodash/isUndefined'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import {
    IApiCatalogListFilterCollection,
    ICatalogListFilterCollection,
    ICatalogListParameters,
} from '../../services/api/service/core/types'
import {
    catalogFiltersParametersEquals,
    catalogListHasPublicFilters,
    formatCatalogListFilters,
    ICatalogListQueryParameters,
    retrieveCatalogListQueryParams,
} from '../../utils/catalog'
import {
    IApiPlanogramListPagination,
    IBasePlanogram,
    IPlanogramGroupAttribute,
    IPlanogramGroupAttributeCollection,
    IPlanogramGroupAttributeValue,
    IPlanogramGroupAttributeValueIdentifiers,
} from '../../services/api/service/planograms/types'

export interface IPlanogramListQueryParameters extends ICatalogListQueryParameters {}
export function retrievePlanogramListQueryParams(locationSearch: string): IPlanogramListQueryParameters {
    return retrieveCatalogListQueryParams(locationSearch)
}

export function formatPlanogramListFilters(
    filters: IApiCatalogListFilterCollection
): ICatalogListFilterCollection | undefined {
    return formatCatalogListFilters(filters)
}

export function planogramListHasPublicFilters(
    filters?: ICatalogListFilterCollection,
    includeStaticFilters = false
): boolean {
    return catalogListHasPublicFilters(filters, includeStaticFilters)
}

export function planogramFiltersParametersEquals(
    params: ICatalogListParameters,
    prevParams?: ICatalogListParameters
): boolean {
    return catalogFiltersParametersEquals(params, prevParams)
}

export function findPlanogramItemIndexBy<T extends IBasePlanogram, K extends keyof T>(
    items: Array<T>,
    propertyName: K,
    value: any
): number | null {
    let index: number | null = null
    item_loop: for (let i = 0; i < items.length; i++) {
        const entity = items[i]
        if (prop(entity, propertyName) === value) {
            index = i
            break item_loop
        }
    }
    return index
}

export function findPreviousPlanogramItemBy<T extends IBasePlanogram, K extends keyof T>(
    items: Array<T>,
    propertyName: K,
    value: any
): T | null {
    const currentIndex: number | null = findPlanogramItemIndexBy<T, K>(items, propertyName, value)
    if (currentIndex === null) {
        return null
    }
    const prevIndex: number = currentIndex - 1
    if (prevIndex > -1 && items[prevIndex]) {
        return items[prevIndex]
    }
    return null
}

export function findNextPlanogramItemBy<T extends IBasePlanogram, K extends keyof T>(
    items: Array<T>,
    propertyName: K,
    value: any
): T | null {
    const currentIndex: number | null = findPlanogramItemIndexBy<T, K>(items, propertyName, value)
    if (currentIndex === null) {
        return null
    }
    const nextIndex: number = currentIndex + 1
    if (nextIndex <= items.length - 1 && items[nextIndex]) {
        return items[nextIndex]
    }
    return null
}

// a garder en synchro avec routes/routes.tsx
// 2c081c40-3ba1-439e-967f-8f36a84f0723
// ATTENTION : mettre à jour aussi routes.tsx si changement
export const PLANOGRAM_REGEXP = /(\/planograms\/[\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12})/

export function isPlanogramUrl(pathname: string): boolean {
    return pathname.match(PLANOGRAM_REGEXP) !== null
}

export function removePlanogramPathToPathName(pathName: string): string {
    // eslint-disable-next-line no-useless-escape
    return pathName.replace(PLANOGRAM_REGEXP, '')
}

export function findPlanogramIdsFromPlanogramGroupAttributes(
    groupAttributes: IPlanogramGroupAttributeCollection,
    selectedGroupAttributeIds: IPlanogramGroupAttributeValueIdentifiers,
    planogramGroupAttributeValueSelected?: IPlanogramGroupAttributeValue
): Array<string> | undefined {
    // on récupère le bon identifiant produit
    const selectedAttributeKeys = Object.keys(selectedGroupAttributeIds)
    const selectedAttributeProductIds: CollectionMap<Array<string>> = {}
    for (const selectedAttributeKey in selectedAttributeKeys) {
        // on récupère l'id qui nous intéresse
        const key: string = selectedAttributeKeys[selectedAttributeKey]
        const value = selectedGroupAttributeIds[key]
        for (const groupAttributeIndex in groupAttributes) {
            const groupAttribute: IPlanogramGroupAttribute = groupAttributes[groupAttributeIndex]
            if (groupAttribute.attribute !== key) {
                continue
            }
            for (const groupAttributeValueIndex in groupAttribute.values) {
                const groupAttributeValue = groupAttribute.values[groupAttributeValueIndex]
                if (groupAttributeValue.id !== value) {
                    continue
                }
                selectedAttributeProductIds[key] = groupAttributeValue.planograms
            }
        }
    }

    const keys = Object.keys(selectedAttributeProductIds)
    let productIds: Array<string> = []
    if (keys.length === 1) {
        productIds = selectedAttributeProductIds[keys[0]]
    } else {
        productIds = intersection(...Object.values(selectedAttributeProductIds))
    }

    if (productIds.length === 0 && planogramGroupAttributeValueSelected) {
        productIds = planogramGroupAttributeValueSelected.planograms
    }

    return productIds.length > 0 ? productIds : undefined
}

export function isPlanogramListResponse(object: any): object is IApiPlanogramListPagination {
    return (
        isObject(object) &&
        !isUndefined(object['@id']) &&
        isString(object['@id']) &&
        object['@id'] === '/planograms' &&
        !isUndefined(object['@type']) &&
        isString(object['@type']) &&
        object['@type'] === 'hydra:Collection'
    )
}

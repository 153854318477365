/**
 *
 * NotFoundAlert
 *
 */
import React, { memo, useMemo } from 'react'
import { Alert } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    IProductListParameters,
    IProductListPersistParameters,
    ProductListStaticFilterCodes,
    ProductsListDisplayMode,
    ProductsListMode,
} from '../../../services/api/service/products/types'

export enum NotFoundReasonModes {
    UnknownFamilyTree,
    UnknownCategory,
    NoResultsSearch,
    NoResultsFilters,
    NoResults,
}
export enum NotFoundReasonAction {
    ResetListedOnly,
    RemoveFilters,
    GoBack,
}

interface IProps {
    mode: ProductsListMode
    displayMode: ProductsListDisplayMode
    reason: NotFoundReasonModes
    queries?: IProductListParameters | undefined
    persistedParams?: IProductListPersistParameters | undefined
    onClick: (action: NotFoundReasonAction) => void
    salesman?: boolean
}

function NotFoundAlert({ reason, mode, queries, persistedParams, salesman = false }: IProps): JSX.Element {
    const { formatMessage } = useIntl()
    const title = useMemo(() => {
        if (mode === ProductsListMode.BestSeller) {
            return formatMessage({ id: 'page.best_seller' })
        } else if (mode === ProductsListMode.Favorite) {
            return formatMessage({ id: 'page.favorite' })
        } else if (mode === ProductsListMode.ArrivalStocks) {
            return formatMessage({ id: 'page.arrival_stocks' })
        } else if (mode === ProductsListMode.Discount) {
            return formatMessage({ id: 'page.discounts' })
        } else if (mode === ProductsListMode.New) {
            return formatMessage({ id: 'page.fresh' })
        } else if (mode === ProductsListMode.MyListing) {
            return formatMessage({ id: 'page.my_listing' })
        }

        if (reason === NotFoundReasonModes.NoResultsSearch) {
            return formatMessage({ id: 'page.search' })
        } else if (reason === NotFoundReasonModes.NoResultsFilters) {
            return formatMessage({ id: 'page.search' })
        }

        return formatMessage({ id: 'products.not_found.title' })
    }, [mode, reason, formatMessage])

    const listedOnlyQuestion = useMemo(() => {
        return persistedParams?.listed_only === 1
    }, [persistedParams])

    const message = useMemo(() => {
        if (reason === NotFoundReasonModes.NoResultsSearch) {
            const keywords: string =
                typeof queries !== 'undefined' &&
                typeof queries.filters !== 'undefined' &&
                typeof queries.filters[ProductListStaticFilterCodes.Search] === 'string'
                    ? (queries.filters[ProductListStaticFilterCodes.Search] as string)
                    : ''
            return formatMessage({ id: 'products.no_searched_results' }, { keyword: keywords })
        } else if (reason === NotFoundReasonModes.NoResults) {
            if (mode === ProductsListMode.BestSeller) {
                return formatMessage({ id: 'best_sellers.no_results' })
            } else if (mode === ProductsListMode.Favorite) {
                return formatMessage({ id: 'favorites.no_results' })
            } else if (mode === ProductsListMode.ArrivalStocks) {
                return formatMessage({ id: 'arrival_stocks.no_results' })
            } else if (mode === ProductsListMode.Discount) {
                return formatMessage({ id: 'discounts.no_results' })
            } else if (mode === ProductsListMode.New) {
                return formatMessage({ id: 'fresh.no_results' })
            } else if (mode === ProductsListMode.MyListing) {
                return formatMessage({ id: 'my_listing.no_results' })
            }
        } else if (reason === NotFoundReasonModes.NoResultsFilters) {
            return formatMessage({ id: 'products.no_filtered_results' })
        } else if (reason === NotFoundReasonModes.UnknownFamilyTree) {
            return formatMessage(
                { id: 'products.not_found' },
                {
                    name: window.location.pathname.split('/').slice(-1)!.pop()!.toUpperCase().replace('-', ' '),
                }
            )
        }

        return formatMessage({ id: 'products.not_found.title' })
    }, [reason, formatMessage, queries, mode])

    return (
        <Alert variant={'danger'}>
            <Alert.Heading>{title}</Alert.Heading>
            <p>{message}</p>
            {listedOnlyQuestion && (
                <>
                    <p>
                        <FormattedMessage
                            id={salesman ? 'products.salesman_listed_only_question' : 'products.listed_only_question'}
                        />
                    </p>
                </>
            )}
        </Alert>
    )
}

NotFoundAlert.defaultProps = {} as Partial<IProps>

export default memo(NotFoundAlert)

/**
 *
 * Mobile
 *
 */
import React, { memo, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import MobileFilterList from '../../Catalog/Partial/Filter/Mobile'
import { IProductListParameters } from '../../../services/api/service/products/types'
import { IApplicationRootState } from '../../../store'
import {
    makeSelectProductsListFetching,
    makeSelectProductsListFilters,
    makeSelectProductsListParams,
    makeSelectProductsListTotalItems,
} from '../../../store/products/selectors'
import { ICatalogListFilter, ICatalogListFilterCollection } from '../../../services/api/service/core/types'
import { customerFilterHasSelectedValues } from '../../../utils/catalog'
import List from './List'

const stateSelector = createStructuredSelector<any, any>({
    loading: makeSelectProductsListFetching(),
    count: makeSelectProductsListTotalItems(),
    params: makeSelectProductsListParams(),
    filters: makeSelectProductsListFilters(),
})

export const PRODUCT_LIST_MOBILE_FILTER_CONTENT_ID = 'mobile-filter-content-page'

function Mobile(): JSX.Element {
    const [showMobileDetailPage, setShowMobileDetailPage] = useState<boolean>(false)
    const { loading, count, params, filters } = useSelector<
        IApplicationRootState,
        {
            loading: boolean
            params: IProductListParameters
            filters?: ICatalogListFilterCollection
            count: number | undefined
        }
    >(stateSelector)

    const handleFilterToggleOpen = useCallback(
        (filter: ICatalogListFilter, open: boolean) => {
            setShowMobileDetailPage(open)
        },
        [setShowMobileDetailPage]
    )

    //ON OK
    const handleHideDetail = useCallback(() => setShowMobileDetailPage(false), [])
    const hasSelectedValues = useMemo(() => {
        return customerFilterHasSelectedValues(filters, params)
    }, [filters, params])

    return (
        <MobileFilterList<IProductListParameters>
            count={count}
            loading={loading}
            hasSelectedValues={hasSelectedValues}
            portalId={PRODUCT_LIST_MOBILE_FILTER_CONTENT_ID}
            showDetail={showMobileDetailPage}
            onHideDetail={handleHideDetail}
        >
            <List filterPortalId={PRODUCT_LIST_MOBILE_FILTER_CONTENT_ID} onFilterToggleOpen={handleFilterToggleOpen} />
        </MobileFilterList>
    )
}

export default memo(Mobile)

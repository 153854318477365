import Qs from 'qs'
import rtrim from './rtrim'
import { removePlanogramPathToPathName } from '../store/planograms/utils'

export enum PlanogramDocumentType {
    Excel = 'excel',
    Pdf = 'pdf',
}

export enum PlanogramListAction {
    MassAddToCart = 'mass_add_to_cart',
    DownloadPdf = 'download_pdf',
    DownloadExel = 'download_excel',
}

export function generatePlanogramUrl(
    id: string,
    locale: string,
    addToPath = true,
    addQueries = true,
    relativeUrl: string | undefined = undefined
): string {
    if (!addToPath) {
        return `/${locale}${id}`
    }
    const search = relativeUrl ? relativeUrl.split('?')[1] || '' : window.location.search.substring(1)
    const parsed = Qs.parse(search)
    const stringified = Qs.stringify(parsed)
    const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
    const baseUrl = removePlanogramPathToPathName(rtrim(relativeUrl || window.location.pathname, '/'))
    return addQueries ? `${baseUrl}${id}${qstring}` : `${baseUrl}${id}`
}

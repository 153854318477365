import React, { useCallback } from 'react'
import { IPlanogramList } from '../../../../services/api/service/planograms/types'
import classNames, { Value as ClassValue } from 'classnames'
import Picture from '../../../../components/Catalog/Picture/Picture'
import { PlanogramClickCallback } from '../../../../types/planogramCallback'

export default function MainPicture({
    planogram,
    className,
    onClick,
}: {
    planogram: IPlanogramList
    className?: ClassValue
    onClick?: PlanogramClickCallback
}): JSX.Element {
    const handleActionClick = useCallback(
        (evt: React.MouseEvent) => {
            onClick?.(evt, planogram)
        },
        [onClick, planogram]
    )

    return (
        <Picture
            loadEagerly
            className={classNames('planogram-image', 'planogram-image-list', className)}
            src={planogram.main_pictures?.[0].list}
            alt={planogram.full_name}
            placeholder={planogram.main_pictures?.[0].placeholder}
            onClick={handleActionClick}
        />
    )
}

/**
 *
 * Product Prices
 *
 */
import React, { memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { CatalogUnitType } from '../../../services/api/service/core/types'

interface IProps {
    packing: number
    abbreviate: boolean
    suffix: boolean
    unitOfSale?: CatalogUnitType
}

function PackingDisplayValue({ packing, abbreviate, suffix, unitOfSale }: IProps): JSX.Element {
    const { formatMessage } = useIntl()

    const displayValue = useMemo(() => {
        if (unitOfSale === CatalogUnitType.Unit) {
            if (abbreviate || suffix) {
                return String(packing)
            }
            return formatMessage({ id: 'product.packing_plural' }, { itemCount: packing })
        }

        if (abbreviate) {
            const identifier = suffix ? 'product.packing_meter_abbr' : 'product.meter_abbr'
            return formatMessage({ id: identifier }, { itemCount: packing })
        }
        const identifier = suffix ? 'product.packing_meter_plural' : 'product.meter_plural'
        return formatMessage({ id: identifier }, { itemCount: packing })
    }, [unitOfSale, abbreviate, suffix, formatMessage, packing])

    return (
        <>
            <span className={'value'}>{displayValue}</span>
        </>
    )
}

PackingDisplayValue.defaultProps = {
    abbreviate: true,
    suffix: false,
    unitOfSale: CatalogUnitType.Unit,
} as Partial<IProps>

export default memo(PackingDisplayValue)

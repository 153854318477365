// LIBRARIES
import classNames from 'classnames'
import React from 'react'

import { IBasePlanogram } from '../../../../services/api/service/planograms/types'

interface IProps {
    className?: string
    planogram: IBasePlanogram
}

function Heading({ className, planogram }: IProps): JSX.Element {
    return (
        <div className={classNames('planogram-heading', className)}>
            <div className="planogram-heading-inner">
                <h2 className="planogram-name">{planogram?.full_name}</h2>
            </div>
        </div>
    )
}

Heading.defaultProps = {
    modal: false,
} as Partial<IProps>

export default Heading

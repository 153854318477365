import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import * as stockAlertActions from '../stockAlert/actions'

import { IApplicationRootState } from '../index'
import {
    IApiCartLightListResponse,
    ICart,
    ICartCollection,
    ICartItem,
    ICartItemCollection,
    ICartLightCollection,
    ICartMode,
    ICartValidationMode,
    ICartDuplicateParameters,
    IReOrderParameters,
    IMultiStoreStoreCartCollection,
    IMultiStoreCartsParameters,
    IApiMultiStoreCartCollection,
    ISalesmanCartCollection,
    ISalesmanCartListParameters,
} from '../../services/api/service/carts/types'
import { CollectionMap } from '../../types/common'
import { Action } from 'redux'
import { AxiosError } from 'axios'
import { IOrdersCreateCollection } from '../../services/api/service/orders/types'
import { IAppErrorTypes } from '../app/types'
import { IRequestPaginationProcessState, IRequestProcessState } from '../http/types'
import { ProductStockAlertCollection } from '../../services/api/service/products/types'
import { $PropertyType } from 'utility-types'
import { IBasePlanogram, IPlanogramModule } from '../../services/api/service/planograms/types'
import { Undefinable } from 'tsdef'

export type ICreateOrderDataChildType = any
export type ICreateOrderDataType = {
    comment?: string | null
    date?: Date | null
    payment_mode?: string | null
    validate_only_minimum_amount_orders?: boolean
}

export type IReOrderData = Omit<IReOrderParameters, 'order'>
export type IDuplicateData = Omit<ICartDuplicateParameters, 'cart'>
export type ICreateOrderDataCollectionType = CollectionMap<ICreateOrderDataType>
export type IReOrdersData = CollectionMap<IReOrderData>
export type IDuplicatesData = CollectionMap<IDuplicateData>
export type ICreateOrderErrorsType = CollectionMap<IAppErrorTypes>
export type IReOrderErrorsType = CollectionMap<IAppErrorTypes>
export type IDuplicateErrorsType = CollectionMap<IAppErrorTypes>
export type ICartItemErrorsType = CollectionMap<Array<string>>
export type ICartItemQuantities = CollectionMap<CollectionMap<number>>
export type ICartItemCanIncrementQuantity = CollectionMap<CollectionMap<boolean>>
export type ICartItemStoreQuantity = CollectionMap<number>
export type ICartsCleanErrorsType = CollectionMap<IAppErrorTypes>
export type ICartsLockerErrorsType = CollectionMap<Array<IAppErrorTypes>>
export type ICreateOrderItems = CollectionMap<IOrdersCreateCollection>

export interface ICartItemStoreQuantityState {
    loading: boolean
    error?: IAppErrorTypes
    updating?: CollectionMap<boolean>
    quantities?: ICartItemStoreQuantity
}

export type ICartItemStoreQuantitiesState = CollectionMap<ICartItemStoreQuantityState>

export interface ICartLightState extends IRequestProcessState {
    list?: ICartLightCollection
}
export type ICartLightStates = CollectionMap<ICartLightState>
export type ICartChildData = ICartLightState
export type ICartChildrenData = CollectionMap<ICartChildData>
export type ICartLockedData = CollectionMap<boolean>
export type IStoreCartDetailState = IRequestProcessState
export type IStoreCartDetailsState = CollectionMap<IStoreCartDetailState>

export type ICartLockedState = {
    shipping_location: ICartLockedData
    cart: ICartLockedData
}
export type ICartItemState = {
    canIncrementQuantity: ICartItemCanIncrementQuantity
    quantities: ICartItemQuantities
    errors?: ICartItemErrorsType
}

export enum BulkCartQuantityAction {
    Add = 'add',
    Remove = 'remove',
}

export enum BulkCartQuantityMode {
    List = 'list',
    Picker = 'picker',
    Grid = 'definition',
    Planogram = 'planogram',
    PlanogramModule = 'planogram_module',
}

export interface IBulkQuantityState extends IRequestProcessState {
    mode: BulkCartQuantityMode
    list?: ICartItemCollection
    quantity?: number
    success?: boolean
    unavailable_stock_products?: ProductStockAlertCollection
    planogramId?: $PropertyType<IBasePlanogram, '@id'>
    planogramModuleId?: $PropertyType<IPlanogramModule, '@id'>
}

export interface IMultiStoreCartListState extends IRequestProcessState {
    items?: IMultiStoreStoreCartCollection
}

export type ISalesmanCartListState = IRequestPaginationProcessState<ISalesmanCartCollection>

export interface IMultiStoreState {
    store_cart_list: IMultiStoreCartListState
}

export type ICartItemsState = {
    main: ICartItemState
    sub: ICartItemState
    fetching: CollectionMap<boolean>
    store_quantities: ICartItemStoreQuantitiesState
}

export type ICartsState = {
    main: Array<string>
    list: ICartCollection
    light: ICartLightStates
    load: IStoreCartDetailsState
    items: ICartItemsState
    bulk: IBulkQuantityState
    locked: ICartLockedState
    settings?: Partial<ICartsPersistParameters>
    multi_store: IMultiStoreState
    salesman: ISalesmanCartListState
    orders: {
        create: {
            fetching: CollectionMap<boolean>
            data: ICreateOrderDataCollectionType
            errors: ICreateOrderErrorsType
            items: ICreateOrderItems
        }
        reorder: {
            fetching: CollectionMap<boolean>
            data: IReOrdersData
            errors: IReOrderErrorsType
            success: CollectionMap<boolean>
            warnings: CollectionMap<Array<string>>
        }
    }
    duplicate: {
        fetching: CollectionMap<boolean>
        data: IDuplicatesData
        errors: IDuplicateErrorsType
        success: CollectionMap<boolean>
        warnings: CollectionMap<Array<string>>
    }
    lock: {
        fetching: CollectionMap<boolean>
        errors?: ICartsLockerErrorsType
        success?: CollectionMap<boolean>
    }
    clean: {
        fetching: CollectionMap<boolean>
        errors?: ICartsCleanErrorsType
    }
    visibility: {
        banner: boolean
    }
}

export interface IBaseProductToCartPayload {
    productId: string
    origin?: string
    storeId?: string
    cartId?: string
}
export interface IAddToCartProcessPayload extends IBaseProductToCartPayload {
    quantity: number
    quantityPrev: number
}

export type RevertQuantityErrorType = Error | AxiosError
export type RevertQuantityErrorsType = Array<string>

export interface ICartRevertQuantityPayload {
    productId: string
    quantity: number
    origin?: string
    storeId?: string
    cartId?: string
    error?: RevertQuantityErrorType
    errors?: RevertQuantityErrorsType
}

export interface IAddToCartSuccessPayload {
    cartItem: ICartItem
    origin?: string
    storeId?: string
    cartId?: string
}

export interface IAddToCartSuccessAction extends Action {
    payload: IAddToCartSuccessPayload
}

export interface ICartsRefreshPayload {
    carts: Array<ICart>
    main?: boolean
}

export interface ICartRefreshPayload {
    cart: ICart
    main?: boolean
}

export interface IRemoveToCartProcessPayload extends IBaseProductToCartPayload {
    quantity: number
    quantityPrev: number
}

export type IRemoveToCartSuccessPayload = IBaseProductToCartPayload & {
    cartItem?: ICartItem
}

export interface IAddToCartProcessAction extends Action {
    payload: IAddToCartProcessPayload
}
export interface IRemoveToCartProcessAction extends Action {
    payload: IRemoveToCartProcessPayload
}

export interface IRemoveToCartSuccessAction extends Action {
    payload: IRemoveToCartSuccessPayload
}

export type ICartReOrderResetPayload = {
    resourceId: string
}

export type ICartReOrderProcessPayload = {
    resourceId: string
    data: IReOrderParameters
    customerId?: string
}

export type ICartDuplicateProcessPayload = {
    resourceId: string
    data: ICartDuplicateParameters
}

export interface ICartReOrderProcessAction extends Action {
    payload: ICartReOrderProcessPayload
}

export interface ICartDuplicateProcessAction extends Action {
    payload: ICartDuplicateProcessPayload
}

export interface ICartReOrderSuccessPayload {
    resourceId: string
    carts: ICartCollection
    warnings?: Array<string>
}

export interface ICartReOrderFailurePayload {
    resourceId: string
    error: IAppErrorTypes
}

export type ICartLockerProcessPayload = {
    cartId: string
    lock: boolean
    processApiCall: boolean
    reloadCarts: boolean
}

export type ICartLockerResetPayload = {
    cartId?: string
}

export type ICartLockerSuccessPayload = {
    cart: ICart
    lock: boolean
}

export type ICartsLockerProcessPayload = {
    cartIds: Array<string>
    lock: boolean
}

export type ICartsLockerResetPayload = {
    cartIds?: Array<string>
}

export interface ICartLockerFailurePayload {
    cartId: string
    error: IAppErrorTypes
    lock: boolean
}

export type ICartsLockerSuccessPayload = {
    cartIds: Array<string>
    errors?: Array<IAppErrorTypes>
    lock: boolean
}

export interface ICartsLockerFailurePayload {
    cartIds: Array<string>
    errors?: Array<IAppErrorTypes>
    lock: boolean
}

export interface ICartLockerProcessAction extends Action {
    payload: ICartLockerProcessPayload
}

export interface ICartLockerSuccessAction extends Action {
    payload: ICartLockerSuccessPayload
}

export interface ICartsLockerProcessAction extends Action {
    payload: ICartsLockerProcessPayload
}

export interface ICartsLockerSuccessAction extends Action {
    payload: ICartsLockerSuccessPayload
}

export type ICartSaveToOrderProcessPayload = {
    cartId: string
    data: ICreateOrderDataType
    processApiCall: boolean
    redirectAfterSuccess?: boolean
}

export interface ICartSaveToOrderSuccessPayload {
    cartId: string
    orders: IOrdersCreateCollection
    redirectAfterSuccess?: boolean
}

export interface ICartSaveToOrderFailurePayload {
    cartId?: string
    error: IAppErrorTypes
}

export interface ICartSaveToOrdersProcessPayload {
    cartIds: Array<string>
    data: ICreateOrderDataCollectionType
}

export interface ICartSaveToOrdersSuccessPayload {
    cartIds: Array<string>
    orders: CollectionMap<IOrdersCreateCollection>
    errors?: CollectionMap<IAppErrorTypes>
}

export interface ICartSaveToOrdersFailurePayload {
    cartIds: Array<string>
    errors?: CollectionMap<IAppErrorTypes>
}

export interface ICartItemRefreshErrorsPayload {
    cartId: string
    errors: ICartItemErrorsType
}

export interface ICartSaveToOrderProcessAction extends Action {
    payload: ICartSaveToOrderProcessPayload
}

export interface ICartSaveToOrderSuccessAction extends Action {
    payload: ICartSaveToOrderSuccessPayload
}

export interface ICartSaveToOrdersProcessAction extends Action {
    payload: ICartSaveToOrdersProcessPayload
}

export interface IBaseCartStoreQuantityPayload {
    productId: string
}

export type ICartStoreQuantityProcessPayload = IBaseCartStoreQuantityPayload
export interface ICartStoreQuantityProcessAction extends Action {
    payload: ICartStoreQuantityProcessPayload
}

export type ICartStoreQuantitySuccessPayload = IBaseCartStoreQuantityPayload & {
    storeQuantity: ICartItemStoreQuantity
}

export interface ICartStoreQuantitySuccessAction extends Action {
    payload: ICartStoreQuantitySuccessPayload
}

export type ICartStoreQuantityFailurePayload = IBaseCartStoreQuantityPayload & {
    error: IAppErrorTypes
}

export interface ICartStoreQuantityFailureAction extends Action {
    payload: ICartStoreQuantityFailurePayload
}

export interface ICartsBaseCleanPayload {
    cartIds: Array<string>
}

export interface ICartsCleanSuccessPayload {
    carts: ICartCollection
}

export interface ICartsCleanProcessAction extends Action {
    payload: ICartsBaseCleanPayload
}

export interface ICartsCleanSuccessAction extends Action {
    payload: ICartsCleanSuccessPayload
}

export interface ICartStoreCartLightProcessPayload {
    cartIdParent: string
}

export interface ICartStoreCartLightProcessAction extends Action {
    payload: ICartStoreCartLightProcessPayload
}

export type ICartStoreCartLightResetPayload = Partial<ICartStoreCartLightProcessPayload>
export interface ICartStoreCartLightResetAction extends Action {
    payload: ICartStoreCartLightResetPayload
}

export interface ICartStoreCartLightSuccessPayload {
    cartIdParent: string
    response: IApiCartLightListResponse
}

export interface ICartStoreCartLightSuccessAction extends Action {
    payload: ICartStoreCartLightSuccessPayload
}

export interface ICartStoreCartLightFailurePayload {
    cartIdParent: string
    error: IAppErrorTypes
}

export interface ICartStoreCartLightFailureAction extends Action {
    payload: ICartStoreCartLightFailurePayload
}

export interface ICartMultiStoreCartsProcessPayload {
    params: IMultiStoreCartsParameters
}
export interface ICartMultistoreCartsProcessAction extends Action {
    payload: ICartMultiStoreCartsProcessPayload
}

export interface ICartMultiStoreCartsSuccessPayload {
    data: IApiMultiStoreCartCollection
}

export interface ICartMultiStoreCartsFailurePayload {
    error: IAppErrorTypes
}

export interface ICartSalesmanCartListProcessPayload {
    params: ISalesmanCartListParameters
}
export interface ICartSalesmanCartListProcessAction extends Action {
    payload: ICartSalesmanCartListProcessPayload
}

export interface ICartSalesmanCartListSuccessPayload {
    data: IApiMultiStoreCartCollection
}

export interface ICartSalesmanCartListFailurePayload {
    error: IAppErrorTypes
}

export interface ICartLoadProcessPayload {
    cartId: string
    main?: boolean
}

export interface ICartLoadProcessAction extends Action {
    payload: ICartLoadProcessPayload
}

export interface ICartLoadSuccessPayload {
    cartId: string
    response: ICart
    main?: boolean
}

export interface ICartLoadSuccessAction extends Action {
    payload: ICartLoadSuccessPayload
}

export interface ICartLoadFailurePayload {
    cartId: string
    error: IAppErrorTypes
}

export interface ICartLoadFailureAction extends Action {
    payload: ICartLoadFailurePayload
}

export type ICartBulkQuantityProcessPayload = {
    quantity: number
    mode: BulkCartQuantityMode
    action: BulkCartQuantityAction
    planogramId?: $PropertyType<IBasePlanogram, '@id'>
    planogramModuleId?: Undefinable<$PropertyType<IBasePlanogram, '@id'>>
}

export interface ICartBulkQuantityProcessAction extends Action {
    payload: ICartBulkQuantityProcessPayload
}

export interface ICartBulkQuantitySuccessPayload {
    items: ICartItemCollection
    unavailableStockProducts: ProductStockAlertCollection
}

export interface ICartBulkQuantitySuccessAction extends Action {
    payload: ICartBulkQuantitySuccessPayload
}

export interface ICartBulkQuantityFailurePayload {
    error: IAppErrorTypes
}

export interface ICartBulkQuantityFailureAction extends Action {
    payload: ICartBulkQuantityFailurePayload
}

export type StrictCartMode = ICartMode.Default | ICartMode.Grouped
export type ICartsPersistParameters = {
    cart_mode: StrictCartMode
    cart_validation_mode: ICartValidationMode
}

export interface ICartsPersistSettingsPayload {
    params: Partial<ICartsPersistParameters>
    merge?: boolean
}

export interface ICartsPersistSettingsAction extends Action {
    payload: ICartsPersistSettingsPayload
}

export type ICartsSwitchCartMode = {
    cartMode: StrictCartMode
}

export interface ICartsSwitchCartModeAction extends Action {
    payload: ICartsSwitchCartMode
}

/* --- ACTIONS --- */
type CartsActions = ActionType<typeof actions & typeof stockAlertActions>

/* --- EXPORTS --- */
type RootState = IApplicationRootState
type ContainerState = ICartsState
type ContainerActions = CartsActions

// @ts-ignore
export { RootState, ContainerState, ContainerActions }

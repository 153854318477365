/**
 *
 * Sidebar
 *
 */
import classNames from 'classnames'
import React, { ChangeEvent, memo, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

interface IProps {
    show: boolean
    disabled: boolean
    defaultValue?: number
    values: Array<number>
    onChange?: (value: number) => void
}

function ItemsPerPage({ defaultValue, values, disabled, show, onChange }: IProps): JSX.Element {
    const [selectedValue, setSelectedValue] = useState<number | undefined>(defaultValue)

    useEffect(() => {
        setSelectedValue(defaultValue)
    }, [defaultValue])

    const handleSortChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedIndex = e.currentTarget.selectedIndex
        if (values[selectedIndex]) {
            setSelectedValue(values[selectedIndex])
            if (onChange) {
                onChange(values[selectedIndex])
            }
        }
    }

    return (
        <div className={classNames('catalog-list-items-per-page', { 'd-none': !show })}>
            <Form.Group className={'catalog-list-action'}>
                <Form.Label>
                    <FormattedMessage id={'products.items_per_page.label'} />
                </Form.Label>
                <Form.Control
                    as="select"
                    size="sm"
                    disabled={disabled}
                    className={'form-control'}
                    onChange={handleSortChange}
                    value={selectedValue}
                >
                    {values.map((val: number) => (
                        <option key={`items_per_page_${val}`} value={val}>
                            {val}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
        </div>
    )
}

ItemsPerPage.defaultProps = {
    show: false,
    disabled: false,
    values: [12, 24, 36, 48, 72, 84, 96],
} as Partial<IProps>

const areEqual = (prevProps: IProps, nextProps: IProps) => {
    return (
        prevProps.values === nextProps.values &&
        prevProps.show === nextProps.show &&
        prevProps.disabled === nextProps.disabled
    )
}

export default memo(ItemsPerPage, areEqual)

// LIBRARIES
import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { ICustomer } from '../../services/api/service/customers/types'
import { IApplicationRootState } from '../../store'
import { IAppErrorTypes } from '../../store/app/types'
import { makeSelectCartMode } from '../../store/carts/selectors'
import { isOrderAvailable } from '../../store/carts/utils'
import { makeSelectCustomer, makeSelectCustomerStore } from '../../store/customers/selectors'

import { default as PlanogramPlaceholder } from './Placeholder'
import { StrictCartMode } from '../../store/carts/types'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { IMe } from '../../services/api/service/me/types'
import {
    makeSelectPlanogramDetail,
    makeSelectPlanogramDetailError,
    makeSelectPlanogramDetailFetching,
    makeSelectPlanogramGroupLinkedPlanograms,
} from '../../store/planogram/selectors'
import { IPlanogramDetail } from '../../services/api/service/planograms/types'
import { planogramDetailProcessAction, planogramResetAction } from '../../store/planogram/actions'
import { generatePlanogramUrl, PlanogramDocumentType } from '../../utils/planogramHelper'
import PlanogramImages from './Detail/PlanogramImages'
import DownloadDocument from '../Planograms/Partial/Actions/DownloadDocument'
import { ContainerState as PlanogramState } from '../../store/planogram/types'
import { $PropertyType } from 'utility-types'
import { Nullable } from 'tsdef'
import AddToCart from '../Planograms/Partial/Actions/AddToCart'
import Selector from './Detail/Selector'
import Heading from '../Planograms/Partial/Item/Heading'
import Modules from './Detail/Modules'

interface IProps {
    className?: string
    planogramId: string
}

export const PLANOGRAM_HEADER_ID = 'planogram-detail-header'
export const PLANOGRAM_CONTENT_ID = 'planogram-detail-content'
export const PLANOGRAM_FOOTER_ID = 'planogram-detail-footer'

const stateSelector = createStructuredSelector<any, any>({
    detailFetching: makeSelectPlanogramDetailFetching(),
    detailError: makeSelectPlanogramDetailError(),
    planogramDetail: makeSelectPlanogramDetail(),
    linkedPlanograms: makeSelectPlanogramGroupLinkedPlanograms(),
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
    me: makeSelectAuthMe(),
    cartMode: makeSelectCartMode(),
})

function Planogram({ className, planogramId }: IProps): JSX.Element {
    const { locale } = useIntl()
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const { linkedPlanograms, planogramDetail, detailError, customer, store, cartMode, me } = useSelector<
        IApplicationRootState,
        {
            linkedPlanograms: Nullable<
                NonNullable<$PropertyType<$PropertyType<PlanogramState, 'planogramGroups'>, 'data'>>['planograms']
            >
            planogramDetail?: IPlanogramDetail
            detailError?: IAppErrorTypes | undefined
            detailFetching: boolean
            customer: ICustomer
            store?: ICustomer
            cartMode: StrictCartMode
            me: IMe
        }
    >(stateSelector)
    const [initialized, setInitialized] = useState<boolean>(false)

    const handleOnSelectorChange = useCallback(
        (evt: React.ChangeEvent) => {
            history.push(generatePlanogramUrl((evt.currentTarget as HTMLInputElement).value, locale), {
                background: location,
            })
        },
        [history, locale, location]
    )

    useEffect(() => {
        if ((planogramDetail || detailError) && !initialized) {
            setInitialized(true)
        }
    }, [planogramDetail, detailError, initialized, setInitialized])

    useEffect(() => {
        // chargement du produit si necessary
        dispatch(planogramDetailProcessAction(planogramId))
    }, [planogramId, dispatch])

    useEffect(() => {
        return () => {
            dispatch(planogramResetAction())
        }
    }, [dispatch])

    return (
        <>
            <Helmet>
                <title>{planogramDetail?.full_name}</title>
            </Helmet>
            {detailError && (
                <Alert variant="danger" className="mb-0">
                    <Alert.Heading>
                        <FormattedMessage id="default.error_occurred" />
                    </Alert.Heading>
                    <p>{detailError.message}</p>
                </Alert>
            )}
            {!detailError && (
                <ReactPlaceholder ready={initialized} customPlaceholder={<PlanogramPlaceholder />}>
                    <div className={classNames('planogram', 'planogram-detail', className)} id={PLANOGRAM_HEADER_ID}>
                        <div className={'planogram-detail-content'} id={PLANOGRAM_CONTENT_ID}>
                            {planogramDetail && (
                                <Row>
                                    <Col xs={24} md={10} lg={12}>
                                        <div className="planogram-start">
                                            <PlanogramImages planogram={planogramDetail} />
                                            <div className="planogram-actions">
                                                <DownloadDocument
                                                    size="lg"
                                                    block
                                                    planogram={planogramDetail}
                                                    documentType={PlanogramDocumentType.Pdf}
                                                />
                                                <DownloadDocument
                                                    size="lg"
                                                    block
                                                    planogram={planogramDetail}
                                                    documentType={PlanogramDocumentType.Excel}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} md={14} lg={12} className="mt-4 mt-lg-0">
                                        <div className="planogram-end">
                                            <div className={'planogram-detail-inner-section'}>
                                                <Heading planogram={planogramDetail} />
                                            </div>
                                            <div className="planogram-detail-inner-section">
                                                <Selector
                                                    currentPlanogramId={planogramDetail['@id']}
                                                    planograms={linkedPlanograms}
                                                />
                                            </div>
                                            {customer && isOrderAvailable(customer, store, cartMode) && (
                                                <div className="planogram-detail-inner-section planogram-detail-add-to-cart">
                                                    <AddToCart planogram={planogramDetail} />
                                                </div>
                                            )}
                                            <div className="planogram-detail-inner-section">
                                                <Modules planogram={planogramDetail} className="mt-4" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </div>
                        <div className={'planogram-detail-footer'} id={PLANOGRAM_FOOTER_ID}>
                            <div className={'planogram-detail-inner-section'}></div>
                        </div>
                    </div>
                </ReactPlaceholder>
            )}
        </>
    )
}

Planogram.defaultProps = {
    modal: false,
} as Partial<IProps>

export default Planogram

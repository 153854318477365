// LIBRARIES
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'

import { IPlanogramDetail } from '../../../services/api/service/planograms/types'
import { generatePlanogramUrl } from '../../../utils/planogramHelper'
import { ContainerState as PlanogramState } from '../../../store/planogram/types'
import { $PropertyType } from 'utility-types'
import { Nullable } from 'tsdef'

interface IProps {
    className?: string
    disabled?: boolean
    currentPlanogramId: $PropertyType<IPlanogramDetail, '@id'>
    planograms: Nullable<
        NonNullable<$PropertyType<$PropertyType<PlanogramState, 'planogramGroups'>, 'data'>>['planograms']
    >
}

function Selector({ planograms, currentPlanogramId, className, disabled }: IProps): JSX.Element {
    const { locale } = useIntl()
    const history = useHistory()
    const location = useLocation()

    const handleOnSelectorChange = useCallback(
        (evt: React.ChangeEvent) => {
            history.push(generatePlanogramUrl((evt.currentTarget as HTMLInputElement).value, locale), {
                background: location,
            })
        },
        [history, locale, location]
    )

    return (
        <div className={classNames('planogram-selector', className)}>
            <p className="lbl">
                <FormattedMessage id="product.sizes" />
            </p>
            <div className="selectors">
                {planograms?.map((linkedPlanogram) => {
                    return (
                        <Form.Check
                            inline
                            key={`attr-selector-inline-${linkedPlanogram['@id']}`}
                            type={'radio'}
                            id={`attr-selector-inline-${linkedPlanogram['@id']}`}
                            className={classNames({
                                'is-selected': linkedPlanogram['@id'].includes(currentPlanogramId),
                            })}
                        >
                            <Form.Check.Input
                                disabled={disabled}
                                name={`planogram_selector`}
                                type={'radio'}
                                checked={linkedPlanogram['@id'].includes(currentPlanogramId)}
                                onChange={handleOnSelectorChange}
                                value={linkedPlanogram['@id']}
                            />
                            <Form.Check.Label>{linkedPlanogram.dimensions}</Form.Check.Label>
                        </Form.Check>
                    )
                })}
            </div>
        </div>
    )
}

Selector.defaultProps = {
    modal: false,
} as Partial<IProps>

export default Selector

import { call, cancelled, getContext, put, select, takeLatest } from 'redux-saga/effects'
import ActionTypes from './constants'
import { IApiClient } from '../../services/api/types'
import { planogramListFailureAction, planogramsListProcessAction, planogramsListSuccessAction } from './actions'
import {
    makeSelectPlanogramsListFilters,
    makeSelectPlanogramsListParams,
    makeSelectPlanogramsListParamsPrev,
} from './selectors'
import { planogramFiltersParametersEquals } from './utils'
import { formatAppError } from '../app/saga'
import cloneDeep from 'lodash/cloneDeep'

import axios from 'axios'
import { classificationCategoryResetAction } from '../classification/actions'

import { IPlanogramsListProcessAction } from './types'
import { IAPiPlanogramListResponse, IPlanogramListFiltersParameters } from '../../services/api/service/planograms/types'

// TOKEN
const CancelToken = axios.CancelToken

function* processPlanogramsRefresh() {
    // récupération des paramètres
    const params = yield select(makeSelectPlanogramsListParams())

    // dispatch
    yield put(planogramsListProcessAction(params))
}

function* processPlanogramsRequest(action: IPlanogramsListProcessAction) {
    const api: IApiClient = yield getContext('api')

    const source = CancelToken.source()
    const params = cloneDeep(action.payload.params)
    const filters = yield select(makeSelectPlanogramsListFilters())

    // récupération des filtres précédents
    const prevParams: IPlanogramListFiltersParameters | undefined = yield select(makeSelectPlanogramsListParamsPrev())
    if (!planogramFiltersParametersEquals(params, prevParams) || typeof filters === 'undefined') {
        params.with_filters = 1
    }

    // reset de la catégorie
    if (params.tree && !params.tree.category) {
        yield put(classificationCategoryResetAction())
    }

    try {
        // on va chercher les produits
        const parameters = cloneDeep(params)
        const response: IAPiPlanogramListResponse = yield call(
            { context: api.planograms, fn: 'list' },
            parameters, // dupliqué dans l'API pas besoin de spread ici
            source.token
        )

        let update = cloneDeep(params)
        if (response.data.state) {
            update = {
                ...update,
                filters: {
                    ...response.data.state?.filters,
                    order: response.data.state?.order,
                },
            }
        }

        yield put(planogramsListSuccessAction(response.data, update))
    } catch (e) {
        const err = yield call(formatAppError, e, 'planograms.unknow_error')
        yield put(planogramListFailureAction(err))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

export default [
    takeLatest(ActionTypes.LIST_REFRESH_ACTION, processPlanogramsRefresh),
    takeLatest(ActionTypes.LIST_PROCESS_ACTION, processPlanogramsRequest),
]

/**
 *
 * Picker Action Picture
 *
 */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IPickerActionComponentProps } from '../../../../types/productPicker'
import Button from '../../../../components/Buttons/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import isNumber from 'lodash/isNumber'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import {
    productsBulkRemoveFavoriteProcessAction,
    productsBulkRemoveFavoriteResetAction,
    productsListRefreshAction,
    productsSelectionApplyActionProcess,
    productsSelectionApplyActionSuccess,
    productsSelectionResetAction,
    productsSelectionResetProcessAction,
} from '../../../../store/products/actions'
import { createStructuredSelector } from 'reselect'
import {
    makeSelectProductsBulkFavoriteRemoveError,
    makeSelectProductsBulkFavoriteRemoveFetching,
    makeSelectProductsBulkFavoriteRemoveSuccess,
    makeSelectProductsListSelectionError,
    makeSelectProductsListSelectionProcessed,
    makeSelectProductsListSelectionProcessing,
    makeSelectProductsListSelectionSuccess,
} from '../../../../store/products/selectors'
import { IApplicationRootState } from '../../../../store'
import { IAppErrorTypes } from '../../../../store/app/types'
import SweetAlert from 'react-bootstrap-sweetalert'
import FlatIcon from '../../../../components/Icon/FlatIcon'
import { Overlay, Popover } from 'react-bootstrap'
import ManagerModal from '../../../Favorite/Classification/Manage/Loadable'
import MassAddToCartModal from '../../../MassAddToCart/Modal/Loadable'
import { BulkCartQuantityMode } from '../../../../store/carts/types'
import Portal from '../../../../components/Portal/Portal'

const stateSelector = createStructuredSelector<any, any>({
    processing: makeSelectProductsListSelectionProcessing(),
    processed: makeSelectProductsListSelectionProcessed(),
    error: makeSelectProductsListSelectionError(),
    successMessage: makeSelectProductsListSelectionSuccess(),
    bulkRemoveFetching: makeSelectProductsBulkFavoriteRemoveFetching(),
    bulkRemoveSuccess: makeSelectProductsBulkFavoriteRemoveSuccess(),
    bulkRemoveError: makeSelectProductsBulkFavoriteRemoveError(),
})

enum PickerActionFavoriteMode {
    MassAddToCart = 'massAddToCart',
    BulkRemove = 'bulkRemove',
    Associate = 'associate',
}

function PickerActionFavorites({ action, items }: IPickerActionComponentProps): JSX.Element {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const { min = 1, max } = action.options
    const target = useRef() as React.MutableRefObject<HTMLDivElement>
    const [mode, setMode] = useState<PickerActionFavoriteMode>()
    const [warnings, setWarnings] = useState<Array<string>>([])
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const [showSwal, setShowSwal] = useState<boolean>(false)
    const disabled = useMemo(() => {
        return isNumber(min) && min > items.length
    }, [min, items])

    const { processed, processing, error, successMessage, bulkRemoveSuccess } = useSelector<
        IApplicationRootState,
        {
            processing: boolean
            processed: boolean
            error?: IAppErrorTypes
            successMessage?: string
            bulkRemoveFetching?: boolean
            bulkRemoveSuccess?: boolean
            bulkRemoveError?: IAppErrorTypes
        }
    >(stateSelector)

    useEffect(() => {
        const warns: Array<string> = []
        if (isNumber(min) && items.length < min) {
            warns.push(formatMessage({ id: 'product_picker.min_warning' }, { min: min }))
        } else if (isNumber(max) && items.length > max) {
            warns.push(formatMessage({ id: 'product_picker.max_warning' }, { max: max }))
        }
        setWarnings(warns)
    }, [min, max, items, setWarnings, formatMessage])

    const handleMouseEnter = useCallback((): void => {
        setShowTooltip(warnings.length > 0)
    }, [setShowTooltip, warnings])
    const handleMouseLeave = useCallback((): void => {
        setShowTooltip(false)
    }, [setShowTooltip])

    const onTrashClick = useCallback(() => {
        if (disabled) {
            return
        }
        setMode(PickerActionFavoriteMode.BulkRemove)
        // dispatch pour dire qu'on est en processing
        dispatch(productsSelectionApplyActionProcess())
        // on dispatch !
    }, [disabled, dispatch, setMode])

    const onAddToCartClick = useCallback(() => {
        if (disabled) {
            return
        }
        setMode(PickerActionFavoriteMode.MassAddToCart)
        // dispatch pour dire qu'on est en processing
        dispatch(productsSelectionApplyActionProcess())
    }, [disabled, dispatch, setMode])

    const onManageDepartmentClick = useCallback(() => {
        if (disabled) {
            return
        }
        setMode(PickerActionFavoriteMode.Associate)
    }, [disabled, setMode])

    const handleSwalConfirm = useCallback(() => {
        if (disabled) {
            return
        }
        if (processed) {
            dispatch(productsListRefreshAction())
            dispatch(productsSelectionResetAction())
        }
        setShowSwal(false)
    }, [disabled, processed, setShowSwal, dispatch])

    const handleSwalBulkRemoveSuccess = useCallback(() => {
        dispatch(productsSelectionApplyActionSuccess())
        dispatch(productsBulkRemoveFavoriteResetAction())
    }, [dispatch])

    const handleManagerHide = useCallback(() => {
        setMode(undefined)
    }, [setMode])

    const handleManagerDone = useCallback(() => {
        setMode(undefined)
        dispatch(
            productsSelectionApplyActionSuccess(
                formatMessage({ id: 'favorite_department.associate.success' }, { count: items.length })
            )
        )
    }, [setMode, dispatch, formatMessage, items])

    const handleMassAddToCartExit = useCallback(() => {
        setMode(undefined)
        dispatch(productsSelectionResetProcessAction())
    }, [setMode, dispatch])

    const handleMassAddToCartDone = useCallback(() => {
        setMode(undefined)
        dispatch(productsSelectionApplyActionSuccess())
    }, [setMode, dispatch])

    const handleBulkRemoveCancel = useCallback(() => {
        setMode(undefined)
        dispatch(productsSelectionResetProcessAction())
    }, [setMode, dispatch])

    const handleBulkRemoveConfirm = useCallback(() => {
        setMode(undefined)
        dispatch(productsBulkRemoveFavoriteProcessAction(items))
    }, [setMode, items, dispatch])

    useEffect(() => {
        if (!processed) {
            setShowSwal(false)
            return
        }

        if (typeof successMessage !== 'undefined' && successMessage.length > 0) {
            setShowSwal(true)
        } else {
            handleSwalConfirm()
        }
    }, [processed, successMessage, error, setShowSwal, handleSwalConfirm])

    return (
        <>
            <div className="sub-actions" ref={target} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Button
                    variant="primary"
                    onClick={onAddToCartClick}
                    className={classNames({ inactive: disabled })}
                    disabled={disabled || processing || processed || typeof error !== 'undefined'}
                >
                    <FlatIcon icon={'shopping-cart'} />
                    <FormattedMessage id="products.add_to_cart" />
                </Button>
                <Button
                    variant="secondary"
                    onClick={onTrashClick}
                    className={classNames({ inactive: disabled })}
                    disabled={processing || processed || typeof error !== 'undefined'}
                >
                    <FontAwesomeIcon icon={'trash'} className={'app-icon'} />
                    <FormattedMessage id="product_picker.remove_from_favorites" />
                </Button>
                <Button
                    variant="secondary"
                    onClick={onManageDepartmentClick}
                    className={classNames({ inactive: disabled })}
                    disabled={processing || processed || typeof error !== 'undefined'}
                >
                    <FontAwesomeIcon icon={'link'} className={'app-icon'} />
                    <FormattedMessage id="product_picker.manage_favorite_departments" />
                </Button>
            </div>
            <Overlay target={target.current} show={showTooltip}>
                {(props) => (
                    <Popover id="warnings-tooltip" {...props}>
                        <Popover.Content>
                            {warnings.map((warning) => (
                                <p className="invalid-feedback" key={warning}>
                                    {warning}
                                </p>
                            ))}
                        </Popover.Content>
                    </Popover>
                )}
            </Overlay>
            {mode === PickerActionFavoriteMode.Associate && (
                <ManagerModal
                    onHide={handleManagerHide}
                    onExit={handleManagerHide}
                    onDone={handleManagerDone}
                    products={items}
                    show
                />
            )}
            {mode === PickerActionFavoriteMode.MassAddToCart && (
                <MassAddToCartModal
                    show
                    onExit={handleMassAddToCartExit}
                    mode={BulkCartQuantityMode.Picker}
                    onDone={handleMassAddToCartDone}
                />
            )}
            <Portal>
                <SweetAlert
                    warning
                    showCancel
                    cancelBtnText={formatMessage({ id: 'default.cancel' })}
                    confirmBtnText={formatMessage({ id: 'default.continue' })}
                    show={mode === PickerActionFavoriteMode.BulkRemove}
                    title={formatMessage({ id: `product_picker.remove_bulk_favorite.title` })}
                    onCancel={handleBulkRemoveCancel}
                    onConfirm={handleBulkRemoveConfirm}
                >
                    <FormattedMessage
                        id={'product_picker.remove_bulk_favorite.question'}
                        values={{ count: items.length }}
                    />
                </SweetAlert>
                <SweetAlert
                    show={showSwal}
                    success
                    title={formatMessage({
                        id:
                            typeof error === 'undefined' && processed
                                ? 'product_picker.your_selection'
                                : 'default.error',
                    })}
                    onConfirm={handleSwalConfirm}
                >
                    {successMessage}
                </SweetAlert>
                <SweetAlert
                    show={bulkRemoveSuccess}
                    success
                    title={formatMessage({ id: `product_picker.remove_bulk_favorite.title` })}
                    onConfirm={handleSwalBulkRemoveSuccess}
                >
                    <FormattedMessage id="product_picker.remove_bulk_favorite.success" />
                </SweetAlert>
            </Portal>
        </>
    )
}

export default memo(PickerActionFavorites)

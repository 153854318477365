import classNames from 'classnames'
import Qs from 'qs'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import ReactDOM from 'react-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import OutsideClickHandler from 'react-outside-click-handler'
import { useHistory, useLocation } from 'react-router-dom'
import rtrim from '../../../../../../utils/rtrim'
import Heading from '../../Partial/Heading'
import { IFactoryProps } from '../../type'
import { CatalogListQueryName, ICatalogListFilterRangeValue } from '../../../../../../services/api/service/core/types'

function TypeRange({ filter, value, onToggleOpen, filterPortalId }: IFactoryProps): JSX.Element {
    const { formatNumber, formatMessage } = useIntl()
    const [open, setOpen] = useState<boolean>(false)
    const [wantedValues, setWantedValues] = useState<ICatalogListFilterRangeValue | undefined>(undefined)
    const history = useHistory()
    const location = useLocation()

    const headingSubTitle = useMemo(() => {
        // @ts-ignore
        if (!value || Object.keys(value) === 0) {
            return undefined
        }

        const labels: Array<string> = []

        if (typeof (value as ICatalogListFilterRangeValue).min !== 'undefined') {
            labels.push(
                formatNumber(Number((value as ICatalogListFilterRangeValue).min!), {
                    currency: 'EUR',
                    currencyDisplay: 'symbol',
                    currencySign: 'standard',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: 'currency',
                })
            )
        }

        if (typeof (value as ICatalogListFilterRangeValue).max !== 'undefined') {
            if (labels.length > 0) {
                labels.push('-')
            }

            labels.push(
                formatNumber(Number((value as ICatalogListFilterRangeValue).max!), {
                    currency: 'EUR',
                    currencyDisplay: 'symbol',
                    currencySign: 'standard',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: 'currency',
                })
            )
        }

        return labels.join(' ')
    }, [formatNumber, value])

    const isEmpty = useMemo(() => {
        return typeof wantedValues === 'undefined'
    }, [wantedValues])

    const handleHeadingClick = useCallback(() => {
        setOpen((state) => !state)
    }, [setOpen])

    const handleOutsideClick = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    const handleCancelClick = useCallback(() => {
        setOpen(false)
        setWantedValues(value as ICatalogListFilterRangeValue)
    }, [setOpen, setWantedValues, value])

    const handleOnInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value
            const isMaxField = e.currentTarget.dataset.max
            const v = value.replace(',', '.')
            const editingKey = isMaxField ? 'max' : 'min'
            const wanted: ICatalogListFilterRangeValue = {
                ...(wantedValues || {}),
            }

            if (isNaN(Number(v))) {
                delete wanted[editingKey]
            } else {
                wanted[editingKey] = v
            }

            // @ts-ignore
            if (Object.keys(wanted) === 0) {
                setWantedValues(undefined)
                return
            }

            if (filter.autoValidate) {
                const parsed = Qs.parse(location.search.substring(1))
                if (Object.keys(wanted).length > 0) {
                    // @ts-ignore
                    parsed[filter.code] = wanted
                } else {
                    delete parsed[filter.code]
                }
                // reset page
                delete parsed[CatalogListQueryName.Page]

                const stringified = Qs.stringify(parsed)
                const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
                const baseUrl = rtrim(location.pathname, '/')
                const finalUrl = `${baseUrl}${qstring}`
                if (finalUrl !== `${location.pathname}${location.search}`) {
                    history.push(`${baseUrl}${qstring}`)
                }
            }

            setWantedValues(wanted)
        },
        [wantedValues, setWantedValues, history, location, filter]
    )

    const handleResetClick = useCallback(() => {
        setOpen(false)
        setWantedValues(undefined)

        // reset query
        const parsed = Qs.parse(location.search.substring(1))
        delete parsed[filter.code]
        // reset pagination
        delete parsed[CatalogListQueryName.Page]

        const stringified = Qs.stringify(parsed)
        const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
        const baseUrl = rtrim(location.pathname, '/')
        const finalUrl = `${baseUrl}${qstring}`
        if (finalUrl !== `${location.pathname}${location.search}`) {
            history.push(`${baseUrl}${qstring}`)
        }
    }, [setOpen, setWantedValues, location, history, filter])

    const handleValidateClick = useCallback(() => {
        setOpen(false)

        // add to query
        const parsed = Qs.parse(location.search.substring(1))
        if (wantedValues && Object.keys(wantedValues).length > 0) {
            // @ts-ignore
            parsed[filter.code] = wantedValues
        } else {
            delete parsed[filter.code]
        }

        // reset pagination
        delete parsed[CatalogListQueryName.Page]

        const stringified = Qs.stringify(parsed)
        const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
        const baseUrl = rtrim(location.pathname, '/')
        const finalUrl = `${baseUrl}${qstring}`
        if (finalUrl !== `${location.pathname}${location.search}`) {
            history.push(`${baseUrl}${qstring}`)
        }
    }, [location.search, location.pathname, wantedValues, filter.code, history])

    useEffect(() => {
        if (!value) {
            setWantedValues(undefined)
        } else {
            setWantedValues(value as ICatalogListFilterRangeValue)
        }
    }, [value, setWantedValues])

    useEffect(() => {
        if (onToggleOpen) {
            onToggleOpen(filter, open)
        }
    }, [filter, open, onToggleOpen])

    const Content = open ? (
        <div className={'content'}>
            <div className={'filter-price-value'}>
                <Row>
                    <Col>
                        <InputGroup>
                            <Form.Control
                                value={wantedValues?.min || ''}
                                type="text"
                                name="min"
                                data-min={1}
                                placeholder={formatMessage({ id: 'products.filter.price.min' })}
                                onChange={handleOnInputChange}
                                pattern="[0-9]+"
                                inputMode="decimal"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>€</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col className={'col-separator'} xs={'auto'}>
                        <span className={'separator'} />
                    </Col>
                    <Col>
                        <InputGroup>
                            <Form.Control
                                value={wantedValues?.max || ''}
                                type="text"
                                name="max"
                                data-max={1}
                                placeholder={formatMessage({ id: 'products.filter.price.max' })}
                                onChange={handleOnInputChange}
                                pattern="[0-9]+"
                                inputMode="decimal"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>€</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
            </div>
            {!filter.autoValidate && (
                <div className={'action'}>
                    <Button
                        block
                        className={'btn-cancel'}
                        variant={'link'}
                        onClick={handleResetClick}
                        disabled={typeof wantedValues === 'undefined'}
                    >
                        <FormattedMessage id={'products.reset_filters'} />
                    </Button>
                    <Button
                        block
                        className={'btn-save'}
                        variant={'link'}
                        onClick={handleValidateClick}
                        disabled={typeof wantedValues === 'undefined'}
                    >
                        <FormattedMessage id={'default.save'} />
                    </Button>
                </div>
            )}
        </div>
    ) : (
        <></>
    )

    const Component =
        filterPortalId && document.getElementById(filterPortalId) && open
            ? ReactDOM.createPortal(
                  <div
                      className={classNames(
                          'catalog-filter',
                          `catalog-filter-${filter.code}`,
                          `catalog-filter-type-${filter.mode}`,
                          { open, empty: isEmpty }
                      )}
                  >
                      <Heading filter={filter} subTitle={headingSubTitle} onClick={handleCancelClick} />
                      {Content}
                  </div>,
                  document.getElementById(filterPortalId!)!
              )
            : Content

    return (
        <div
            className={classNames(
                'catalog-filter',
                `catalog-filter-${filter.code}`,
                `catalog-filter-type-${filter.mode}`,
                { open, empty: isEmpty }
            )}
        >
            {!filterPortalId && (
                <OutsideClickHandler onOutsideClick={handleOutsideClick} disabled={!open}>
                    <Heading
                        filter={filter}
                        onClick={handleHeadingClick}
                        active={open}
                        count={undefined}
                        subTitle={headingSubTitle}
                    />
                    {Component}
                </OutsideClickHandler>
            )}
            {filterPortalId && (
                <>
                    <Heading
                        filter={filter}
                        onClick={handleHeadingClick}
                        active={open}
                        count={undefined}
                        subTitle={headingSubTitle}
                    />
                    {Component}
                </>
            )}
        </div>
    )
}

export default memo(TypeRange)

/**
 *
 * Modal
 *
 */
import React, { useCallback, useMemo } from 'react'
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ScrollTo } from 'scroll-to-position'
import FlatIcon from '../../components/Icon/FlatIcon'
import MainPicture from '../../containers/Planograms/Partial/Item/MainPicture'
import { IApplicationRootState } from '../../store'
import { PLANOGRAM_DETAIL_MODAL_ID } from './Modal'
import { makeSelectPlanogramListNextItem, makeSelectPlanogramListPrevItem } from '../../store/planograms/selectors'
import { IPlanogramList } from '../../services/api/service/planograms/types'
import { generatePlanogramUrl } from '../../utils/planogramHelper'
import Suggestion from '../Planograms/Navigation/Suggestion'

interface IProps {
    planogramId: string
}

function PlanogramDetailNavigation({ planogramId }: IProps): JSX.Element {
    const { locale } = useIntl()
    const history = useHistory()
    const location = useLocation()

    const selectPreviousPlanogramWithProductId = useMemo(makeSelectPlanogramListPrevItem, [])
    const selectNextPlanogramWithProductId = useMemo(makeSelectPlanogramListNextItem, [])
    const previous: IPlanogramList | null | undefined = useSelector<
        IApplicationRootState,
        IPlanogramList | null | undefined
    >((state) => selectPreviousPlanogramWithProductId(state, `/planograms/${planogramId}`))
    const next: IPlanogramList | null | undefined = useSelector<
        IApplicationRootState,
        IPlanogramList | null | undefined
    >((state) => selectNextPlanogramWithProductId(state, `/planograms/${planogramId}`))

    const redirectProductUrl = useCallback(
        (planogram: IPlanogramList) => {
            const finalUrl = generatePlanogramUrl(planogram['@id'], locale)
            // @ts-ignore
            if (location.state && location.state.background) {
                // @ts-ignore
                history.push(finalUrl, { background: location.state.background })
            } else {
                history.push(finalUrl)
            }
            const productAttributeElement = document.getElementById(PLANOGRAM_DETAIL_MODAL_ID)
            if (productAttributeElement) {
                let opts = {}
                if (document.body.classList.contains('modal-open')) {
                    const container = document.querySelector('.modal')
                    opts = {
                        scrollContainer: container,
                    }
                }
                ScrollTo(productAttributeElement, opts)
            }
        },
        [history, location, locale]
    )

    const handleOnPreviousClick = () => {
        if (previous) {
            redirectProductUrl(previous)
        }
    }
    const handleOnNextClick = () => {
        if (next) {
            redirectProductUrl(next)
        }
    }

    if (!previous && !next) {
        return <></>
    }

    let PreviousComponent = <></>
    let NextComponent = <></>
    if (previous) {
        PreviousComponent = (
            <Popover id={`popover-button-cart-${previous['@id']}`} className={'planogram-detail-navigation-popover'}>
                <Popover.Content>
                    <Suggestion planogram={previous} column={8} noGutters={false} />
                </Popover.Content>
            </Popover>
        )
    }

    if (next) {
        NextComponent = (
            <Popover id={`popover-button-cart-${next['@id']}`} className={'planogram-detail-navigation-popover'}>
                <Popover.Content>
                    <Suggestion planogram={next} column={8} noGutters={false} />
                </Popover.Content>
            </Popover>
        )
    }

    return (
        <div className={'planogram-detail-navigation'}>
            <Row noGutters>
                <Col>
                    {previous && (
                        <button
                            type={'button'}
                            className={'btn-planogram-detail-navigation btn-prev'}
                            onClick={handleOnPreviousClick}
                        >
                            <div className={'planogram-card'}>
                                {previous && (
                                    <OverlayTrigger
                                        placement={'auto'}
                                        trigger={['hover', 'focus', 'click']}
                                        overlay={PreviousComponent}
                                    >
                                        <span className={'d-block'}>
                                            <MainPicture planogram={previous} />
                                        </span>
                                    </OverlayTrigger>
                                )}
                            </div>

                            <FlatIcon icon={'arrow-left'} />
                            <span className={'lbl'}>
                                <FormattedMessage id={'planogram.navigation.previous'} />
                            </span>
                            <span className={'lbl abbr'}>
                                <FormattedMessage id={'default.previous'} />
                            </span>
                        </button>
                    )}
                </Col>
                <Col>
                    {next && (
                        <button
                            type={'button'}
                            className={'btn-planogram-detail-navigation btn-next'}
                            onClick={handleOnNextClick}
                        >
                            <span className={'lbl'}>
                                <FormattedMessage id={'planogram.navigation.next'} />
                            </span>
                            <span className={'lbl abbr'}>
                                <FormattedMessage id={'default.next'} />
                            </span>
                            <FlatIcon icon={'arrow-right'} />
                            <div className={'planogram-card'}>
                                {next && (
                                    <OverlayTrigger
                                        placement={'auto'}
                                        trigger={['hover', 'focus', 'click']}
                                        overlay={NextComponent}
                                    >
                                        <span className={'d-block'}>
                                            <MainPicture planogram={next} />
                                        </span>
                                    </OverlayTrigger>
                                )}
                            </div>
                        </button>
                    )}
                </Col>
            </Row>
        </div>
    )
}

PlanogramDetailNavigation.defaultProps = {} as Partial<IProps>

export default PlanogramDetailNavigation

/*
 *
 * Planogram constants
 *
 */

enum ActionTypes {
    RESET_ACTION = 'app/Planogram/RESET',
    DETAIL_PROCESS_ACTION = 'app/Planogram/Detail/REQUEST_PROCESS',
    DETAIL_SUCCESS_ACTION = 'app/Planogram/Detail/REQUEST_SUCCESS',
    DETAIL_FAILURE_ACTION = 'app/Planogram/Detail/REQUEST_FAILURE',
    DETAIL_RESET_ACTION = 'app/Planogram/Detail/RESET',
    GROUP_PROCESS_ACTION = 'app/Planogram/GroupAttributes/REQUEST_PROCESS',
    GROUP_SUCCESS_ACTION = 'app/Planogram/GroupAttributes/REQUEST_SUCCESS',
    GROUP_FAILURE_ACTION = 'app/Planogram/GroupAttributes/REQUEST_FAILURE',
    GROUP_RESET_ACTION = 'app/Planogram/GroupAttributes/RESET',
}

export default ActionTypes

import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import classNames from 'classnames'
import React, { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { FlashMessage, removeFlashMessage } from 'redux-flash-messages/lib'
import { createStructuredSelector } from 'reselect'
import AppError from '../../components/AppError/AppError'
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop'
import CartsBanner from '../../containers/CartsBanner/CartsBanner'
import Footer from '../../containers/Footer/Footer'
import Header from '../../containers/Header/Header'
import MobileMenu from '../../containers/MobileMenu/MobileMenu'
import { ICustomer } from '../../services/api/service/customers/types'
import { IApplicationRootState } from '../../store'
import { makeSelectApocalypseFlashMessages } from '../../store/app/selectors'
import { makeSelectCustomer } from '../../store/customers/selectors'
import { sideMenuCloseAction, sideMenuOpenAction } from '../../store/sidemenu/actions'
import makeSelectSideMenuOpen from '../../store/sidemenu/selectors'
import { isInternetExplorer } from '../../utils/browser'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { IMe } from '../../services/api/service/me/types'
import { isSalesmanResource } from '../../store/salesmens/utils'
import SalesmanCartAlert from '../../containers/SalesmanCartAlert/SalesmanCartAlert'

interface IProps {
    children?: ReactNode
}

const stateSelector = createStructuredSelector<any, any>({
    sideMenuOpen: makeSelectSideMenuOpen(),
    flashMessages: makeSelectApocalypseFlashMessages(),
    customer: makeSelectCustomer(),
    me: makeSelectAuthMe(),
})

function Layout({ children }: IProps): JSX.Element {
    const { sideMenuOpen, flashMessages, customer, me } = useSelector<
        IApplicationRootState,
        {
            sideMenuOpen: boolean
            flashMessages: Array<FlashMessage>
            customer?: ICustomer
            me?: IMe
        }
    >(stateSelector)
    const dispatch = useDispatch()

    const [stickyEnabled, setStickyEnabled] = useState(true)
    const stickyRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const headerRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const hasFlashMessages = flashMessages.length > 0
    const [headerHeight, setHeaderHeight] = useState<number>(0)
    const [cartsBannerHeight, setCartsBannerHeight] = useState<number>(0)
    const isSalesman = useMemo(() => {
        return isSalesmanResource(me)
    }, [me])

    useEffect(() => {
        setHeaderHeight(headerRef.current?.scrollHeight || 0)
    }, [headerHeight])

    useEffect(() => {
        return () => {
            flashMessages.forEach((flashMessage) => {
                dispatch(removeFlashMessage(flashMessage.id))
            })
        }
    })

    const handleStateChange = useCallback(
        (open: boolean) => {
            if (open !== sideMenuOpen) {
                if (open) {
                    dispatch(sideMenuOpenAction())
                } else {
                    dispatch(sideMenuCloseAction())
                }
            }
        },
        [sideMenuOpen, dispatch]
    )

    useScrollPosition(
        ({ currPos }) => {
            const isShow = currPos.y > -headerHeight
            if (isShow !== stickyEnabled) setStickyEnabled(isShow)
        },
        [stickyEnabled, headerHeight],
        stickyRef,
        false,
        50
    )

    const handleCartsBannerHeightChange = useCallback(
        (height: number) => {
            setCartsBannerHeight(height)
        },
        [setCartsBannerHeight]
    )

    useEffect(() => {
        document.documentElement.classList.add(`member-${me?.['@type']?.toLowerCase() || 'anonymous'}`)
        return () => {
            document.documentElement.className = document.documentElement.className.replace(
                /^member-[A-Za-z0-9_-]+/g,
                ''
            )
        }
    }, [me])

    return (
        <>
            <ToastContainer hideProgressBar={true} />
            <Header sticky={!stickyEnabled} ref={headerRef} />
            <MobileMenu isOpen={sideMenuOpen} onStateChange={handleStateChange} />
            <div
                ref={stickyRef}
                id={'page-wrap'}
                className={classNames(
                    {
                        sticked: !stickyEnabled,
                        'has-app-error': hasFlashMessages,
                        'is-ie': isInternetExplorer(),
                        salesman: isSalesmanResource(me),
                    },
                    `customer-${customer?.account_type || 'anonymous'}`
                )}
            >
                {hasFlashMessages && <AppError messages={flashMessages} />}
                {!hasFlashMessages && <div className={'page-wrap-inner'}>{children}</div>}
                <Footer />
            </div>
            <CartsBanner onHeightChange={handleCartsBannerHeightChange} />
            <ScrollToTop bottom={cartsBannerHeight} stickyRef={stickyRef} />
            {isSalesman && <SalesmanCartAlert />}
        </>
    )
}

export default memo(Layout)

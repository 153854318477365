// LIBRARIES
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import { IPlanogramDetail } from '../../../services/api/service/planograms/types'
import Module from './Module'
import isEmpty from 'lodash/isEmpty'
import initials from 'initialism'
import { Nullable } from 'tsdef'

interface IProps {
    className?: string
    disabled?: boolean
    planogram: IPlanogramDetail
}

function Modules({ planogram, className, disabled }: IProps): JSX.Element {
    const [activeKey, setActiveKey] = useState<Nullable<string>>('')

    useEffect(() => {
        setActiveKey(planogram.modules.length > 0 ? planogram.modules[0]['@id'] : '')
    }, [planogram])

    return (
        <div className={classNames('planogram-module-selector', className)}>
            <p className="lbl">
                <FormattedMessage id="planogram_module.plural" values={{ total: planogram.modules.length }} />
            </p>
            <Tabs unmountOnExit activeKey={activeKey!} onSelect={setActiveKey} className="modules">
                {planogram.modules
                    ?.filter((module) => module.products.length > 0)
                    .map((module) => {
                        return (
                            <Tab
                                eventKey={module['@id']}
                                title={
                                    <div className="planogram-module-tab-name">
                                        <OverlayTrigger
                                            overlay={<Tooltip id={`tooltip-${module['@id']}`}>{module.name}</Tooltip>}
                                        >
                                            <span
                                                className={classNames('module-name', {
                                                    'with-color': !isEmpty(module.color),
                                                })}
                                            >
                                                <span className="name">{initials(module.name, 1)}</span>
                                                {module.color && (
                                                    <span
                                                        className="color"
                                                        style={{
                                                            backgroundColor: module.color,
                                                        }}
                                                    />
                                                )}
                                            </span>
                                        </OverlayTrigger>
                                        <span className="caret" />
                                    </div>
                                }
                                key={module['@id']}
                            >
                                <Module planogram={planogram} module={module} disabled={disabled} />
                            </Tab>
                        )
                    })}
            </Tabs>
        </div>
    )
}

Modules.defaultProps = {
    modal: false,
} as Partial<IProps>

export default Modules

import { all } from 'redux-saga/effects'

import { default as authSaga } from './auth/saga'
import { default as configSaga } from './config/saga'
import { default as appSagas } from './app/saga'
import { default as sideMenuSagas } from './sidemenu/saga'
import { default as productSagas } from './product/saga'
import { default as productsSagas } from './products/saga'
import { default as cartsSaga } from './carts/saga'
import { default as customersSaga } from './customers/saga'
import { default as paymentModesSaga } from './payment-modes/saga'
import { default as ordersSaga } from './orders/saga'
import { default as orderSaga } from './order/saga'
import { default as cmsSaga } from './cms/saga'
import { default as classificationSaga } from './classification/saga'
import { default as invoicesSaga } from './invoices/saga'
import { default as searchSaga } from './search/saga'
import { default as exportsSaga } from './exports/saga'
import { default as importsSaga } from './imports/saga'
import { default as salesmanSaga } from './salesmens/saga'
import { default as statisticsSaga } from './statistics/saga'
import { default as stockAlertSaga } from './stockAlert/saga'
import { default as pictureExportSaga } from './pictureExport/saga'
import { default as localizationSaga } from './localization/saga'
import { default as planogramsSaga } from './planograms/saga'
import { default as planogramSaga } from './planogram/saga'

export default function* rootSaga() {
    yield all([
        ...appSagas,
        ...configSaga,
        ...sideMenuSagas,
        ...authSaga,
        ...productsSagas,
        ...productSagas,
        ...cartsSaga,
        ...customersSaga,
        ...ordersSaga,
        ...orderSaga,
        ...cmsSaga,
        ...classificationSaga,
        ...invoicesSaga,
        ...searchSaga,
        ...exportsSaga,
        ...importsSaga,
        ...stockAlertSaga,
        ...salesmanSaga,
        ...statisticsSaga,
        ...pictureExportSaga,
        ...localizationSaga,
        ...paymentModesSaga,
        ...planogramsSaga,
        ...planogramSaga,
    ])
}

/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { Table } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import {
    IProductAttributeCollection,
    IProductCustomAttributeCollection,
} from '../../../services/api/service/products/types'
import FlatIcon from '../../Icon/FlatIcon'
import Attribute from './Attribute'
import { CatalogUnitType } from '../../../services/api/service/core/types'

interface IProps {
    attributes: IProductAttributeCollection
    customAttributes: IProductCustomAttributeCollection
    unitOfSale?: CatalogUnitType
    className?: string
}

type IProductAttributeKey = keyof IProductAttributeCollection
const attributeKeys: Array<IProductAttributeKey> = ['main_color', 'color', 'size', 'weight']
const excludedKeys: Array<IProductAttributeKey> = ['box_weight']

function Attributes({ attributes, customAttributes, className, unitOfSale }: IProps): JSX.Element {
    const attributeExcludeKeys = useMemo(() => {
        const attr = [...excludedKeys]
        if (unitOfSale === CatalogUnitType.Meter) {
            attr.push('weight')
        }
        return attr
    }, [unitOfSale])
    return (
        <div className={classNames('product-attributes-section', className)}>
            <div className={'heading'}>
                <h3 className={'h2 hv'}>
                    <FlatIcon icon={'discount'} />
                    <FormattedMessage id={'product.product_attributes'} />
                </h3>
            </div>
            <div className={'content'}>
                <Table striped hover size={'md'}>
                    <tbody>
                        {attributeKeys
                            .filter((value) => attributeExcludeKeys.indexOf(value as IProductAttributeKey) === -1)
                            .map((attributeKey: IProductAttributeKey) => (
                                <Attribute key={attributeKey} attributes={attributes} attributekey={attributeKey} />
                            ))}
                        {Object.keys(attributes)
                            .filter(
                                (value) =>
                                    attributeKeys.indexOf(value as IProductAttributeKey) === -1 &&
                                    attributeExcludeKeys.indexOf(value as IProductAttributeKey) === -1
                            )
                            .map((key) => (
                                <Attribute
                                    key={key}
                                    attributes={attributes}
                                    attributekey={key as IProductAttributeKey}
                                />
                            ))}
                        {customAttributes &&
                            customAttributes.map((value, index) => {
                                return (
                                    <tr key={`product_custom_attributes_${index}`}>
                                        <th>{value.label}</th>
                                        <td>{value.value}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

Attributes.defaultProps = {
    unitOfSale: CatalogUnitType.Unit,
} as Partial<IProps>

export default memo(Attributes)

import React from 'react'
import { CatalogListFilterMode } from '../../../../services/api/service/core/types'
import { IFactoryProps } from './type'
import TypeOptions from './Type/Options'
import TypeOrder from './Type/Order'
import TypeRange from './Type/Range'

function FilterFactory(props: IFactoryProps): JSX.Element {
    switch (props.filter.mode) {
        case CatalogListFilterMode.Option:
            return <TypeOptions {...props} />
        case CatalogListFilterMode.Order:
            return <TypeOrder {...props} />
        case CatalogListFilterMode.Range:
            return <TypeRange {...props} />
        default:
            return <></>
    }
}

export default FilterFactory

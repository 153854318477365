/*
 *
 * Carts reducer
 *
 */

import ActionTypes from './constants'
import StockAlertActionTypes from '../stockAlert/constants'
import { BulkCartQuantityMode, ContainerActions, ContainerState, IBulkQuantityState } from './types'
import {
    findCartBy,
    findCartItemByProductId,
    formatCanIncrementQuantityValues,
    formatCartLockValues,
    formatErrorValues,
    formatQuantityValues,
    formatShippingLocationLockValues,
} from './utils'
import { ICart, ICartCollection, ICartItem } from '../../services/api/service/carts/types'
import { CollectionMap } from '../../types/common'
import isBoolean from 'lodash/isBoolean'

export const cartsVisibilityState = {
    banner: false,
}

export const cartsItemState = {
    canIncrementQuantity: {},
    quantities: {},
    errors: {},
}

export const initialSalesmanListState = {
    fetching: false,
}

export const cartsItemsState = {
    main: cartsItemState,
    sub: cartsItemState,
    store_quantities: {},
    fetching: {},
}

export const cartsLightCartState = {}
export const cartsLoadState = {}
export const cartsLockedState = {
    shipping_location: {},
    cart: {},
}
export const cartsLockState = {
    fetching: {},
    errors: {},
    success: {},
}
export const cartsMultiStoreState = {
    store_cart_list: {
        fetching: false,
    },
}

export const cartsCleanState = {
    fetching: {},
    errors: {},
}
export const cartsInitialBulkState: IBulkQuantityState = {
    mode: BulkCartQuantityMode.List,
    fetching: false,
    success: false,
}

export const cartsDuplicateState = {
    fetching: {},
    data: {},
    errors: {},
    success: {},
    warnings: {},
}

export const cartsCartsState = {
    create: {
        fetching: {},
        data: {},
        errors: {},
        items: {},
    },
    reorder: {
        fetching: {},
        data: {},
        errors: {},
        success: {},
        warnings: {},
    },
}

export const initialState: ContainerState = {
    main: [],
    list: [],
    salesman: initialSalesmanListState,
    multi_store: cartsMultiStoreState,
    light: cartsLightCartState,
    load: cartsLoadState,
    lock: cartsLockState,
    locked: cartsLockedState,
    orders: cartsCartsState,
    items: cartsItemsState,
    visibility: cartsVisibilityState,
    clean: cartsCleanState,
    bulk: cartsInitialBulkState,
    duplicate: cartsDuplicateState,
}

function cartsReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.RESET_ACTION:
            return initialState
        case ActionTypes.PERSIST_PARAMS_ACTION:
            return {
                ...state,
                settings: action.payload.params,
            }
        case ActionTypes.SWITCH_CART_MODE:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    cart_mode: action.payload.cartMode,
                },
            }
        case ActionTypes.TOGGLE_BANNER_ACTION: {
            return {
                ...state,
                visibility: {
                    ...state.visibility,
                    banner: !state.visibility.banner,
                },
            }
        }
        case ActionTypes.SHOW_BANNER_ACTION: {
            return {
                ...state,
                visibility: {
                    ...state.visibility,
                    banner: true,
                },
            }
        }
        case ActionTypes.HIDE_BANNER_ACTION: {
            return {
                ...state,
                visibility: {
                    ...state.visibility,
                    banner: false,
                },
            }
        }
        case ActionTypes.REMOVE_TO_CART_PROCESS_ACTION: {
            const { quantity, productId, storeId, cartId } = action.payload
            const storeQuantities = { ...state.items.store_quantities }
            const mainQuantities = { ...state.items.main.quantities }
            const subQuantities = { ...state.items.sub.quantities }

            // récupération du cartItem
            if (!storeId) {
                let isMain = false
                let key = 'unknown'
                let carts: ICartCollection = []
                if (!cartId) {
                    carts = state.list.filter((item) => state.main.indexOf(item['@id']) > -1)
                } else {
                    key = cartId
                    isMain = state.main.indexOf(cartId) > -1
                    const crt = state.list.find((itm) => itm['@id'] === cartId)
                    carts = crt ? [crt] : []
                }

                // a-t-on un cart item ?
                const cartItem = findCartItemByProductId(carts, productId)
                if (cartItem) {
                    isMain = state.main.indexOf(cartItem.cart) > -1
                    key = cartItem.cart
                }

                if (isMain) {
                    mainQuantities[key] = {
                        ...mainQuantities[key],
                        [productId]: quantity,
                    }
                } else {
                    subQuantities[key] = {
                        ...subQuantities[key],
                        [productId]: quantity,
                    }
                }
            }

            if (storeId && storeQuantities[productId]) {
                storeQuantities[productId].updating = {
                    ...storeQuantities[action.payload.productId].updating,
                    [storeId]: true,
                }
                storeQuantities[productId].quantities = {
                    ...storeQuantities[productId].quantities,
                    [storeId]: quantity,
                }
            }

            return {
                ...state,
                items: {
                    ...state.items,
                    store_quantities: storeQuantities,
                    main: {
                        ...state.items.main,
                        quantities: mainQuantities,
                    },
                    sub: {
                        ...state.items.sub,
                        quantities: subQuantities,
                    },
                    fetching: {
                        [productId]: true,
                    },
                },
            }
        }
        case ActionTypes.REMOVE_TO_CART_SUCCESS_ACTION: {
            const { cartItem, productId, storeId, cartId } = action.payload
            let idx: number | undefined = undefined
            let cart: ICart | undefined = undefined

            // si pas de cart item, on ne fait que le supprimer
            if (!cartItem) {
                // récupération des paniers elligibles
                let carts: ICartCollection = []
                let currentCartId: string | undefined = undefined
                if (cartId) {
                    currentCartId = cartId
                } else {
                    // récupération des main seulement
                    carts = state.list.filter((item) => state.main.indexOf(item['@id']) > -1)
                    // récupération du bon identifiant panier
                    for (const index in carts) {
                        const currentCart = carts[index]
                        if (currentCart.items && Object.keys(currentCart.items).indexOf(productId) > -1) {
                            currentCartId = currentCart['@id']
                            break
                        }
                    }
                }

                // récupération de l'index et du panier
                idx = state.list.findIndex((obj) => obj['@id'] === currentCartId)
                cart = { ...state.list[idx] }

                // récupération item panier
                const cartItem: ICartItem | undefined = cart.items ? cart.items[productId] || undefined : undefined

                // màj du panier
                cart.total_quantity = cart.total_quantity - (cartItem ? cartItem.quantity : 0)
                cart.total = cart.total - (cartItem ? cartItem.total || 0 : 0)
                cart.package_count = cart.package_count - (cartItem ? cartItem.package_count : 0)
                cart.total_volume =
                    cart.total_volume - (cartItem ? cartItem.quantity * cartItem.product.unit_volume : 0)

                if (typeof cart.total_eco_tax !== 'undefined') {
                    cart.total_eco_tax =
                        (cart.total_eco_tax || 0) - (cartItem?.total_eco_tax ? cartItem.total_eco_tax : 0)
                }

                // suppression du cart item
                if (cart.items) {
                    delete cart.items[productId]
                }
            } else {
                // récupération du bon panier
                idx = state.list.findIndex((obj) => obj['@id'] === cartItem.cart)
                cart = { ...state.list[idx] }

                // récupération item panier ancien
                const cartItemOld: ICartItem | undefined = cart.items
                    ? cart.items[action.payload.productId] || undefined
                    : undefined

                // override
                if (cartItem.quantity === 0) {
                    if (cart.items) {
                        delete cart.items[productId]
                    }
                } else if (cart.items) {
                    cart.items[productId] = cartItem
                }

                // màj du panier
                cart.total_quantity =
                    cart.total_quantity - (cartItemOld ? cartItemOld.quantity : 0) + (cartItem ? cartItem.quantity : 0)
                cart.total =
                    (cart.total || 0) -
                    (cartItemOld ? cartItemOld.total || 0 : 0) +
                    (cartItem ? cartItem.total || 0 : 0)
                cart.package_count =
                    cart.package_count -
                    (cartItemOld ? cartItemOld.package_count : 0) +
                    (cartItem ? cartItem.package_count : 0)
                cart.total_volume =
                    cart.total_volume -
                    (cartItemOld ? cartItemOld.quantity * cartItemOld.product.unit_volume : 0) +
                    (cartItem ? cartItem.product.unit_volume * cartItem.quantity : 0)

                if (typeof cart.total_eco_tax !== 'undefined') {
                    cart.total_eco_tax =
                        (cart.total_eco_tax || 0) -
                        (cartItemOld ? cartItemOld.total_eco_tax || 0 : 0) +
                        (cartItem ? cartItem.total_eco_tax || 0 : 0)
                }

                if (storeId && cart.store_carts) {
                    // récupération du panier
                    let subCartId: number | undefined = undefined
                    for (const idx1 in cart.store_carts) {
                        const storeCart = cart.store_carts[idx1]
                        if (storeCart.customer.indexOf(storeId) > -1) {
                            subCartId = parseInt(idx1)
                            break
                        }
                    }
                    if (typeof subCartId === 'number' && !isNaN(subCartId)) {
                        const subCart = cart.store_carts[subCartId]

                        // màj du panier
                        subCart.total_quantity =
                            subCart.total_quantity -
                            (cartItemOld ? cartItemOld.quantity : 0) +
                            (cartItem ? cartItem.quantity : 0)
                        subCart.total =
                            (subCart.total || 0) -
                            (cartItemOld ? cartItemOld.total || 0 : 0) +
                            (cartItem ? cartItem.total || 0 : 0)
                        subCart.package_count =
                            subCart.package_count -
                            (cartItemOld ? cartItemOld.package_count : 0) +
                            (cartItem ? cartItem.package_count : 0)
                        subCart.total_volume =
                            subCart.total_volume -
                            (cartItemOld ? cartItemOld.product.unit_volume * cartItemOld.quantity : 0) +
                            (cartItem ? cartItem.product.unit_volume * cartItem.quantity : 0)
                        subCart.items_count = subCart.items_count - (cartItemOld ? 1 : 0) + (cartItem ? 1 : 0)
                        subCart.items_count = subCart.items_count < 0 ? 0 : subCart.items_count

                        if (typeof subCart.total_eco_tax !== 'undefined') {
                            subCart.total_eco_tax =
                                (subCart.total_eco_tax || 0) -
                                (cartItemOld ? cartItemOld.total_eco_tax || 0 : 0) +
                                (cartItem ? cartItem.total_eco_tax || 0 : 0)
                        }

                        cart.store_carts[subCartId] = subCart
                    }
                }
            }

            // mise à jour du panier en conséquence
            cart.items_count = cart.items ? Object.values(cart.items).length : 0

            const list = state.list
                .slice(0, idx)
                .concat([cart])
                .concat(state.list.slice(idx + 1))

            let items = {
                ...state.items,
                main: {
                    ...state.items.main,
                    canIncrementQuantity: formatCanIncrementQuantityValues(list, state.main),
                    quantities: formatQuantityValues(list, state.main),
                    errors: formatErrorValues(list, state.main),
                },
                sub: {
                    ...state.items.sub,
                    canIncrementQuantity: formatCanIncrementQuantityValues(list, state.main, true),
                    quantities: formatQuantityValues(list, state.main, true),
                    errors: formatErrorValues(list, state.main, true),
                },
                fetching: {
                    ...state.items.fetching,
                    [productId]: false,
                },
            }

            if (storeId && items.store_quantities[productId]) {
                items = {
                    ...items,
                    store_quantities: {
                        ...items.store_quantities,
                        [productId]: {
                            ...items.store_quantities[productId],
                            updating: {
                                ...items.store_quantities[productId].updating,
                                [storeId]: false,
                            },
                            quantities: {
                                ...items.store_quantities[action.payload.productId].quantities,
                                [storeId]: 0,
                            },
                        },
                    },
                }
            }

            return Object.assign({}, state, {
                list: list,
                items: items,
                orders: cartsCartsState,
            })
        }
        case ActionTypes.ADD_TO_CART_PROCESS_ACTION: {
            const { productId, storeId, quantity, cartId } = action.payload

            const storeQuantities = { ...state.items.store_quantities }
            const mainQuantities = { ...state.items.main.quantities }
            const subQuantities = { ...state.items.sub.quantities }

            // récupération du cartItem
            if (!storeId) {
                let isMain = false
                let key = 'unknown'
                let carts: ICartCollection = []
                if (!cartId) {
                    // récupération des main
                    carts = state.list.filter((item) => state.main.indexOf(item['@id']) > -1)
                } else {
                    key = cartId
                    isMain = state.main.indexOf(cartId) > -1
                    const crt = state.list.find((itm) => itm['@id'] === cartId)
                    carts = crt ? [crt] : []
                }

                // a-t-on un cart item ?
                const cartItem = findCartItemByProductId(carts, productId)
                if (cartItem) {
                    isMain = state.main.indexOf(cartItem.cart) > -1
                    key = cartItem.cart
                }

                if (isMain) {
                    mainQuantities[key] = {
                        ...mainQuantities[key],
                        [productId]: quantity,
                    }
                } else {
                    subQuantities[key] = {
                        ...subQuantities[key],
                        [productId]: quantity,
                    }
                }
            }

            if (storeId && storeQuantities[productId]) {
                if (storeQuantities[productId]) {
                    storeQuantities[productId].updating = {
                        ...storeQuantities[productId].updating,
                        [storeId]: true,
                    }
                    storeQuantities[productId].quantities = {
                        ...storeQuantities[productId].quantities,
                        [storeId]: quantity,
                    }
                }
            }

            return {
                ...state,
                items: {
                    ...state.items,
                    store_quantities: storeQuantities,
                    main: {
                        ...state.items.main,
                        quantities: mainQuantities,
                    },
                    sub: {
                        ...state.items.sub,
                        quantities: subQuantities,
                    },
                    fetching: {
                        ...state.items.fetching,
                        [productId]: true,
                    },
                },
            }
        }
        case ActionTypes.ADD_TO_CART_SUCCESS_ACTION: {
            const { cartItem, storeId } = action.payload

            const idx = state.list.findIndex((obj) => obj['@id'] === cartItem.cart)
            let cart: ICart = {
                ...state.list[idx],
            }
            // récupération des anciens et nouveaux cart item
            const cartItemOld: ICartItem | undefined = cart.items
                ? cart.items[cartItem.product.id] || undefined
                : undefined

            // mise à jour du panier
            cart = {
                ...cart,
                items: {
                    ...(state.list[idx]['items'] ? state.list[idx]['items'] : {}),
                    [cartItem.product.id]: cartItem,
                },
            }

            cart.items_count = cart.items ? Object.values(cart.items).length : 0
            cart.total_quantity = cart.total_quantity - (cartItemOld ? cartItemOld.quantity : 0) + cartItem.quantity
            cart.total = (cart.total || 0) - (cartItemOld ? cartItemOld.total || 0 : 0) + (cartItem.total || 0)
            cart.package_count =
                cart.package_count - (cartItemOld ? cartItemOld.package_count : 0) + cartItem.package_count

            cart.total_volume =
                cart.total_volume -
                (cartItemOld ? cartItemOld.quantity * cartItemOld.product.unit_volume : 0) +
                cartItem.quantity * cartItem.product.unit_volume

            if (typeof cart.total_eco_tax !== 'undefined') {
                cart.total_eco_tax =
                    (cart.total_eco_tax || 0) -
                    (cartItemOld ? cartItemOld.total_eco_tax || 0 : 0) +
                    (cartItem.total_eco_tax || 0)
            }

            if (storeId && cart.store_carts) {
                // récupération du panier
                let subCartId: number | undefined = undefined
                for (const idx1 in cart.store_carts) {
                    const storeCart = cart.store_carts[idx1]
                    if (storeCart.customer.indexOf(storeId) > -1) {
                        subCartId = parseInt(idx1)
                        break
                    }
                }
                if (typeof subCartId === 'number' && !isNaN(subCartId)) {
                    const subCart = cart.store_carts[subCartId]
                    subCart.items_count += 1
                    subCart.total_quantity =
                        subCart.total_quantity - (cartItemOld ? cartItemOld.quantity : 0) + cartItem.quantity
                    subCart.total =
                        (subCart.total || 0) - (cartItemOld ? cartItemOld.total || 0 : 0) + (cartItem.total || 0)
                    subCart.package_count =
                        subCart.package_count - (cartItemOld ? cartItemOld.package_count : 0) + cartItem.package_count
                    subCart.total_volume =
                        subCart.total_volume -
                        (cartItemOld ? cartItemOld.quantity * cartItemOld.product.unit_volume : 0) +
                        cartItem.quantity * cartItem.product.unit_volume

                    if (typeof subCart.total_eco_tax !== 'undefined') {
                        subCart.total_eco_tax =
                            (subCart.total_eco_tax || 0) -
                            (cartItemOld ? cartItemOld.total_eco_tax || 0 : 0) +
                            (cartItem.total_eco_tax || 0)
                    }

                    cart.store_carts[subCartId] = subCart
                }
            }

            const list = state.list
                .slice(0, idx)
                .concat([cart])
                .concat(state.list.slice(idx + 1))

            // split quantities in main cart quantities & other carts quantities
            let mainState = { ...state.items.main }
            let subState = { ...state.items.sub }

            if (state.main.indexOf(cartItem.cart!) > -1) {
                mainState = {
                    ...state.items.main,
                    canIncrementQuantity: formatCanIncrementQuantityValues(list, state.main),
                    quantities: formatQuantityValues(list, state.main),
                    errors: formatErrorValues(list, state.main),
                }
            } else {
                subState = {
                    ...state.items.sub,
                    canIncrementQuantity: formatCanIncrementQuantityValues(list, state.main, true),
                    quantities: formatQuantityValues(list, state.main, true),
                    errors: formatErrorValues(list, state.main, true),
                }
            }

            let items = {
                ...state.items,
                main: mainState,
                sub: subState,
                fetching: {
                    ...state.items.fetching,
                    [cartItem.product.id]: false,
                },
            }

            if (storeId && items.store_quantities[cartItem.product.id]) {
                items = {
                    ...items,
                    store_quantities: {
                        ...items.store_quantities,
                        [cartItem.product.id]: {
                            ...items.store_quantities[cartItem.product.id],
                            updating: {
                                ...items.store_quantities[cartItem.product.id].updating,
                                [storeId]: false,
                            },
                            quantities: cartItem.store_quantities,
                        },
                    },
                }
            }

            return Object.assign({}, state, {
                list: list,
                items: items,
                orders: cartsCartsState,
            })
        }
        case ActionTypes.REVERT_QUANTITY_ACTION: {
            const { quantity, storeId, cartId, productId } = action.payload
            const storeQuantities = { ...state.items.store_quantities }
            const mainQuantities = { ...state.items.main.quantities }
            const subQuantities = { ...state.items.sub.quantities }

            if (!storeId) {
                let isMain = false
                let key = 'unknown'
                let carts: ICartCollection = []
                if (!cartId) {
                    carts = state.list.filter((item) => state.main.indexOf(item['@id']) > -1)
                } else {
                    key = cartId
                    isMain = state.main.indexOf(cartId) > -1
                    const crt = state.list.find((itm) => itm['@id'] === cartId)
                    carts = crt ? [crt] : []
                }

                // a-t-on un cart item ?
                const cartItem = findCartItemByProductId(carts, productId)
                if (cartItem) {
                    isMain = state.main.indexOf(cartItem.cart) > -1
                    key = cartItem.cart
                }

                if (isMain) {
                    mainQuantities[key] = {
                        ...mainQuantities[key],
                        [productId]: quantity,
                    }
                } else {
                    subQuantities[key] = {
                        ...subQuantities[key],
                        [productId]: quantity,
                    }
                }
            }

            if (storeId && storeQuantities[action.payload.productId]) {
                storeQuantities[action.payload.productId].updating = {
                    ...storeQuantities[productId].updating,
                    [storeId]: false,
                }
                storeQuantities[action.payload.productId].quantities = {
                    ...storeQuantities[productId].quantities,
                    [storeId]: quantity,
                }
            }

            return {
                ...state,
                items: {
                    store_quantities: storeQuantities,
                    main: {
                        ...state.items.main,
                        quantities: mainQuantities,
                    },
                    sub: {
                        ...state.items.sub,
                        quantities: subQuantities,
                    },
                    fetching: {
                        ...state.items.fetching,
                        [productId]: false,
                    },
                },
            }
        }
        case ActionTypes.CLEAN_CART_PROCESS_ACTION: {
            let fetching: CollectionMap<boolean> = {}
            fetching = action.payload.cartIds.reduce((obj, cartId) => ((obj[cartId] = true), obj), fetching)

            return {
                ...state,
                clean: {
                    ...state.clean,
                    fetching: {
                        ...state.clean.fetching,
                        ...fetching,
                    },
                },
            }
        }
        case ActionTypes.CLEAN_CART_SUCCESS_ACTION: {
            const { carts } = action.payload
            let fetching: CollectionMap<boolean> = {}
            fetching = carts.reduce((obj, cart) => ((obj[cart['@id']] = false), obj), fetching)
            let cartList = state.list
            for (const index in carts) {
                const cartNew = carts[index]
                const idx = cartList.findIndex((obj) => obj['@id'] === cartNew['@id'])
                if (idx > -1) {
                    cartList = cartList
                        .slice(0, idx)
                        .concat([cartNew])
                        .concat(state.list.slice(idx + 1))
                }
            }

            return {
                ...state,
                list: cartList,
                items: {
                    ...state.items,
                    main: {
                        ...state.items.main,
                        canIncrementQuantity: formatCanIncrementQuantityValues(cartList, state.main),
                        quantities: formatQuantityValues(cartList, state.main),
                        errors: formatErrorValues(cartList, state.main),
                    },
                    sub: {
                        ...state.items.sub,
                        canIncrementQuantity: formatCanIncrementQuantityValues(cartList, state.main, true),
                        quantities: formatQuantityValues(cartList, state.main, true),
                        errors: formatErrorValues(cartList, state.main, true),
                    },
                },
                clean: {
                    ...state.clean,
                    fetching: {
                        ...state.clean.fetching,
                        ...fetching,
                    },
                },
            }
        }
        case ActionTypes.REFRESH_CARTS_ACTION: {
            const { carts } = action.payload
            const isMain = action.payload.main

            const main = [...state.main]
            let list = [...state.list]
            for (const cartIndex in carts) {
                const currentCart = carts[cartIndex]
                const idx = state.list.findIndex((obj) => obj['@id'] === currentCart['@id'])
                if (idx === -1) {
                    list.push(currentCart)
                } else {
                    list = list
                        .slice(0, idx)
                        .concat([currentCart])
                        .concat(state.list.slice(idx + 1))
                }
                if (isMain && main.indexOf(currentCart['@id']) === -1) {
                    main.push(currentCart['@id'])
                }
            }

            return {
                ...state,
                main: main,
                list: list,
                locked: {
                    shipping_location: formatShippingLocationLockValues(list),
                    cart: formatCartLockValues(list),
                },
                items: {
                    ...state.items,
                    main: {
                        ...state.items.main,
                        canIncrementQuantity: formatCanIncrementQuantityValues(list, main),
                        quantities: formatQuantityValues(list, main),
                        errors: formatErrorValues(list, main),
                    },
                    sub: {
                        ...state.items.sub,
                        canIncrementQuantity: formatCanIncrementQuantityValues(list, main, true),
                        quantities: formatQuantityValues(list, main, true),
                        errors: formatErrorValues(list, main, true),
                    },
                },
            }
        }
        case ActionTypes.REFRESH_CART_ACTION: {
            const idx = state.list.findIndex((obj) => obj['@id'] === action.payload.cart['@id'])
            let list = [...state.list]
            if (idx === -1) {
                list.push(action.payload.cart)
            } else {
                list = list
                    .slice(0, idx)
                    .concat([action.payload.cart])
                    .concat(state.list.slice(idx + 1))
            }
            const main = [...state.main]
            if (action.payload.main && main.indexOf(action.payload.cart['@id']) === -1) {
                main.push(action.payload.cart['@id'])
            }

            return Object.assign({}, state, {
                main: main,
                list: list,
                locked: {
                    shipping_location: formatShippingLocationLockValues(list),
                    cart: formatCartLockValues(list),
                },
                items: {
                    ...state.items,
                    main: {
                        ...state.items.main,
                        canIncrementQuantity: formatCanIncrementQuantityValues(list, main),
                        quantities: formatQuantityValues(list, main),
                        errors: formatErrorValues(list, main),
                    },
                    sub: {
                        ...state.items.sub,
                        canIncrementQuantity: formatCanIncrementQuantityValues(list, main, true),
                        quantities: formatQuantityValues(list, main, true),
                        errors: formatErrorValues(list, main, true),
                    },
                },
            })
        }
        case ActionTypes.DUPLICATE_PROCESS_ACTION: {
            const key = `${action.payload.resourceId}`
            const errors = { ...state.duplicate.errors }
            delete errors[key]
            return {
                ...state,
                duplicate: {
                    ...state.duplicate,
                    fetching: {
                        ...state.duplicate.fetching,
                        [key]: true,
                    },
                    data: {
                        ...state.duplicate.data,
                        [key]: action.payload.data,
                    },
                    success: {
                        [key]: false,
                    },
                    errors: errors,
                },
            }
        }
        case ActionTypes.DUPLICATE_RESET_ACTION: {
            const key = `${action.payload.resourceId}`
            const errors = { ...state.duplicate.errors }
            delete errors[key]
            const fetching = { ...state.duplicate.fetching }
            delete fetching[key]
            const success = { ...state.duplicate.success }
            delete success[key]
            const data = { ...state.duplicate.data }
            delete data[key]
            return {
                ...state,
                duplicate: {
                    ...state.duplicate,
                    fetching: fetching,
                    data: data,
                    success: success,
                    errors: errors,
                },
            }
        }
        case ActionTypes.DUPLICATE_FAILURE_ACTION: {
            const key = `${action.payload.resourceId}`
            return {
                ...state,
                duplicate: {
                    ...state.duplicate,
                    fetching: {
                        ...state.duplicate.fetching,
                        [key]: false,
                    },
                    errors: {
                        ...state.duplicate.errors,
                        [key]: action.payload.error,
                    },
                },
            }
        }
        case ActionTypes.DUPLICATE_SUCCESS_ACTION: {
            const { resourceId, carts, warnings } = action.payload
            const key = `${resourceId}`

            const reorderErrors = { ...state.duplicate.errors }
            delete reorderErrors[key]

            const reorderWarnings = { ...state.duplicate.warnings }
            delete reorderWarnings[key]
            if (warnings) {
                reorderWarnings[key] = warnings
            }

            let list = [...state.list]
            carts.forEach((cart) => {
                const idx = state.list.findIndex((obj) => obj['@id'] === cart['@id'])
                if (idx === -1) {
                    list.push(cart)
                } else {
                    list = list
                        .slice(0, idx)
                        .concat([cart])
                        .concat(state.list.slice(idx + 1))
                }
            })

            return Object.assign({}, state, {
                list: list,
                locked: {
                    shipping_location: formatShippingLocationLockValues(list),
                    cart: formatCartLockValues(list),
                },
                items: {
                    ...state.items,
                    main: {
                        ...state.items.main,
                        canIncrementQuantity: formatCanIncrementQuantityValues(list, state.main),
                        quantities: formatQuantityValues(list, state.main),
                        errors: formatErrorValues(list, state.main),
                    },
                    sub: {
                        ...state.items.sub,
                        canIncrementQuantity: formatCanIncrementQuantityValues(list, state.main, true),
                        quantities: formatQuantityValues(list, state.main, true),
                        errors: formatErrorValues(list, state.main, true),
                    },
                },
                duplicate: {
                    ...state.duplicate,
                    fetching: {
                        ...state.duplicate.fetching,
                        [key]: false,
                    },
                    data: {
                        ...state.duplicate.data,
                    },
                    success: {
                        [key]: true,
                    },
                    warnings: reorderWarnings,
                    errors: reorderErrors,
                },
            })
        }
        case ActionTypes.REORDER_PROCESS_ACTION: {
            const key = `${action.payload.resourceId}`
            const errors = { ...state.orders.reorder.errors }
            delete errors[key]
            return {
                ...state,
                orders: {
                    ...state.orders,
                    reorder: {
                        ...state.orders.reorder,
                        fetching: {
                            ...state.orders.reorder.fetching,
                            [key]: true,
                        },
                        data: {
                            ...state.orders.reorder.data,
                            [key]: action.payload.data,
                        },
                        success: {
                            [key]: false,
                        },
                        errors: errors,
                    },
                },
            }
        }
        case ActionTypes.REORDER_RESET_ACTION: {
            const key = `${action.payload.resourceId}`
            const errors = { ...state.orders.reorder.errors }
            delete errors[key]
            const fetching = { ...state.orders.reorder.fetching }
            delete fetching[key]
            const success = { ...state.orders.reorder.success }
            delete success[key]
            const data = { ...state.orders.reorder.data }
            delete data[key]
            return {
                ...state,
                orders: {
                    ...state.orders,
                    reorder: {
                        ...state.orders.reorder,
                        fetching: fetching,
                        data: data,
                        success: success,
                        errors: errors,
                    },
                },
            }
        }
        case ActionTypes.REORDER_FAILURE_ACTION: {
            const key = `${action.payload.resourceId}`
            return {
                ...state,
                orders: {
                    ...state.orders,
                    reorder: {
                        ...state.orders.reorder,
                        fetching: {
                            ...state.orders.reorder.fetching,
                            [key]: false,
                        },
                        errors: {
                            ...state.orders.reorder.errors,
                            [key]: action.payload.error,
                        },
                    },
                },
            }
        }
        case ActionTypes.REORDER_SUCCESS_ACTION: {
            const { resourceId, carts, warnings } = action.payload
            const key = `${resourceId}`

            const reorderErrors = { ...state.orders.reorder.errors }
            delete reorderErrors[key]

            const reorderWarnings = { ...state.orders.reorder.warnings }
            delete reorderWarnings[key]
            if (warnings) {
                reorderWarnings[key] = warnings
            }

            let list = [...state.list]
            carts.forEach((cart) => {
                const idx = state.list.findIndex((obj) => obj['@id'] === cart['@id'])
                if (idx === -1) {
                    list.push(cart)
                } else {
                    list = list
                        .slice(0, idx)
                        .concat([cart])
                        .concat(state.list.slice(idx + 1))
                }
            })

            return Object.assign({}, state, {
                list: list,
                locked: {
                    shipping_location: formatShippingLocationLockValues(list),
                    cart: formatCartLockValues(list),
                },
                items: {
                    ...state.items,
                    main: {
                        ...state.items.main,
                        canIncrementQuantity: formatCanIncrementQuantityValues(list, state.main),
                        quantities: formatQuantityValues(list, state.main),
                        errors: formatErrorValues(list, state.main),
                    },
                    sub: {
                        ...state.items.sub,
                        canIncrementQuantity: formatCanIncrementQuantityValues(list, state.main, true),
                        quantities: formatQuantityValues(list, state.main, true),
                        errors: formatErrorValues(list, state.main, true),
                    },
                },
                orders: {
                    ...state.orders,
                    reorder: {
                        ...state.orders.reorder,
                        fetching: {
                            ...state.orders.reorder.fetching,
                            [key]: false,
                        },
                        data: {
                            ...state.orders.reorder.data,
                        },
                        success: {
                            [key]: true,
                        },
                        warnings: reorderWarnings,
                        errors: reorderErrors,
                    },
                },
            })
        }
        case ActionTypes.SAVE_TO_ORDER_PROCESS_ACTION: {
            const errors = state.orders.create.errors
            delete errors[action.payload.cartId]
            return {
                ...state,
                orders: {
                    ...state.orders,
                    create: {
                        ...state.orders.create,
                        fetching: {
                            ...state.orders.create.fetching,
                            [action.payload.cartId]: true,
                        },
                        data: {
                            ...state.orders.create.data,
                            [action.payload.cartId]: action.payload.data,
                        },
                        errors: errors,
                    },
                },
            }
        }
        case ActionTypes.SAVE_TO_ORDER_FAILURE_ACTION: {
            return {
                ...state,
                orders: {
                    ...state.orders,
                    create: {
                        ...state.orders.create,
                        fetching: {
                            ...state.orders.create.fetching,
                            [action.payload.cartId!]: false,
                        },
                        errors: {
                            ...state.orders.create.errors,
                            [action.payload.cartId!]: action.payload.error,
                        },
                    },
                },
            }
        }
        case ActionTypes.SAVE_TO_ORDER_SUCCESS_ACTION: {
            const errors = state.orders.create.errors
            delete errors[action.payload.cartId]
            return {
                ...state,
                orders: {
                    ...state.orders,
                    create: {
                        ...state.orders.create,
                        fetching: {
                            ...state.orders.create.fetching,
                            [action.payload.cartId]: false,
                        },
                        data: {
                            ...state.orders.create.data,
                            [action.payload.cartId]: {},
                        },
                        items: {
                            ...state.orders.create.items,
                            [action.payload.cartId]: action.payload.orders,
                        },
                        errors: errors,
                    },
                },
            }
        }
        case ActionTypes.REFRESH_CART_ITEMS_ERRORS_ACTION: {
            const { errors, cartId } = action.payload
            const isMain = state.main.indexOf(cartId) > -1
            let mainErrors = { ...state.items.main.errors }
            let subErrors = { ...state.items.sub.errors }

            if (isMain) {
                mainErrors = errors
            } else {
                subErrors = errors
            }

            return {
                ...state,
                items: {
                    ...state.items,
                    main: {
                        ...state.items.main,
                        errors: mainErrors,
                    },
                    sub: {
                        ...state.items.sub,
                        errors: subErrors,
                    },
                },
            }
        }
        case ActionTypes.STORE_QUANTITY_PROCESS_ACTION: {
            const storeQuantities = { ...state.items.store_quantities }
            storeQuantities[action.payload.productId] = { loading: true }

            return {
                ...state,
                items: {
                    ...state.items,
                    store_quantities: storeQuantities,
                },
            }
        }
        case ActionTypes.STORE_QUANTITY_FAILURE_ACTION: {
            const storeQuantities = { ...state.items.store_quantities }
            storeQuantities[action.payload.productId] = { loading: false, error: action.payload.error }

            return {
                ...state,
                items: {
                    ...state.items,
                    store_quantities: storeQuantities,
                },
            }
        }
        case ActionTypes.STORE_QUANTITY_SUCCESS_ACTION: {
            const storeQuantities = { ...state.items.store_quantities }
            storeQuantities[action.payload.productId] = {
                loading: false,
                error: undefined,
                quantities: action.payload.storeQuantity,
            }

            return {
                ...state,
                items: {
                    ...state.items,
                    store_quantities: storeQuantities,
                },
            }
        }
        case ActionTypes.RESET_ORDERS_ACTION: {
            return state
        }
        case ActionTypes.SAVE_TO_ORDERS_PROCESS_ACTION: {
            return state
        }
        case ActionTypes.CART_LIGHT_PROCESS_ACTION:
            return {
                ...state,
                light: {
                    // On n'en charge qu'un à la fois
                    // ...state.light,
                    [action.payload.cartIdParent]: {
                        tms: undefined,
                        fetching: true,
                        error: undefined,
                        list: state.light[action.payload.cartIdParent]
                            ? state.light[action.payload.cartIdParent].list
                            : undefined,
                    },
                },
            }
        case ActionTypes.CART_LIGHT_FAILURE_ACTION: {
            return {
                ...state,
                light: {
                    ...state.light,
                    [action.payload.cartIdParent]: {
                        tms: Date.now(),
                        fetching: false,
                        error: action.payload.error,
                        list: undefined,
                    },
                },
            }
        }
        case ActionTypes.CART_LIGHT_RESET_ACTION: {
            return {
                ...state,
                light: {},
            }
        }
        case ActionTypes.CART_LIGHT_SUCCESS_ACTION: {
            return {
                ...state,
                light: {
                    ...state.light,
                    [action.payload.cartIdParent]: {
                        tms: Date.now(),
                        fetching: false,
                        error: undefined,
                        list: action.payload.response.data['hydra:member'],
                    },
                },
            }
        }
        case ActionTypes.CART_MULTI_STORE_PROCESS_ACTION:
            return {
                ...state,
                multi_store: {
                    ...state.multi_store,
                    store_cart_list: {
                        ...state.multi_store.store_cart_list,
                        tms: undefined,
                        fetching: true,
                        error: undefined,
                    },
                },
            }
        case ActionTypes.CART_MULTI_STORE_FAILURE_ACTION: {
            return {
                ...state,
                multi_store: {
                    ...state.multi_store,
                    store_cart_list: {
                        ...state.multi_store.store_cart_list,
                        tms: Date.now(),
                        fetching: false,
                        error: action.payload.error,
                        items: undefined,
                    },
                },
            }
        }
        case ActionTypes.CART_MULTI_STORE_RESET_ACTION: {
            return {
                ...state,
                multi_store: {
                    ...state.multi_store,
                    store_cart_list: {
                        ...cartsMultiStoreState.store_cart_list,
                    },
                },
            }
        }
        case ActionTypes.CART_MULTI_STORE_SUCCESS_ACTION: {
            return {
                ...state,
                multi_store: {
                    ...state.multi_store,
                    store_cart_list: {
                        tms: Date.now(),
                        fetching: false,
                        error: undefined,
                        items: action.payload.data['hydra:member'],
                    },
                },
            }
        }
        case ActionTypes.BULK_ADD_TO_CART_RESET_ACTION:
            return {
                ...state,
                bulk: cartsInitialBulkState,
            }
        case ActionTypes.BULK_ADD_TO_CART_PROCESS_ACTION:
            return {
                ...state,
                bulk: {
                    mode: action.payload.mode,
                    tms: undefined,
                    fetching: true,
                    error: undefined,
                    quantity: action.payload.quantity,
                    list: undefined,
                    planogramId: action.payload.planogramId || undefined,
                    planogramModuleId: action.payload.planogramModuleId || undefined,
                    success: false,
                },
            }
        case ActionTypes.BULK_ADD_TO_CART_FAILURE_ACTION: {
            return {
                ...state,
                bulk: {
                    ...state.bulk,
                    tms: Date.now(),
                    fetching: false,
                    error: action.payload.error,
                    list: undefined,
                    success: false,
                },
            }
        }
        case ActionTypes.BULK_ADD_TO_CART_SUCCESS_ACTION: {
            return {
                ...state,
                bulk: {
                    ...state.bulk,
                    tms: Date.now(),
                    fetching: false,
                    success: true,
                    list: undefined,
                    unavailable_stock_products: action.payload.unavailableStockProducts,
                    error: undefined,
                },
            }
        }
        case ActionTypes.LOAD_CART_PROCESS_ACTION:
            return {
                ...state,
                load: {
                    ...state.load,
                    [action.payload.cartId]: {
                        tms: undefined,
                        fetching: true,
                        error: undefined,
                    },
                },
            }
        case ActionTypes.LOAD_CART_FAILURE_ACTION: {
            return {
                ...state,
                load: {
                    ...state.load,
                    [action.payload.cartId]: {
                        tms: Date.now(),
                        fetching: false,
                        error: action.payload.error,
                    },
                },
            }
        }
        case ActionTypes.LOAD_CART_SUCCESS_ACTION: {
            const cartNew = action.payload.response
            const isMain = action.payload.main
            const idx = state.list.findIndex((obj) => obj['@id'] === cartNew['@id'])
            let list = [...state.list]
            if (idx === -1) {
                list.push(cartNew)
            } else {
                list = list
                    .slice(0, idx)
                    .concat([cartNew])
                    .concat(state.list.slice(idx + 1))
            }
            const main = [...state.main]
            if (isMain && main.indexOf(cartNew['@id']) === -1) {
                main.push(cartNew['@id'])
            }

            // split quantities in main cart quantities & other carts quantities
            let mainState = { ...state.items.main }
            let subState = { ...state.items.sub }

            if (isMain) {
                mainState = {
                    canIncrementQuantity: formatCanIncrementQuantityValues(list, main),
                    quantities: formatQuantityValues(list, main),
                    errors: formatErrorValues(list, main),
                }
            } else {
                subState = {
                    canIncrementQuantity: formatCanIncrementQuantityValues(list, main, true),
                    quantities: formatQuantityValues(list, main, true),
                    errors: formatErrorValues(list, main, true),
                }
            }

            return Object.assign({}, state, {
                main: main,
                list: list,
                locked: {
                    shipping_location: formatShippingLocationLockValues(list),
                    cart: formatCartLockValues(list),
                },
                load: {
                    ...state.load,
                    [action.payload.cartId]: {
                        tms: Date.now(),
                        fetching: false,
                        error: undefined,
                    },
                },
                items: {
                    ...state.items,
                    main: mainState,
                    sub: subState,
                },
            })
        }
        case StockAlertActionTypes.STOCK_ALERT_FAILURE_ACTION:
        case StockAlertActionTypes.STOCK_ALERT_BULK_SUCCESS_ACTION:
        case StockAlertActionTypes.STOCK_ALERT_SUCCESS_ACTION: {
            if (!state.list) {
                return state
            }

            // NOTE: on sait que subscriptionEnabled est pas toujours définit (seulement dans failure) mais pour éviter de la redondance, on mutualise les 2 actions
            const {
                // @ts-ignore
                productId: productIdPayload,
                subscribe,
                // @ts-ignore
                subscriptionEnabled,
                // @ts-ignore
                productIds: productIdsPayload,
            } = action.payload
            const productIds = typeof productIdsPayload === 'undefined' ? [productIdPayload] : productIdsPayload

            let list = [...state.list]

            for (const productId of productIds) {
                const cartItem = findCartItemByProductId(state.list, productId)
                if (!cartItem) {
                    continue
                }

                // récupération du panier
                const cart = findCartBy(state.list, '@id', cartItem.cart)
                if (!cart) {
                    continue
                }

                // index
                const cartIndex = state.list.findIndex((item) => item['@id'] === cart['@id'])

                // copie du panier
                const cartNew = {
                    ...cart,
                    items: {
                        ...cart.items,
                        [productId]: {
                            ...cartItem,
                            product: {
                                ...cartItem.product,
                                has_stock_alert: subscribe,
                                can_add_stock_alert: isBoolean(subscriptionEnabled)
                                    ? subscriptionEnabled
                                    : cartItem.product.can_add_stock_alert,
                            },
                        },
                    },
                }

                // on met à jour la liste
                list = list
                    .slice(0, cartIndex)
                    .concat([cartNew])
                    .concat(state.list.slice(cartIndex + 1))
            }

            return Object.assign({}, state, {
                list: list,
            })
        }

        case ActionTypes.LOCKER_PROCESS_ACTION: {
            const errors = state.lock.errors || {}
            delete errors[action.payload.cartId]
            const success = state.lock.success || {}
            delete success[action.payload.cartId]

            return {
                ...state,
                lock: {
                    ...state.lock,
                    fetching: {
                        ...state.lock.fetching,
                        [action.payload.cartId]: true,
                    },
                    errors: errors,
                    success: success,
                },
            }
        }
        case ActionTypes.LOCKERS_PROCESS_ACTION: {
            return {
                ...state,
                lock: {
                    ...cartsLockState,
                    fetching: {
                        all: true,
                    },
                },
            }
        }
        case ActionTypes.LOCKERS_SUCCESS_ACTION: {
            return {
                ...state,
                lock: {
                    ...cartsLockState,
                    fetching: {
                        all: false,
                    },
                    success: {
                        all: true,
                    },
                    errors: {
                        all: action.payload.errors || [],
                    },
                },
            }
        }
        case ActionTypes.LOCKERS_FAILURE_ACTION: {
            return {
                ...state,
                lock: {
                    ...cartsLockState,
                    fetching: {
                        all: false,
                    },
                    success: {
                        all: false,
                    },
                    errors: {
                        all: action.payload.errors || [],
                    },
                },
            }
        }
        case ActionTypes.LOCKER_RESET_ACTION: {
            const { cartId } = action.payload

            if (!cartId) {
                return {
                    ...state,
                    lock: {
                        ...cartsLockState,
                    },
                }
            }

            const errors = state.lock.errors || {}
            delete errors[cartId]
            const success = state.lock.success || {}
            delete success[cartId]
            const fetching = state.lock.fetching || {}
            delete fetching[cartId]

            return {
                ...state,
                lock: {
                    ...state.lock,
                    fetching: fetching,
                    errors: errors,
                    success: success,
                },
            }
        }
        case ActionTypes.LOCKERS_RESET_ACTION: {
            const { cartIds } = action.payload

            if (!cartIds || cartIds.length === 0) {
                return {
                    ...state,
                    lock: {
                        ...cartsLockState,
                    },
                }
            }

            const errors = state.lock.errors || {}
            const success = state.lock.success || {}
            const fetching = state.lock.fetching || {}
            for (const cartId in cartIds) {
                delete errors[cartId]
                delete success[cartId]
                delete fetching[cartId]
            }

            return {
                ...state,
                lock: {
                    ...state.lock,
                    fetching: fetching,
                    errors: errors,
                    success: success,
                },
            }
        }
        case ActionTypes.LOCKER_FAILURE_ACTION: {
            return {
                ...state,
                lock: {
                    ...state.lock,
                    fetching: {
                        ...state.lock.fetching,
                        [action.payload.cartId]: false,
                    },
                    errors: {
                        ...state.lock.errors,
                        [action.payload.cartId]: [action.payload.error],
                    },
                },
            }
        }
        case ActionTypes.LOCKER_SUCCESS_ACTION: {
            // cart ID
            const { cart } = action.payload

            let list = [...state.list]
            const idx = state.list.findIndex((obj) => obj['@id'] === cart['@id'])
            if (idx === -1) {
                list.push(cart)
            } else {
                list = list
                    .slice(0, idx)
                    .concat([cart])
                    .concat(state.list.slice(idx + 1))
            }

            return {
                ...state,
                list: list,
                locked: {
                    shipping_location: formatShippingLocationLockValues(list),
                    cart: formatCartLockValues(list),
                },
                items: {
                    ...state.items,
                    main: {
                        ...state.items.main,
                        canIncrementQuantity: formatCanIncrementQuantityValues(list, state.main),
                        quantities: formatQuantityValues(list, state.main),
                        errors: formatErrorValues(list, state.main),
                    },
                    sub: {
                        ...state.items.sub,
                        canIncrementQuantity: formatCanIncrementQuantityValues(list, state.main, true),
                        quantities: formatQuantityValues(list, state.main, true),
                        errors: formatErrorValues(list, state.main, true),
                    },
                },
                lock: {
                    ...state.lock,
                    fetching: {
                        ...state.lock.fetching,
                        [cart['@id']]: false,
                    },
                    success: {
                        ...state.lock.success,
                        [cart['@id']]: true,
                    },
                },
            }
        }
        case ActionTypes.CART_SALESMAN_LIST_RESET_ACTION: {
            return {
                ...state,
                salesman: {
                    ...initialSalesmanListState,
                },
            }
        }
        case ActionTypes.CART_SALESMAN_LIST_SUCCESS_ACTION:
            return {
                ...state,
                salesman: {
                    ...state.salesman,
                    items: action.payload['hydra:member'],
                    totalItems: action.payload['hydra:totalItems'],
                    nextPageUrl: action.payload['hydra:view']['hydra:next'],
                    prevPageUrl: action.payload['hydra:view']['hydra:prev'],
                    firstPageUrl: action.payload['hydra:view']['hydra:first'],
                    lastPageUrl: action.payload['hydra:view']['hydra:last'],
                },
            }
        default:
            return state
    }
}

export default cartsReducer

import { IProductAttributeCollection, IProductAttributeValue } from '../services/api/service/products/types'
import { PrimitiveType } from 'intl-messageformat'
import { MessageDescriptor } from 'react-intl'
import Qs from 'qs'
import { removeProductPathToPathName } from '../store/products/utils'
import rtrim from './rtrim'

export function normalizeQuantity(quantity: number, packing: number): number {
    const nextPackingQuantity = Math.ceil(quantity / packing)
    return packing * nextPackingQuantity
}

export function normalizePackingQuantity(quantity: number, packing: number): number {
    return Math.ceil(quantity / packing)
}

export function formatAttributeValue(
    key: keyof IProductAttributeCollection,
    value: number | boolean | IProductAttributeValue | undefined,
    attributes: IProductAttributeCollection,
    formatMessage?: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string
): string | number | null {
    if (['weight'].indexOf(key) > -1) {
        if (typeof value === 'number') {
            const convertedValue = value / 1000
            if (formatMessage) {
                return formatMessage({ id: 'product.attribute.weight_value' }, { value: value, weight: convertedValue })
            }

            return value / 1000
        }
    } else if (['box_weight'].indexOf(key) > -1) {
        if (typeof value === 'number') {
            if (formatMessage) {
                return formatMessage({ id: 'product.attribute.weight_value' }, { value: value })
            }
        }
    }

    //product.attribute.brand_value
    if (typeof value === 'number') {
        return value
    } else if (typeof value === 'object') {
        return value.label ? value.label : null
    }

    return null
}

// a garder en synchro avec routes/routes.tsx
export const PRODUCT_PATHNAME_REGEXP = /\/p\/[A-Za-z0-9-]+$/
export function generateProductUrl(productId: string, locale: string, addToPath = true, addQueries = true): string {
    if (!addToPath) {
        return `/${locale}/p/${productId}`
    }
    const parsed = Qs.parse(window.location.search.substring(1))
    const stringified = Qs.stringify(parsed)
    const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
    const baseUrl = removeProductPathToPathName(rtrim(window.location.pathname, '/'))
    return addQueries ? `${baseUrl}/p/${productId}${qstring}` : `${baseUrl}/p/${productId}`
}

/**
 *
 * Entry
 *
 */
import classNames from 'classnames'
import React, { memo, useCallback, useRef } from 'react'
import FlatIcon from '../../../../../../components/Icon/FlatIcon'
import { IOptionEntryProps } from '../../type'

function Entry({ selected, option, onChange }: IOptionEntryProps): JSX.Element {
    const target = useRef<HTMLDivElement>(null)
    const handleOnClick = useCallback(() => {
        if (onChange) {
            onChange(option, !selected)
        }
    }, [onChange, option, selected])

    return (
        <div
            ref={target}
            onClick={handleOnClick}
            className={classNames('entry', {
                'is-selected': selected,
            })}
        >
            <span className={'form-label-text'}>{option.label}</span>
            <FlatIcon icon={'check'} className={'app-icon-selected'} />
        </div>
    )
}

Entry.defaultProps = {} as Partial<IOptionEntryProps>

export default memo(Entry)

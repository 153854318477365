/*
 *
 * Planograms
 *
 */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import ReactPlaceholder from 'react-placeholder'
import { Alert, Col, Form, Row } from 'react-bootstrap'
import { default as PlanogramsPlaceholder } from './Placeholder'
import classNames from 'classnames'
import { IApplicationRootState } from '../../store'
import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IAppErrorTypes } from '../../store/app/types'
import { ICustomer } from '../../services/api/service/customers/types'
import {
    makeSelectClassificationAttributeDefinition,
    makeSelectClassificationFamilyTreeDefault,
} from '../../store/classification/selectors'
import { IFamilyTreeCollection } from '../../services/api/service/classification/types'
import { planogramListFormatQueriesToParams } from '../../store/classification/utils'
import { getPath } from '../../routes'

import { default as FilterList } from './Filter/List'
import { default as MobileFilterList } from './Filter/Mobile'

import { usePlanogramListParsedQuery } from './useParsedQuery'
import { makeSelectCartMode } from '../../store/carts/selectors'
import { StrictCartMode } from '../../store/carts/types'
import { IMe } from '../../services/api/service/me/types'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { objectEquals } from 'object-equals'
import { makeSelectCustomer, makeSelectCustomerStore } from '../../store/customers/selectors'
import { MediaContext } from 'react-media-query-hoc'
import { ICatalogListFilterCollection, ICatalogListFilterTree } from '../../services/api/service/core/types'
import { customerFilterHasSelectedValues } from '../../utils/catalog'
import {
    IPlanogramList,
    IPlanogramListFiltersParameters,
    PlanogramsListMode,
} from '../../services/api/service/planograms/types'
import {
    makeSelectPlanogramsListError,
    makeSelectPlanogramsListFetching,
    makeSelectPlanogramsListFilters,
    makeSelectPlanogramsListItems,
    makeSelectPlanogramsListTotalItems,
} from '../../store/planograms/selectors'
import { planogramListHasPublicFilters } from '../../store/planograms/utils'
import { generatePlanogramUrl } from '../../utils/planogramHelper'
import { planogramsListProcessAction } from '../../store/planograms/actions'
import Counter from './Partial/Counter'
import ItemsPerPage from '../Catalog/Partial/ItemsPerPage'
import Pagination from './Partial/Pagination'
import NotFoundAlert, { NotFoundReasonModes } from '../../components/Catalog/NotFoundAlert'
import Item from './Partial/Item/Item'

interface IProps {
    mode?: PlanogramsListMode
    tree?: ICatalogListFilterTree | undefined
    slug?: string
    baseUrl?: string
}

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
    cartMode: makeSelectCartMode(),
    familyTree: makeSelectClassificationFamilyTreeDefault(),
    listFetching: makeSelectPlanogramsListFetching(),
    listItems: makeSelectPlanogramsListItems(),
    listTotalItems: makeSelectPlanogramsListTotalItems(),
    listError: makeSelectPlanogramsListError(),
    definition: makeSelectClassificationAttributeDefinition(),
    listFilters: makeSelectPlanogramsListFilters(),
    me: makeSelectAuthMe(),
})

function Planograms({ tree, baseUrl, slug, mode = PlanogramsListMode.Default }: IProps): JSX.Element {
    const dispatch = useDispatch()
    const { locale } = useIntl()
    const history = useHistory()
    const location = useLocation()
    const { queries } = usePlanogramListParsedQuery()
    const media: any = useContext(MediaContext)
    const [notFoundReason, setNotFoundReason] = useState<NotFoundReasonModes | undefined>(undefined)
    const [currentPlanogramListParams, setCurrentPlanogramListParams] = useState<
        IPlanogramListFiltersParameters | undefined
    >(undefined)

    // redux
    const { listFetching, listItems, listError, listTotalItems, me, listFilters } = useSelector<
        IApplicationRootState,
        {
            listFetching: boolean
            listTotalItems: number | undefined
            listItems: Array<IPlanogramList>
            listError?: IAppErrorTypes | undefined
            customer: ICustomer
            store?: ICustomer
            me: IMe
            cartMode: StrictCartMode
            familyTree: IFamilyTreeCollection
            listFilters?: ICatalogListFilterCollection
        }
    >(stateSelector)

    const planogramsListShowPlaceholder = useMemo(() => {
        return (
            typeof listTotalItems === 'undefined' &&
            typeof listError === 'undefined' &&
            typeof notFoundReason === 'undefined'
        )
    }, [listTotalItems, listError, notFoundReason])

    const hasPublicFiltersAvailable = useMemo(() => {
        return planogramListHasPublicFilters(listFilters, true)
    }, [listFilters])

    const handleOnPlanogramClick = useCallback(
        (planogram: IPlanogramList) => {
            history.push(generatePlanogramUrl(planogram['@id'], locale), { background: location })
        },
        [locale, location, history]
    )

    const handleNotFoundAlertClick = useCallback(() => {
        if (!baseUrl) {
            history.push(generatePath(getPath('planograms', locale), { lang: locale }))
            return
        }
        history.push(baseUrl)
    }, [history, baseUrl, locale])

    useEffect(() => {
        const params: IPlanogramListFiltersParameters | undefined = planogramListFormatQueriesToParams(queries, tree)
        setCurrentPlanogramListParams((ste) => {
            if (!ste || !objectEquals(ste, params)) {
                return params
            }
            return ste
        })
    }, [mode, queries, tree, setCurrentPlanogramListParams])

    useEffect(() => {
        if (!currentPlanogramListParams || (slug && !tree)) {
            return
        }
        dispatch(planogramsListProcessAction(currentPlanogramListParams))
    }, [dispatch, mode, tree, slug, currentPlanogramListParams])

    useEffect(() => {
        if (slug && !tree) {
            setNotFoundReason(NotFoundReasonModes.UnknownFamilyTree)
            return
        }

        if (listFetching || listTotalItems !== 0 || typeof listError !== 'undefined') {
            setNotFoundReason(undefined)
            return
        }

        if (currentPlanogramListParams?.filters && currentPlanogramListParams?.filters.search) {
            setNotFoundReason(NotFoundReasonModes.NoResultsSearch)
            return
        }

        if (customerFilterHasSelectedValues(listFilters, currentPlanogramListParams)) {
            setNotFoundReason(NotFoundReasonModes.NoResultsFilters)
            return
        }

        setNotFoundReason(NotFoundReasonModes.NoResults)
    }, [
        tree,
        slug,
        currentPlanogramListParams,
        setNotFoundReason,
        listFilters,
        listTotalItems,
        listFetching,
        listError,
    ])

    useEffect(() => {
        document.documentElement.classList.add(`catalog-list-member-${me['@type'].toLowerCase()}`)
        return () => {
            document.documentElement.className = document.documentElement.className.replace(
                /catalog-list-member-[A-Za-z0-9_-]+/g,
                ''
            )
        }
    }, [me])

    return (
        <>
            <div className={classNames('catalog-list-section', 'planogram-list-section')}>
                <div className={classNames('catalog-list-header', { 'has-errors': listError })}>
                    <div className={classNames('catalog-list-filter-section')}>
                        {!media.mobile && hasPublicFiltersAvailable && <FilterList className={'desktop'} />}
                    </div>
                    <div className={classNames('catalog-list-action-section', { 'd-none': listError })}>
                        <Counter loading={listFetching} count={listTotalItems} />
                        <div className={'catalog-list-actions'}>
                            <Form inline>
                                {media.mobile && hasPublicFiltersAvailable && <MobileFilterList />}
                                <ItemsPerPage loading={listFetching} count={listTotalItems} />
                            </Form>
                        </div>
                    </div>
                </div>
                <div className={'catalog-list-content'}>
                    <div
                        className={classNames('catalog-list-overlay', {
                            showing: listFetching && typeof listTotalItems !== 'undefined',
                        })}
                    >
                        <FontAwesomeIcon icon="circle-notch" spin={true} className={'app-icon'} />
                    </div>
                    <ReactPlaceholder
                        ready={!planogramsListShowPlaceholder}
                        customPlaceholder={<PlanogramsPlaceholder itemsPerPage={queries?.itemsPerPage} />}
                    >
                        <>
                            {typeof notFoundReason !== 'undefined' && (
                                <NotFoundAlert
                                    reason={notFoundReason}
                                    onClick={handleNotFoundAlertClick}
                                    queries={currentPlanogramListParams}
                                />
                            )}
                            {typeof notFoundReason === 'undefined' && listError && (
                                <Alert variant={'danger'}>
                                    <Alert.Heading>
                                        <FormattedMessage id="default.warning" />
                                    </Alert.Heading>
                                    <p className="mb-0">{listError?.message}</p>
                                </Alert>
                            )}
                            <Row className={'catalog-list'}>
                                {listItems?.map((planogram) => (
                                    <Col key={`planogram_item_${planogram['@id']}`}>
                                        <Item planogram={planogram} />
                                    </Col>
                                ))}
                            </Row>
                        </>
                    </ReactPlaceholder>
                </div>
                <div className={classNames('product-list-footer', { 'd-none': listError })}>
                    <Pagination loading={listFetching} count={listTotalItems} />
                </div>
            </div>
        </>
    )
}

export default Planograms

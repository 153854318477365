import Qs from 'qs'
import { newDecoder } from './qs'
import {
    CatalogListFilterMode,
    CatalogListQueryName,
    IApiCatalogListFilterCollection,
    ICatalogListFilterCollection,
    ICatalogListParameters,
    ICatalogListUserFilterCollection,
} from '../services/api/service/core/types'
import cloneDeep from 'lodash/cloneDeep'
import { $enum } from 'ts-enum-util'
import ApplicationHelper from './applicationHelper'
import { IPaginationQueryParameters } from '../types/pagination'
import { objectEquals } from 'object-equals'
import { Undefinable } from 'tsdef'
import { IProductListParameters } from '../services/api/service/products/types'
import isEmpty from 'lodash/isEmpty'

export interface ICatalogListQueryParameters<
    T extends ICatalogListUserFilterCollection = ICatalogListUserFilterCollection
> extends Partial<Omit<IPaginationQueryParameters, 'search'>> {
    filters?: T
}

export function catalogListHasPublicFilters(
    filters?: ICatalogListFilterCollection,
    includeStaticFilters = false
): boolean {
    if (!filters) {
        return false
    }

    const publicKeys: Array<string> = []
    for (const k in filters) {
        const filter = filters[k]
        if (filter.public && (includeStaticFilters || !filter.static)) {
            publicKeys.push(k)
        }
    }

    return publicKeys.length > 0
}

export function catalogFiltersParametersEquals<T extends ICatalogListParameters = ICatalogListParameters>(
    params: T,
    prevParams: Undefinable<T>,
    excludedFilterKeys?: Array<string>
): boolean {
    if (typeof prevParams === 'undefined') {
        return false
    }

    const excludedRootKeys = [
        CatalogListQueryName.Page,
        CatalogListQueryName.ItemsPerPage,
        CatalogListQueryName.WithFilters,
    ]

    const current = cloneDeep(params)
    const prev = cloneDeep(prevParams)

    // suppression des clés racines
    excludedRootKeys.forEach((excludedRootKey) => {
        delete current[excludedRootKey]
        delete prev[excludedRootKey]
    })

    // suppression des filtres
    excludedFilterKeys?.forEach((excludedFilterKey) => {
        if (current.filters) {
            delete current.filters[excludedFilterKey]
        }
        if (prev.filters) {
            delete prev.filters[excludedFilterKey]
        }
    })

    return objectEquals(current, prev)
}

export function retrieveCatalogListQueryParams(locationSearch: string): ICatalogListQueryParameters {
    // @ts-ignore
    const parsed = Qs.parse(locationSearch.substring(1), {
        ignoreQueryPrefix: true,
        arrayFormat: 'bracket',
        decoder: newDecoder(),
    })

    const itemsPerPage =
        typeof parsed[CatalogListQueryName.ItemsPerPage] !== 'undefined'
            ? parseInt(parsed[CatalogListQueryName.ItemsPerPage] as string)
            : undefined

    const page =
        typeof parsed[CatalogListQueryName.Page] !== 'undefined'
            ? parseInt(parsed[CatalogListQueryName.Page] as string)
            : undefined

    // suppression des entrées précédentes
    let filters = cloneDeep(parsed)
    $enum(CatalogListQueryName).forEach((k) => delete filters[k])

    // on récupère les filtres et on fait le ménage
    if (filters && Object.values(filters).length > 0) {
        filters = ApplicationHelper.removeEmpty(filters)
        if (Object.values(filters).length === 0) {
            // @ts-ignore
            filters = undefined
        }
    }

    return {
        page,
        itemsPerPage,
        filters,
    }
}

export function formatCatalogListFilters(
    filters: IApiCatalogListFilterCollection
): ICatalogListFilterCollection | undefined {
    const all: IApiCatalogListFilterCollection = []

    for (const k in filters) {
        const current = filters[k]
        // formattage
        all.push({
            ...current,
            mode: CatalogListFilterMode.Option,
            searchable: current.code.indexOf('color') === -1,
            public: true,
            multiple: current.code !== 'f_department',
            translatable: false,
            autoValidate: false,
            static: false,
            position: current.position,
        })
    }

    // tri
    all.sort((a, b) => {
        return a.position > b.position ? 1 : a.position === b.position ? 0 : -1
    })

    const final: ICatalogListFilterCollection = {}
    all.forEach((single) => (final[single.code] = single))

    return final
}

export function customerFilterHasSelectedValues(
    filters?: ICatalogListFilterCollection,
    params?: IProductListParameters
): boolean {
    if (!filters || !params || !params.filters) {
        return false
    }

    const publicKeys: Array<string> = []
    for (const k in filters) {
        const filter = filters[k]
        if (filter.public) {
            publicKeys.push(k)
        }
    }

    if (publicKeys.length === 0 || !params || !params.filters) {
        return false
    }

    let hasValues = false
    for (const idx in publicKeys) {
        const k = publicKeys[idx]
        if (params.filters[k] && !isEmpty(params.filters[k])) {
            hasValues = true
            break
        }
    }

    return hasValues
}

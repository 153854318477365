import classNames, { Value as ClassValue } from 'classnames'
import React, { MouseEvent, ReactNode, useCallback } from 'react'
import { LazyImage } from 'react-lazy-images'
export type PictureProps<T = any> = {
    extra?: T
    className?: ClassValue
    loadEagerly: boolean
    src: string
    alt?: string
    placeholder: string
    index?: number
    children?: ReactNode
    onClick?: (evt: React.MouseEvent, index: number, extra?: T) => void
}

function Picture<T = any>({
    extra,
    className,
    loadEagerly,
    src,
    alt,
    placeholder,
    index = 0,
    onClick,
    children,
}: PictureProps<T>): JSX.Element {
    const handleOnClickImage = useCallback(
        (event: MouseEvent) => {
            if (!(event.currentTarget instanceof HTMLImageElement)) {
                return
            }

            onClick?.(event, parseInt(event.currentTarget.dataset.index!), extra)
        },
        [extra, onClick]
    )

    return (
        <div className={classNames('catalog-image', 'catalog-image-list', className)}>
            <div className={'catalog-image-container'}>
                <div className={'catalog-image-inner'}>
                    <LazyImage
                        loadEagerly={loadEagerly}
                        debounceDurationMs={200}
                        src={src}
                        alt={alt}
                        placeholder={({ imageProps, ref }) => (
                            <img
                                {...imageProps}
                                className={'placeholder'}
                                ref={ref}
                                alt={src}
                                src={placeholder}
                                data-index={0}
                                onClick={handleOnClickImage}
                            />
                        )}
                        actual={({ imageProps }) => (
                            <>
                                <img
                                    {...imageProps}
                                    alt={alt}
                                    className={'picture'}
                                    data-index={index}
                                    onClick={handleOnClickImage}
                                />
                            </>
                        )}
                    />
                </div>
                {children}
            </div>
        </div>
    )
}

Picture.defaultProps = {
    loadEagerly: false,
} as Partial<PictureProps>

export default Picture

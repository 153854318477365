/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { memo, MouseEvent, useCallback, useEffect, useState } from 'react'
import Lightbox from 'react-image-lightbox'
// import Swiper core and required components
import SwiperCore, { A11y, Controller, Lazy, Navigation, Pagination, Thumbs } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class'
import { IPlanogramDetail, IPlanogramDetailImage } from '../../../services/api/service/planograms/types'
import FlatIcon from '../../../components/Icon/FlatIcon'

// install Swiper components
SwiperCore.use([Controller, Navigation, Pagination, A11y, Lazy, Thumbs])

interface IProps {
    planogram: IPlanogramDetail
    className?: string
    onImageZoomClick?: (index: number) => void
    allowZoom: boolean
}

function PlanogramImages({ planogram, className, allowZoom, onImageZoomClick }: IProps): JSX.Element {
    const [swiper, setSwiper] = useState<SwiperClass | undefined>(undefined)
    const [swiperThumb, setSwiperThumb] = useState<SwiperClass | undefined>(undefined)
    const [lightboxOpen, setLightboxOpen] = useState<boolean>(false)
    const [zoomIndex, setZoomIndex] = useState<number>(0)

    useEffect(() => {
        if (swiper) {
            swiper.slideTo(zoomIndex)
        }
    }, [swiper, swiperThumb, zoomIndex])

    useEffect(() => {
        swiper?.update()
        swiper?.slideTo(0, 0)
        swiper?.lazy.load()
        swiperThumb?.update()
        swiperThumb?.slideTo(0, 0)
        swiperThumb?.lazy.load()
    }, [planogram, swiper, swiperThumb])

    // const handleOnClickImage = (event: MouseEvent) => {
    //     event.preventDefault()
    // }

    const handleOnZoomClick = useCallback(
        (event: MouseEvent) => {
            event.preventDefault()
            if (!swiper) {
                return
            }
            const pictureIndex = swiper.realIndex || 0
            setZoomIndex(pictureIndex)
            setLightboxOpen(true)
            if (onImageZoomClick) {
                onImageZoomClick(pictureIndex)
            }
        },
        [onImageZoomClick, setLightboxOpen, setZoomIndex, swiper]
    )

    const pictures: Array<IPlanogramDetailImage> = planogram.pictures
    const zooms = pictures.map((value) => value.zoom)
    const placeholders = pictures.map((value) => value.placeholder)
    const hasPictures = planogram.pictures.length > 0

    return (
        <div
            className={classNames(
                'planogram-image-section',
                'catalog-image-section',
                { 'catalog-image-section-multiple': pictures.length > 1 },
                className
            )}
        >
            <div
                className={classNames(
                    'catalog-image',
                    'catalog-image-list',
                    'planogram-image',
                    'planogram-image-list',
                    'planogram-image-detail',
                    {
                        'planogram-image-multiple': pictures.length > 1,
                    }
                )}
            >
                <div className={classNames('catalog-image-container', 'planogram-image-container')}>
                    <div className={classNames('catalog-image-inner', 'planogram-image-inner')}>
                        <Swiper
                            thumbs={{ swiper: swiperThumb }}
                            onSwiper={setSwiper}
                            lazy
                            preloadImages={false}
                            watchSlidesProgress
                            slidesPerView={1}
                            spaceBetween={0}
                            navigation={{}}
                        >
                            {pictures.map((picture, idx) => (
                                <SwiperSlide
                                    key={`product_${planogram['@id']}_image_${picture.large}_${idx}`}
                                    className={classNames('catalog-image-slide', 'planogram-image-slide')}
                                    zoom={false}
                                >
                                    <div className="swiper-zoom-container">
                                        <img
                                            data-src={picture.large}
                                            className={classNames('swiper-lazy', 'img-fluid')}
                                            alt={planogram.name}
                                            onClick={handleOnZoomClick}
                                        />
                                        <div
                                            className="swiper-zoom-target"
                                            style={{ backgroundImage: `url(${picture.zoom})` }}
                                        />
                                        <div
                                            className={classNames(
                                                'swiper-lazy-preloader',
                                                'swiper-lazy-preloader-black'
                                            )}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        {allowZoom && hasPictures && (
                            <a href={'#zoom'} className={'btn-planogram-image-zoom'} onClick={handleOnZoomClick}>
                                <FlatIcon icon={'search'} />
                            </a>
                        )}
                    </div>
                </div>
            </div>

            <Swiper
                navigation={{}}
                onSwiper={setSwiperThumb}
                spaceBetween={2}
                slidesPerView={3}
                slidesPerGroup={3}
                breakpoints={{
                    768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                    },
                    // 1300: {
                    //     slidesPerView: 4,
                    //     slidesPerGroup: 4,
                    // },
                }}
            >
                {pictures.map((picture, idx) => (
                    <SwiperSlide
                        zoom={false}
                        className={classNames('planogram-image-slide', 'planogram-image-slide-thumb')}
                        key={`product_${planogram['@id']}_image_${picture.thumb}_${idx}`}
                    >
                        <img src={picture.thumb} className={classNames('img-fluid')} alt={planogram.name} />
                    </SwiperSlide>
                ))}
            </Swiper>

            {lightboxOpen && (
                <Lightbox
                    mainSrc={zooms[zoomIndex]}
                    mainSrcThumbnail={placeholders[zoomIndex]}
                    nextSrc={zooms[(zoomIndex + 1) % zooms.length]}
                    nextSrcThumbnail={placeholders[(zoomIndex + 1) % zooms.length]}
                    prevSrc={zooms[(zoomIndex + zooms.length - 1) % zooms.length]}
                    prevSrcThumbnail={placeholders[(zoomIndex + zooms.length - 1) % zooms.length]}
                    onCloseRequest={() => setLightboxOpen(false)}
                    onMovePrevRequest={() => setZoomIndex((zoomIndex + zooms.length - 1) % zooms.length)}
                    onMoveNextRequest={() => setZoomIndex((zoomIndex + 1) % zooms.length)}
                    wrapperClassName={'catalog-image-lightbox'}
                />
            )}
        </div>
    )
}

PlanogramImages.defaultProps = {
    allowZoom: true,
    showBadges: false,
} as Partial<IProps>

export default memo(PlanogramImages)

/*
 *
 * Pagination
 *
 */

import { IProductDetail } from '../../services/api/service/products/types'
import React, { useCallback } from 'react'
import { Table } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import Picture from '../../components/Catalog/Picture/Picture'
import classNames from 'classnames'
import Heading from '../Planograms/Partial/Item/Heading'
import { useHistory, useLocation } from 'react-router-dom'
import { generatePlanogramUrl } from '../../utils/planogramHelper'
import { IPlanogramList } from '../../services/api/service/planograms/types'

interface IProps {
    product: IProductDetail
    onClick?: (planogram: IPlanogramList) => void
}

function ProductPlanograms({ product }: IProps): JSX.Element {
    const { locale } = useIntl()
    const history = useHistory()
    const location = useLocation()

    const handleOnPlanogramClick = useCallback(
        (evt: React.MouseEvent) => {
            const planoId = (evt.currentTarget as HTMLTableRowElement).dataset.id
            const planogram = product.planograms?.find((p) => p['@id'] === planoId)
            if (!planogram) {
                return
            }

            // second, open new modal
            history.replace(generatePlanogramUrl(planogram['@id'], locale), {
                background: location,
            })
            // history.push(generatePlanogramUrl(planogram['@id'], locale), {
            //     background: location,
            // })
        },
        [product.planograms, history, locale, location]
    )

    return (
        <div className="product-planograms">
            <h2>
                <FormattedMessage id="planogram.marketing_title" />
            </h2>
            <Table striped bordered={false} hover size="sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{/*<FormattedMessage id="planogram.plural" values={{ total: 1 }} />*/}</th>
                    </tr>
                </thead>
                <tbody>
                    {product.planograms.map((planogram) => (
                        <tr key={planogram['@id']} data-id={planogram['@id']} onClick={handleOnPlanogramClick}>
                            <td>
                                {planogram.main_pictures && (
                                    <Picture
                                        loadEagerly
                                        className={classNames('planogram-image', 'planogram-image-list')}
                                        src={planogram.main_pictures[0].list}
                                        alt={planogram.full_name}
                                        placeholder={planogram.main_pictures[0].placeholder}
                                    />
                                )}
                            </td>
                            <td>
                                <Heading planogram={planogram} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

ProductPlanograms.defaultProps = {} as Partial<IProps>

export default ProductPlanograms

/*
 *
 * Planogrammes
 *
 */

import classNames from 'classnames'
import React, { memo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders'

function Placeholder(): JSX.Element {
    return (
        <div className={classNames('planogram-card', 'planogram-detail', 'planogram-detail-placeholder')}>
            <Row className={'product-list'}>
                <Col xs={24} md={12}>
                    <div className={classNames('catalog-image', 'catalog-image-list')}>
                        <div className={'catalog-image-container'}>
                            <div className={'catalog-image-inner'}>
                                <RectShape />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className={'product-detail-inner-section'}>
                        <TextBlock rows={2} color={'gray'} className={'planogram-name-placeholder'} />
                    </div>
                    <div className={'product-detail-inner-section'}>
                        <TextBlock rows={1} color={'gray'} className={'planogram-attributes-placeholder'} />
                    </div>
                    <div className={'product-detail-inner-section'}>
                        <RectShape color={'gray'} className={'planogram-add-to-cart-placeholder'} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default memo(Placeholder)

// LIBRARIES
import classNames from 'classnames'
import React, { useCallback, useMemo, useRef, useState } from 'react'

import { IPlanogramModuleProduct } from '../../../services/api/service/planograms/types'
import { default as ProductImage } from '../../../components/Product/Partial/MainPicture'
import { default as ProductReference } from '../../../components/Product/Partial/Reference'
import Attribute from '../../../components/Product/Partial/Attribute'
import {
    makeSelectCanIncrementQuantityByProductId,
    makeSelectCartByShippingLocationId,
    makeSelectCartMode,
    makeSelectErrorsByProductId,
    makeSelectFetchingByProductId,
    makeSelectLockByShippingLocationId,
    makeSelectQuantityByProductId,
} from '../../../store/carts/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { IApplicationRootState } from '../../../store'
import { Nullable } from 'tsdef'
import { ICart } from '../../../services/api/service/carts/types'
import { addToCartProcessAction, removeToCartProcessAction } from '../../../store/carts/actions'
import StoreQuantity from '../../StoreQuantity/StoreQuantity'
import QuantitySelector, { QuantityAlertDisplayMode } from '../../../components/QuantitySelector/QuantitySelector'
import { stockAlertProcessAction } from '../../../store/stockAlert/actions'
import { isOrderAvailable, isQuantitySelectorMultipleEnabled } from '../../../store/carts/utils'
import { createStructuredSelector } from 'reselect'
import { makeSelectCustomer, makeSelectCustomerStore } from '../../../store/customers/selectors'

import { ICustomer } from '../../../services/api/service/customers/types'
import { StrictCartMode } from '../../../store/carts/types'
import { generateProductUrl } from '../../../utils/productHelper'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import FlatIcon from '../../../components/Icon/FlatIcon'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface IProps {
    className?: string
    disabled?: boolean
    moduleProduct: IPlanogramModuleProduct
    tabIndex?: number
    qtySelectorMultiple?: boolean
}

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
    cartMode: makeSelectCartMode(),
})

function ModuleProduct({ moduleProduct, className, disabled, tabIndex }: IProps): JSX.Element {
    const { product } = moduleProduct
    const dispatch = useDispatch()
    const colRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const [showStoreQuantity, setShowStoreQuantity] = useState<boolean>(false)
    const { locale } = useIntl()
    const history = useHistory()
    const location = useLocation()
    const selectQuantityWithId = useMemo(makeSelectQuantityByProductId, [])
    const quantity: number = useSelector<IApplicationRootState, number>((state) =>
        selectQuantityWithId(state, product.id, true)
    )
    const selectFetchingWithId = useMemo(makeSelectFetchingByProductId, [])
    const fetching: boolean = useSelector<IApplicationRootState, boolean>((state) =>
        selectFetchingWithId(state, product.id, true)
    )
    const selectCanIncrementQuantityWithId = useMemo(makeSelectCanIncrementQuantityByProductId, [])
    const canIncrementQuantity: boolean = useSelector<IApplicationRootState, boolean>((state) =>
        selectCanIncrementQuantityWithId(state, product.id, true)
    )
    const selectItemErrorsWithId = useMemo(makeSelectErrorsByProductId, [])
    const itemErrors: Array<string> | undefined = useSelector<IApplicationRootState, Array<string> | undefined>(
        (state) => selectItemErrorsWithId(state, product.id, true)
    )
    const selectItemLockedWithShippingLocationId = useMemo(makeSelectLockByShippingLocationId, [])
    const cartIsLocked: boolean = useSelector<IApplicationRootState, boolean>((state) =>
        selectItemLockedWithShippingLocationId(state, product.shipping_location)
    )
    const selectCartWithShippingLocationId = useMemo(makeSelectCartByShippingLocationId, [])
    const cart: Nullable<ICart> = useSelector<IApplicationRootState, Nullable<ICart>>((state) =>
        selectCartWithShippingLocationId(state, product.shipping_location, true)
    )

    // redux
    const { customer, cartMode, store } = useSelector<
        IApplicationRootState,
        {
            customer: ICustomer
            store?: ICustomer
            cartMode: StrictCartMode
        }
    >(stateSelector)

    const isQtySelectorMultiple = useMemo(() => {
        return isQuantitySelectorMultipleEnabled(customer, store, cartMode)
    }, [customer, store, cartMode])

    const onImageClick = useCallback(() => {
        history.push(generateProductUrl(product.id, locale), { background: location })
    }, [locale, location, history])

    const handleProductQuantityMultipleAsked = useCallback(
        (enabled: boolean, saving: boolean, multiple: boolean) => {
            if (multiple && isQtySelectorMultiple) {
                setShowStoreQuantity(true)
            }
        },
        [isQtySelectorMultiple]
    )

    const handleProductQuantityExit = useCallback(() => {
        setShowStoreQuantity(false)
    }, [setShowStoreQuantity])

    const canOrder = useMemo(() => {
        return isOrderAvailable(customer, store, cartMode)
    }, [cartMode, customer, store])

    const handleQuantityChange = useCallback(
        (quantity: number, quantityPrev: number) => {
            if (quantity > 0) {
                dispatch(
                    addToCartProcessAction(product.id, quantity, quantityPrev, undefined, undefined, cart?.['@id'])
                )
            } else {
                dispatch(
                    removeToCartProcessAction(product.id, quantity, quantityPrev, undefined, undefined, cart?.['@id'])
                )
            }
        },
        [dispatch, cart, product]
    )

    const handleRestockAlertSubscriptionChange = useCallback(
        (subscribed: boolean) => {
            dispatch(stockAlertProcessAction(product.id, subscribed))
        },
        [dispatch, product.id]
    )

    return (
        <tr className={classNames('product', 'product-card', 'product-line', className)}>
            <td>
                <ProductImage product={product} onImageClick={onImageClick} />

                {showStoreQuantity && (
                    <StoreQuantity
                        product={product}
                        errors={itemErrors}
                        onStoreQuantityExit={handleProductQuantityExit}
                    />
                )}
            </td>
            <td className="col-attribute">
                <Attribute
                    attributes={product.frontend_attributes}
                    attributekey="collection"
                    parentAs="div"
                    childAs="div"
                />
            </td>
            <td className="col-reference">
                <ProductReference reference={product.reference} />
            </td>
            <td className="col-qty">
                {canOrder && (
                    <QuantitySelector
                        unitPrice={product.price}
                        showPacking
                        onQuantityChange={handleQuantityChange}
                        onSelectorClick={handleProductQuantityMultipleAsked}
                        packing={product.packing}
                        currentValue={quantity}
                        saving={fetching}
                        multiple={isQtySelectorMultiple}
                        outOfStock={product.out_of_stock}
                        outOfStockLabel={product.coming_soon ? 'default.coming_soon' : 'product.out_of_stock'}
                        multipleButtonLabel={'product.update_quantity'}
                        arrivalStock={product.arrival_stock}
                        remainingStock={product.stock}
                        orderInProgressQuantity={product.order_in_progress_quantity}
                        canIncrement={canIncrementQuantity}
                        errors={itemErrors}
                        popoverContainerRef={colRef}
                        uniqId={product.id}
                        tabIndex={tabIndex}
                        quantityAlertDisplayMode={QuantityAlertDisplayMode.Tooltip}
                        restockAlertOutOfStockButtonOnly
                        onRestockAlertSubscriptionChange={handleRestockAlertSubscriptionChange}
                        restockAlertEnabled={product.can_add_stock_alert}
                        restockAlertSubscribed={product.has_stock_alert}
                        disabled={disabled}
                        locked={cartIsLocked}
                        showPrices={!customer.hide_prices}
                    />
                )}
            </td>
            <td className="col-module-product-qty">
                <OverlayTrigger
                    overlay={
                        <Tooltip id={`tooltip-${module['@id']}`}>
                            <FormattedMessage id="planogram_module_product.min_qty_message" />
                        </Tooltip>
                    }
                >
                    <div className="module-product-qty">
                        <span>{moduleProduct.quantity}</span> <FlatIcon icon="information" />
                    </div>
                </OverlayTrigger>
            </td>
        </tr>
    )
}

ModuleProduct.defaultProps = {
    modal: false,
} as Partial<IProps>

export default ModuleProduct

import { call, cancelled, getContext, put, select, takeLatest } from 'redux-saga/effects'
import ProductActionTypes from './constants'
import { IApiClient } from '../../services/api/types'
import {
    planogramDetailFailureAction,
    planogramDetailSuccessAction,
    planogramGroupFailureAction,
    planogramGroupProcessAction,
    planogramGroupSuccessAction,
} from './actions'
import { makeSelectPlanogramGroups } from './selectors'
import { IPlanogramDetailProcessAction, IPlanogramGroupAttributeState, IPlanogramGroupProcessAction } from './types'
import { formatAppError } from '../app/saga'
import ReactGA from 'react-ga'
import Config from '../../config/index'
// TOKEN
import axios from 'axios'

import { IApiPlanogramGroupResponse, IApiPlanogramResponse } from '../../services/api/service/planograms/types'

const CancelToken = axios.CancelToken

function* processPlanogramDetail(action: IPlanogramDetailProcessAction) {
    const source = CancelToken.source()
    const planogramId: string = action.payload!.planogramId
    const api: IApiClient = yield getContext('api')

    try {
        const currentProductGroup: IPlanogramGroupAttributeState = yield select(makeSelectPlanogramGroups())

        const response: IApiPlanogramResponse = yield call(
            { context: api.planograms, fn: 'get' },
            planogramId,
            source.token
        )
        yield put(planogramDetailSuccessAction(response))

        if (
            currentProductGroup &&
            currentProductGroup.data &&
            currentProductGroup.data['@id'] === response.data.group
        ) {
            return
        }

        yield put(planogramGroupProcessAction(response.data.group))

        if (Config.GOOGLE.ANALYTICS.ACTIVE) {
            try {
                ReactGA.event({
                    category: 'Planogram',
                    action: 'show',
                    label: response.data.full_name,
                })
            } catch (e) {}
        }
    } catch (e) {
        const error = yield call(formatAppError, e, 'product.detail.error')
        yield put(planogramDetailFailureAction(error))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

function* processPlanogramGroups(action: IPlanogramGroupProcessAction) {
    const source = CancelToken.source()
    const api: IApiClient = yield getContext('api')
    const planogramGroupId = action.payload?.planogramGroupId
    const currentPlanogramGroup: IPlanogramGroupAttributeState = yield select(makeSelectPlanogramGroups())

    // si c'est le même groupe, on ne s'embête pas à refaire une requete HTTP
    if (currentPlanogramGroup && currentPlanogramGroup.data && currentPlanogramGroup.data['@id'] === planogramGroupId) {
        return
    }

    if (!planogramGroupId) {
        yield put(planogramGroupSuccessAction(null))
        return
    }

    try {
        const response: IApiPlanogramGroupResponse = yield call(
            { context: api.planograms, fn: 'planogramGroup' },
            planogramGroupId,
            source.token
        )
        yield put(planogramGroupSuccessAction(response))
    } catch (e) {
        const error = yield call(formatAppError, e, 'product.detail_group.error')
        yield put(planogramGroupFailureAction(error))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

export default [
    takeLatest(ProductActionTypes.DETAIL_PROCESS_ACTION, processPlanogramDetail),
    takeLatest(ProductActionTypes.GROUP_PROCESS_ACTION, processPlanogramGroups),
]

import classNames, { Value as ClassNameValue } from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import {
    ICatalogListFilter,
    ICatalogListFilterCollection,
    ICatalogListParameters,
} from '../../../../services/api/service/core/types'
import {
    IAttributeDefinitionCollection,
    IFamilyAttributeDefinition,
} from '../../../../services/api/service/classification/types'
import { CollectionMap } from '../../../../types/common'
import Factory from './Factory'
import Button from '../../../../components/Buttons/Button'

export interface IProps<T extends ICatalogListParameters = ICatalogListParameters> {
    className?: ClassNameValue
    filterPortalId?: string
    loading: boolean
    filters?: ICatalogListFilterCollection
    definition: IAttributeDefinitionCollection
    params: T
    hasSelectedValues: boolean
    onFilterToggleOpen?: (filter: ICatalogListFilter, open: boolean) => void
    onReset?: () => void
}

function List<T extends ICatalogListParameters = ICatalogListParameters>({
    className,
    filterPortalId,
    onFilterToggleOpen,
    loading,
    filters,
    params,
    definition,
    hasSelectedValues,
}: IProps<T>): JSX.Element {
    const history = useHistory()
    const { pathname } = useLocation()

    const keys: Array<string> = useMemo(() => {
        if (!filters) {
            return []
        }
        const publicKeys: Array<string> = []
        for (const k in filters) {
            const filter = filters[k]
            if (filter.public) {
                publicKeys.push(k)
            }
        }
        return publicKeys
    }, [filters])

    const hasFilters = keys.length === 0
    const configuration: CollectionMap<IFamilyAttributeDefinition> = useMemo(() => {
        const final: CollectionMap<IFamilyAttributeDefinition> = {}
        definition.forEach((single) => (final[single.code] = single))
        return final
    }, [definition])

    const handleReset = useCallback(() => {
        history.push(pathname)
    }, [history, pathname])

    return (
        <>
            <div
                className={classNames('catalog-filter-list', className, {
                    empty: hasFilters,
                    fulfilled: hasSelectedValues,
                    disabled: loading,
                })}
            >
                <div className={'catalog-filter-list-inner'}>
                    <div className={'title'}>
                        <span>
                            <FormattedMessage id={'default.filter'} />
                        </span>
                    </div>
                    {keys.map((key) => {
                        const value = params && params.filters && params.filters[key] ? params.filters[key] : undefined
                        return (
                            <Factory
                                key={key}
                                filter={filters![key]}
                                value={value}
                                showPreview={
                                    key === 'classification' || (configuration[key] && configuration[key].show_preview)
                                }
                                swatch={configuration[key] && configuration[key].swatch}
                                filterPortalId={filterPortalId}
                                onToggleOpen={onFilterToggleOpen}
                            />
                        )
                    })}
                    {hasSelectedValues && (
                        <div className={'reset-all'}>
                            <Button className={'btn-reset-all'} onClick={handleReset} type={'button'} variant={'link'}>
                                <FormattedMessage id={'products.remove_filters'} />
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

List.defaultProps = {
    desktop: true,
} as Partial<IProps>

export default List

import { ICmsWidgetLink } from '../types/widget'
import * as H from 'history'
import { PRODUCT_PATHNAME_REGEXP } from './productHelper'

export default function redirectCmsWidgetLink(link: ICmsWidgetLink, history: H.History, location: H.Location): void {
    if (link.blank) {
        if (link.url) {
            window.open(link.url, '_blank')
        }
        return
    }
    if (!link.internal) {
        if (link.url) {
            window.location.href = link.url
        }
        return
    }

    if (!link.url) {
        return
    }

    try {
        const parser = document.createElement('a')
        parser.href = link.url
        const redirectUrl = parser.pathname + parser.search
        if (parser.pathname.match(PRODUCT_PATHNAME_REGEXP)) {
            history.push(redirectUrl, { background: location })
            return
        }
        history.push(redirectUrl)
    } catch (e) {
        // console.log(e)
    }
}

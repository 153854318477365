import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageLoader from '../components/Loader/PageLoader'
import { classificationFamilyTreeProcessAction } from '../store/classification/actions'
import { makeSelectClassificationFamilyTreeByMode } from '../store/classification/selectors'
import { Undefinable } from 'tsdef'
import { IFamilyTreeCollection } from '../services/api/service/classification/types'
import { IApplicationRootState } from '../store'
import { PlanogramsListMode } from '../services/api/service/planograms/types'
import PlanogramsPage from './PlanogramsPage'

export default function PlanogramsLoaderPage(): JSX.Element {
    const dispatch = useDispatch()
    const selectFamilyTreeWithMode = useMemo(makeSelectClassificationFamilyTreeByMode, [])
    const familyTree: Undefinable<IFamilyTreeCollection> = useSelector<
        IApplicationRootState,
        Undefinable<IFamilyTreeCollection>
    >((state) => {
        return selectFamilyTreeWithMode(state, PlanogramsListMode.Default)
    })

    useEffect(() => {
        if (typeof familyTree === 'undefined') {
            dispatch(classificationFamilyTreeProcessAction(PlanogramsListMode.Default))
        }
    }, [dispatch, familyTree])

    if (typeof familyTree !== 'undefined') {
        return <PlanogramsPage />
    }

    return <PageLoader />
}

/**
 *
 * Suggestion Card
 *
 */
import classNames from 'classnames'
import React, { memo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { default as PlanogramHeading } from '../Partial/Item/Heading'
import { IPlanogramList } from '../../../services/api/service/planograms/types'
import { PlanogramClickCallback } from '../../../types/planogramCallback'
import Picture from '../../../components/Catalog/Picture/Picture'

export interface IProps {
    planogram: IPlanogramList
    className?: string
    onPlanogramClick?: PlanogramClickCallback
    column?: number
    noGutters?: boolean
}

function Suggestion({ planogram, className, onPlanogramClick, column, noGutters }: IProps): JSX.Element {
    const handlePlanogramClick = (evt: React.MouseEvent) => {
        onPlanogramClick?.(evt, planogram)
    }

    return (
        <div
            className={classNames('planogram', 'planogram-card', 'planogram-suggestion', className)}
            onClick={handlePlanogramClick}
        >
            <Row noGutters={noGutters}>
                <Col xs={column}>
                    <Picture
                        loadEagerly
                        className={classNames('planogram-image', 'planogram-image-list', className)}
                        src={planogram.main_pictures?.[0].list}
                        alt={planogram.full_name}
                        placeholder={planogram.main_pictures?.[0].placeholder}
                    />
                </Col>
                <Col>
                    <PlanogramHeading planogram={planogram} />
                </Col>
            </Row>
        </div>
    )
}

Suggestion.defaultProps = {
    showPacking: false,
    showPrices: false,
    column: 6,
    noGutters: true,
} as Partial<IProps>

export default memo(Suggestion)

/*
 *
 * Planograms constants
 *
 */

enum ActionTypes {
    RESET_ACTION = 'app/Planograms/RESET',
    LIST_REFRESH_ACTION = 'app/Planograms/List/REFRESH',
    LIST_PROCESS_ACTION = 'app/Planograms/List/REQUEST_PROCESS',
    LIST_SUCCESS_ACTION = 'app/Planograms/List/REQUEST_SUCCESS',
    LIST_FAILURE_ACTION = 'app/Planograms/List/REQUEST_FAILURE',
    LIST_RESET_ACTION = 'app/Planograms/List/RESET',
}

export default ActionTypes

import React, { useCallback, useState } from 'react'
import { IBasePlanogram, IPlanogramList } from '../../../../services/api/service/planograms/types'
import classNames, { Value as ClassValue } from 'classnames'
import { useIntl } from 'react-intl'
// import isEmpty from 'lodash/isEmpty'
import { $enum } from 'ts-enum-util'
import { generatePlanogramUrl, PlanogramDocumentType, PlanogramListAction } from '../../../../utils/planogramHelper'
import { useHistory, useLocation } from 'react-router-dom'
import { Undefinable } from 'tsdef'
import { PlanogramClickCallback } from '../../../../types/planogramCallback'
import AddToCart from '../Actions/AddToCart'
import DownloadDocument from '../Actions/DownloadDocument'
import isUndefined from 'lodash/isUndefined'
import Heading from './Heading'
import MainPicture from './MainPicture'

export type ItemProps<T extends IBasePlanogram = IPlanogramList> = {
    readonly className?: ClassValue
    readonly planogram: T
    readonly disabled?: boolean
    readonly onClick?: PlanogramClickCallback
}

function Item({ className, planogram, disabled, onClick }: ItemProps): JSX.Element {
    const [fetchingAction, setFetchingAction] = useState<Undefinable<PlanogramListAction>>()

    const { locale } = useIntl()
    const history = useHistory()
    const location = useLocation()

    const resetFetchingAction = useCallback(() => setFetchingAction(undefined), [])
    const handleActionClick = useCallback(
        (evt: React.MouseEvent) => {
            const actionValue = $enum(PlanogramListAction).isValue((evt.currentTarget as HTMLElement).dataset.action)
                ? ((evt.currentTarget as HTMLElement).dataset.action as PlanogramListAction)
                : undefined

            if (!actionValue) {
                history.push(generatePlanogramUrl(planogram['@id'], locale), { background: location })
            }

            onClick?.(evt, planogram, actionValue as PlanogramListAction)
        },
        [onClick, planogram, history, locale, location]
    )

    return (
        <div className={classNames('planogram', 'planogram-card', 'planogram-list-item', className)}>
            <div className="planogram-head">
                {planogram.main_pictures && <MainPicture planogram={planogram} onClick={handleActionClick} />}
            </div>
            <div className="planogram-content">
                <Heading planogram={planogram} />
                <div className="planogram-actions">
                    <AddToCart
                        block
                        size="sm"
                        planogram={planogram}
                        onActionStart={setFetchingAction}
                        disabled={
                            (!isUndefined(fetchingAction) && fetchingAction !== PlanogramListAction.MassAddToCart) ||
                            disabled
                        }
                        onActionDone={resetFetchingAction}
                    />
                    <DownloadDocument
                        block
                        size="sm"
                        planogram={planogram}
                        onActionStart={setFetchingAction}
                        onActionDone={resetFetchingAction}
                        disabled={
                            (!isUndefined(fetchingAction) && fetchingAction !== PlanogramListAction.DownloadPdf) ||
                            disabled
                        }
                        documentType={PlanogramDocumentType.Pdf}
                    />
                    <DownloadDocument
                        block
                        size="sm"
                        planogram={planogram}
                        onActionStart={setFetchingAction}
                        onActionDone={resetFetchingAction}
                        disabled={
                            (!isUndefined(fetchingAction) && fetchingAction !== PlanogramListAction.DownloadExel) ||
                            disabled
                        }
                        documentType={PlanogramDocumentType.Excel}
                    />
                </div>
            </div>
        </div>
    )
}

export default Item
